<script setup>
import { ref, reactive, onMounted } from "vue";
import {
  getUrlVars,
  getJSON,
  getUserId,
  SUCCESS,
  getCourse,
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
  calcBracketNum,
  calRefund,
  replaceComma,
  loadIPATLoginInfo,
  isIPAT_CONFIRM,
  isSUGOTOKU_IPAT_CONFIRM,
  isSaveINETID,
  retrieveCourse,
  PAYINGNONE,
  isNar,
  getBet4Array,
  setBet4Array,
  setRaceBet4sg,
  getUserAgent,
  isCoupleBracketNum,
  setDefaultPurchase,
  getNonMemberId,
  isAppNonmember,
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
  TOB_WIN,
  TOB_PLACE,
  TOB_WIDE,
  TOB_TRIO,
  TOB_BRACKET,
  TOB_BRACKET_EXACTA,
  TOB_BRACKET_EXACTA_MULTI,
  TOB_EXACTA,
  TOB_EXACTA_MULTI,
  TOB_TRIFECTA,
  TOB_TRIFECTA_MULTI,
  TOB_QUINELLA,
  METHOD_BOX,
  METHOD_NORMAL,
  METHOD_FORMATION,
  METHOD_WHEEL_1,
  METHOD_WHEEL_2,
  METHOD_WHEEL,
  METHOD_WHEEL_3,
  METHOD_WHEEL_1_2,
  METHOD_WHEEL_1_3,
  METHOD_WHEEL_2_3,
  JOINPATH,
  JRA_HOST,
  NAR_HOST,
} from "../../assets/js/define";

import SetBalanceModal from "../../components/utils/SetBalanceModal.vue";
import SetAmountModal from "../../components/utils/SetAmountModal.vue";
import SetDelModal from "../../components/utils/SetDelModal.vue";
import SetAmountAvgModal from "../../components/utils/SetAmountAvgModal.vue";
import raceCommon from "../../functions/odds/race-common";
import {useRouter} from "vue-router";

const { sortTrioNum, sortDoubleNum } = raceCommon();

// ユーザID
let mUserId = "";
let mInfo = null;

let ContentListAll = reactive([]);
let ContentList = reactive([]);
let CheckList = reactive([]);
let allList = reactive([]);
let refundValue = reactive([]);
let detailList = reactive([]);
let amountList = reactive([]);
let purchaseCount = reactive([]);
let modalBalanceOpen = ref(false);
let modalAmountOpen = ref(false);
let modalDelOpen = ref(false);
let modalAmountAvgOpen = ref(false);
const totalCount = ref(0);
const itemPrize = ref(0);
const addItemCount = ref(0);
const ipatBetString = ref(0);
const avgAmountIndex = ref(0);
// 選択ボタン表示フラグ
const selectFlag = ref(false);
// 削除ボタン表示フラグ
const delFlag = ref(false);
const itemNum = ref(0);
let loginInfo = {};
const isSugotoku = ref(false)
let isWebview = ref(false)
let narFlag = ref(false)
const isWebviewAndPayingNone = ref(false)

onMounted(() => {
  isWebview.value = getUserAgent()
  narFlag.value = isNar() === 1
  callRetrieveCourse(function (courseId) {
    isSugotoku.value = courseId === PAYINGSUGOTOKU || courseId === PAYINGSUGOTOKU_NONE
    isWebviewAndPayingNone.value = isWebview.value && courseId === PAYINGNONE

    if ( courseId === PAYINGSUGOTOKU_NONE || (!isWebview.value && courseId === PAYINGNONE)) {
      router.push('/' + JOINPATH);
    } else {
      loginInfo = loadIPATLoginInfo();
      doInit();
    }
  });
});

const router = useRouter()

const callRetrieveCourse = function (callback) {
  try {
    retrieveCourse(callback);
  } catch (e) {
    callback("none");
  }
}

// 買い目登録済みの馬券を取得
const doInit = async () => {
  document.body.className = "";
  // データリセット
  totalCount.value = 0;
  selectFlag.value = false;
  delFlag.value = false;
  itemNum.value = 0;
  addItemCount.value = 0;
  itemPrize.value = 0;

  ContentListAll.splice(0);
  ContentList.splice(0);
  CheckList.splice(0);
  allList.splice(0);
  detailList.splice(0);
  amountList.splice(0);
  purchaseCount.splice(0);
  // ユーザID取得
  mUserId = getUserId();

  if (isSugotoku.value) {
    let array = getBet4Array();
    if (array) {
      ContentListAll.splice(0);
      ContentList.splice(0);
      await setData(array);
    }
  } else {
    let request = HOST + PATH + "Odds/getRaceBet.js" + BACKWORDPATH + '?narFlag=' + isNar();
    getJSON(request, true, async function (status, json) {
      if (status == SUCCESS) {
        ContentListAll.splice(0);
        ContentList.splice(0);
        await setData(json);
      }
    });
  }
  document.body.className = "status-loaded";
};

// 買い目登録済みの情報からデータ取得、整形
const setData = async (json) => {
  try {
    //変数初期化
    let prevTargetData = {};
    //パラメータ取得
    let params = getUrlVars();

    if (json != null) {
      for (let i = 0; i < json.length; i++) {
        let targetData = json[i];
        let oddsData = await setOdds(targetData);
        targetData.oddsValue = oddsData.oddsValue;
        targetData.oddsClass = oddsData.oddsClass;
        const isBracket = [TOB_BRACKET, TOB_BRACKET_EXACTA, TOB_BRACKET_EXACTA_MULTI].includes(targetData.TOB);
        const isBracketExacta = [TOB_BRACKET_EXACTA, TOB_BRACKET_EXACTA_MULTI].includes(targetData.TOB);
        if (targetData == null) continue;
        let itemCount = 1;

        let raceNum = targetData.raceNum;
        //#3527 3桁だった場合、2桁に修正する
        if (raceNum.length == 3) {
          raceNum = raceNum.slice(1, 3);
        }
        let reqRaceInfo =
          HOST +
          PATH +
          "RaceInfo/raceBasicInfo.js" +
          BACKWORDPATH +
          "?dor=" +
          targetData.dor +
          "&racetrackCd=" +
          targetData.racetrackCd +
          "&raceNum=" +
          raceNum +
          "&narFlag=" +
          isNar();
        await getJSON(
          reqRaceInfo,
          true,
          async function (statusRaceInfo, jsonRaceInfo) {
            let raceInfos = jsonRaceInfo[0];
            //出頭数
            let raceEntryNum = raceInfos["EntryNum"];

            //１つ前表示データと開催日、開催場、レース番号、識別、方法のいずれかが異なるか、基本以外の買い方の場合
            if (
              prevTargetData.dor != targetData.dor ||
              prevTargetData.racetrackCd != targetData.racetrackCd ||
              prevTargetData.raceNum != targetData.raceNum ||
              prevTargetData.TOB != targetData.TOB ||
              prevTargetData.method != targetData.method ||
              targetData.method != METHOD_NORMAL
            ) {
              //親のテーブルを追加する。
              targetData.tableId = "betTable" + i;
              let place = {
                "01": "札幌",
                "02": "函館",
                "03": "福島",
                "04": "新潟",
                "05": "東京",
                "06": "中山",
                "07": "中京",
                "08": "京都",
                "09": "阪神",
                10: "小倉",
                30: "門別",
                31: "北見",
                32: "岩見",
                33: "帯広",
                34: "旭川",
                35: "盛岡",
                36: "水沢",
                37: "上山",
                38: "三条",
                39: "足利",
                40: "宇都",
                41: "高崎",
                42: "浦和",
                43: "船橋",
                44: "大井",
                45: "川崎",
                46: "金沢",
                47: "笠松",
                48: "名古屋",
                49: "紀三",
                50: "園田",
                51: "姫路",
                52: "益田",
                53: "福山",
                54: "高知",
                55: "佐賀",
                56: "荒尾",
                57: "中津",
                58: "札幌",
                59: "函館",
                60: "新潟",
                61: "中京",
                A0: "他外",
                A2: "日本",
                A4: "アメ",
                A6: "イギ",
                A8: "フラ",
                B0: "イン",
                B2: "アイ",
                B4: "ニュ",
                B6: "オー",
                B8: "カナ",
                C0: "イタ",
                C2: "ドイ",
                C5: "オマ",
                C6: "イラ",
                C7: "(ア)",
                C8: "シリ",
                D0: "スウ",
                D2: "ハン",
                D4: "ポル",
                D6: "ロシ",
                D8: "ウル",
                E0: "ペル",
                E2: "アル",
                E4: "ブラ",
                E6: "ベル",
                E8: "トル",
                F0: "韓国",
                F1: "中国",
                F2: "チリ",
                F8: "パナ",
                G0: "香港",
                G2: "スペ",
                H0: "西独",
                H2: "南ア",
                H4: "スイ",
                H6: "モナ",
                H8: "フィ",
                I0: "プエ",
                I2: "コロ",
                I4: "チェ",
                I6: "チェ",
                I8: "スロ",
                J0: "エク",
                J2: "ギリ",
                J4: "マレ",
                J6: "メキ",
                J8: "モロ",
                K0: "パキ",
                K2: "ポー",
                K4: "パラ",
                K6: "サウ",
                K8: "キプ",
                L0: "タイ",
                L2: "ウク",
                L4: "ベネ",
                L6: "ユー",
                L8: "デン",
                M0: "シン",
                M2: "澳門",
                M4: "墺",
                M6: "ヨル",
                M8: "カタ",
              };
              //注意：define値と同期
              let tobArray = {
                1: "単勝",
                2: "複勝",
                3: "枠連",
                4: "馬連",
                5: "ワイド",
                6: "馬単",
                7: "3連複",
                8: "3連単",
                9: "馬単",
                10: "3連単",
                11: "枠単",
                12: "枠単",
              };
              //注意：define値と同期
              let methodArr = {
                0: "通常",
                1: "フォーメーション",
                2: "ボックス",
                3: "流し",
                4: "1着軸流し",
                5: "2着軸流し",
                6: "3着軸流し",
                7: "1・2着軸流し",
                8: "1・3着軸流し",
                9: "2・3着軸流し",
              };
              //ヘッダ生成
              let headTitle =
                targetData.dor.substr(0, 4) +
                "/" +
                targetData.dor.substr(4, 2) +
                "/" +
                targetData.dor.substr(6, 2) +
                " " +
                place[targetData.racetrackCd] +
                raceNum +
                "R " +
                tobArray[targetData.TOB];
              if (targetData.TOB != TOB_WIN && targetData.TOB != TOB_PLACE) {
                if (
                  targetData.TOB == TOB_TRIO &&
                  targetData.method == METHOD_WHEEL_1
                ) {
                  headTitle += " " + "1頭軸流し";
                } else if (
                  targetData.TOB == TOB_TRIO &&
                  targetData.method == METHOD_WHEEL_2
                ) {
                  headTitle += " " + "2頭軸流し";
                } else {
                  headTitle += " " + methodArr[targetData.method];
                }
              }
              if (
                targetData.TOB == TOB_EXACTA_MULTI ||
                targetData.TOB == TOB_BRACKET_EXACTA_MULTI ||
                targetData.TOB == TOB_TRIFECTA_MULTI
              ) {
                headTitle += "マルチ";
              }
              // レースのタイトル
              // メソッド
              targetData.HeaderTitle = headTitle;
              targetData.Method = targetData.method;
            } else {
              //全て一致の場合、prevデータを追加したテーブルに追加する。
              targetData.tableId = prevTargetData.tableId;
            }

            //１列目値の特定
            var leftNo = [];
            var leftFormatTags = "";
            for (var betCheck = 1; betCheck <= 18; betCheck++) {
              if (targetData.bet.substr(betCheck - 1, 1) == "1") {
                leftNo.push(betCheck);
                var wNo = "";
                if (
                  (targetData.TOB == TOB_BRACKET &&  targetData.method == METHOD_BOX) ||
                  isBracketExacta
                ) {
                  //枠連ボックスの場合
                  wNo = betCheck;
                } else {
                  wNo = calcBracketNum(raceEntryNum, betCheck);
                }
                leftFormatTags +=
                  "<i class='icon-umaban -waku0" +
                  wNo +
                  "'>" +
                  betCheck +
                  "</i>";
              }
              targetData.LeftFormatTags = leftFormatTags;
              targetData.BetCheck = betCheck;
            }

            if (
              isBracket &&
              targetData.method == METHOD_BOX
            ) {
              //枠連ボックスの場合、同枠をチェック
              var same_bracket = "";
              for (var sameCheck = 19; sameCheck <= 36; sameCheck++) {
                if (targetData.bet.substr(sameCheck - 1, 1) == "1") {
                  if (same_bracket != "") {
                    same_bracket += " ";
                  }
                  same_bracket += String(sameCheck - 18);
                }
              }
              if (same_bracket != "") {
                same_bracket = "同枠：" + same_bracket;
              }
              // 同枠チェック
              targetData.LeftFormatTags =
                leftFormatTags + "<br>" + same_bracket;
              targetData.SameBracket = same_bracket;
            }

            //２列目値の特定
            var centerNo = [];
            var centerFormatTags = "";
            for (var betCheck = 19; betCheck <= 36; betCheck++) {
              if (targetData.bet.substr(betCheck - 1, 1) == "1") {
                const horseNum = betCheck - 18;
                centerNo.push(horseNum);
                var wNo = isBracketExacta ? horseNum : calcBracketNum(raceEntryNum, horseNum);
                centerFormatTags +=
                  "<i class='icon-umaban -waku0" +
                  wNo +
                  "'>" +
                  horseNum +
                  "</i>";
              }
              targetData.CenterFormatTags = centerFormatTags;
            }

            //３列目値の特定
            var rightNo = [];
            var rightFormatTags = "";
            for (var betCheck = 37; betCheck <= 54; betCheck++) {
              if (targetData.bet.substr(betCheck - 1, 1) == "1") {
                const horseNum = betCheck - 36;
                rightNo.push(horseNum);
                var wNo = calcBracketNum(raceEntryNum, horseNum);
                rightFormatTags +=
                  "<i class='icon-umaban -waku0" +
                  wNo +
                  "'>" +
                  horseNum +
                  "</i>";
              }
              targetData.RightFormatTags = rightFormatTags;
            }

            var addTags = "";
            //■明細が１行となる通常、ボックスの場合
            if (
              targetData.method == METHOD_NORMAL ||
              targetData.method == METHOD_BOX
            ) {
              if (targetData.TOB == TOB_WIN || targetData.TOB == TOB_PLACE) {
                //単勝、複勝の場合
                var wNo = calcBracketNum(raceEntryNum, leftNo[0]);
                targetData.HorseNum = "icon-umaban -waku0" + wNo;
                targetData.LeftNo = leftNo[0];
              } else if (
                (targetData.TOB == TOB_BRACKET ||
                  targetData.TOB == TOB_BRACKET_EXACTA ||
                  targetData.TOB == TOB_QUINELLA ||
                  targetData.TOB == TOB_EXACTA ||
                  targetData.TOB == TOB_WIDE) &&
                targetData.method == METHOD_NORMAL
              ) {
                let left;
                let center;
                if (targetData.TOB == TOB_EXACTA || targetData.TOB == TOB_BRACKET_EXACTA) {
                  left = leftNo[0];
                  center = centerNo[0];
                } else {
                  if (leftNo[0] < centerNo[0]) {
                    left = leftNo[0];
                    center = centerNo[0];
                  } else {
                    left = centerNo[0];
                    center = leftNo[0];
                  }
                }

                //枠連基本、枠単基本、馬連基本、馬単基本、ワイド基本の場合
                var wNol =
                  isBracket
                    ? left
                    : calcBracketNum(raceEntryNum, left);
                var wNoc =
                  isBracket
                    ? center
                    : calcBracketNum(raceEntryNum, center);

                targetData.wNol = "icon-umaban -waku0" + wNol;
                targetData.wNoc = "icon-umaban -waku0" + wNoc;
                targetData.Left = left;
                targetData.Center = center;
              } else if (targetData.method == METHOD_BOX) {
                //枠連基本、馬連基本、馬単基本、ワイド、3連複、3連単ボックス基本の場合
                var ten = 0;
                if (targetData.TOB == TOB_TRIO) {
                  ten =
                    (leftNo.length *
                      (leftNo.length - 1) *
                      (leftNo.length - 2)) /
                    6;
                } else if (targetData.TOB == TOB_TRIFECTA) {
                  ten =
                    leftNo.length * (leftNo.length - 1) * (leftNo.length - 2);
                } else if (targetData.TOB == TOB_EXACTA || targetData.TOB == TOB_BRACKET_EXACTA) {
                  ten = leftNo.length * (leftNo.length - 1);
                } else if (targetData.TOB == TOB_BRACKET) {
                  for (var j = 0; j < leftNo.length; j++) {
                    ten += j;
                  }
                  var sameFrame = 0;
                  for (var betCheck = 19; betCheck <= 36; betCheck++) {
                    if (targetData.bet.substr(betCheck - 1, 1) == "1") {
                      sameFrame++;
                    }
                  }
                  ten += sameFrame;
                } else {
                  for (var j = 0; j < leftNo.length; j++) {
                    ten += j;
                  }
                }

                targetData.LeftFormatTags = leftFormatTags;
                targetData.SameBracket = same_bracket;
                targetData.Ten = ten;
                itemCount = ten;
              } else if (
                (targetData.TOB == TOB_TRIO ||
                  targetData.TOB == TOB_TRIFECTA) &&
                targetData.method == METHOD_NORMAL
              ) {
                //3連複、3連単の基本
                var left, center, right;
                if (targetData.TOB == TOB_TRIFECTA) {
                  left = leftNo[0];
                  center = centerNo[0];
                  right = rightNo[0];
                }
                if (targetData.TOB == TOB_TRIO) {
                  if (leftNo[0] > centerNo[0] && leftNo[0] > rightNo[0]) {
                    if (centerNo[0] > rightNo[0]) {
                      left = rightNo[0];
                      center = centerNo[0];
                      right = leftNo[0];
                    } else if (centerNo[0] < rightNo[0]) {
                      left = centerNo[0];
                      center = rightNo[0];
                      right = leftNo[0];
                    }
                  } else if (
                    centerNo[0] > leftNo[0] &&
                    centerNo[0] > rightNo[0]
                  ) {
                    if (leftNo[0] > rightNo[0]) {
                      left = rightNo[0];
                      center = leftNo[0];
                      right = centerNo[0];
                    } else if (leftNo[0] < rightNo[0]) {
                      left = leftNo[0];
                      center = rightNo[0];
                      right = centerNo[0];
                    }
                  } else if (
                    rightNo[0] > leftNo[0] &&
                    rightNo[0] > centerNo[0]
                  ) {
                    if (leftNo[0] > centerNo[0]) {
                      left = centerNo[0];
                      center = leftNo[0];
                      right = rightNo[0];
                    } else if (leftNo[0] < centerNo[0]) {
                      left = leftNo[0];
                      center = centerNo[0];
                      right = rightNo[0];
                    }
                  }
                }
                var wNol = calcBracketNum(raceEntryNum, left);
                var wNoc = calcBracketNum(raceEntryNum, center);
                var wNor = calcBracketNum(raceEntryNum, right);
                // "<i class='icon-umaban -waku0" + wNoc +"'>" + center + "</i>"
                targetData.wNol = "icon-umaban -waku0" + wNol;
                targetData.wNoc = "icon-umaban -waku0" + wNoc;
                targetData.wNor = "icon-umaban -waku0" + wNor;
                targetData.Left = left;
                targetData.Center = center;
                targetData.Right = right;
              }
              //右側共通部
              var rowspan = "1";
              if (targetData.method == METHOD_NORMAL) {
                rowspan = "2";
              }
              var amount = "1";
              if (targetData.amount != undefined) {
                if (parseInt(targetData.amount, 10) != NaN) {
                  amount = targetData.amount;
                }
              }
              targetData.Rowspan = rowspan;
              targetData.Amount = amount;

              if (targetData.method == METHOD_NORMAL) {
                //通常のみ払い戻し予定額
                let amountRefund = '<span id="payout' + i + '">';
                targetData.Refund = amountRefund;
              }
            }

            //■明細が２行となる馬連、馬単、ワイドの流し、フォーメーション、
            // 3連複 1頭軸流し・2頭軸流し、3連単 1着軸流し・2着軸流し・3着軸流しの場合
            if (
              ((targetData.TOB == TOB_EXACTA ||
                targetData.TOB == TOB_BRACKET_EXACTA ||
                targetData.TOB == TOB_BRACKET_EXACTA_MULTI ||
                targetData.TOB == TOB_QUINELLA ||
                targetData.TOB == TOB_WIDE ||
                targetData.TOB == TOB_EXACTA_MULTI) &&
                (targetData.method == METHOD_FORMATION ||
                  targetData.method == METHOD_WHEEL ||
                  targetData.method == METHOD_WHEEL_1 ||
                  targetData.method == METHOD_WHEEL_2)) ||
              ((targetData.TOB == TOB_TRIO ||
                targetData.TOB == TOB_TRIFECTA ||
                targetData.TOB == TOB_TRIFECTA_MULTI) &&
                (targetData.method == METHOD_WHEEL_1 ||
                  targetData.method == METHOD_WHEEL_2 ||
                  targetData.method == METHOD_WHEEL_3))
            ) {
              var ten = 0;
              if (
                ((targetData.TOB == TOB_QUINELLA ||
                  targetData.TOB == TOB_WIDE) &&
                  targetData.method == METHOD_WHEEL) ||
                (targetData.TOB == TOB_BRACKET_EXACTA &&
                  (targetData.method == METHOD_WHEEL_1 ||
                    targetData.method == METHOD_WHEEL_2)) ||
                (targetData.TOB == TOB_EXACTA &&
                  (targetData.method == METHOD_WHEEL_1 ||
                    targetData.method == METHOD_WHEEL_2)) ||
                (targetData.TOB == TOB_TRIO &&
                  targetData.method == METHOD_WHEEL_2)
              ) {
                ten = centerNo.length;
              } else if (
                (targetData.TOB == TOB_QUINELLA ||
                  targetData.TOB == TOB_WIDE) &&
                targetData.method == METHOD_FORMATION
              ) {
                var checkBets = new Array();
                for (var j = 0; j < leftNo.length; j++) {
                  for (var k = 0; k < centerNo.length; k++) {
                    if (leftNo[j] != centerNo[k]) {
                      var targetBet = "";
                      if (leftNo[j] < centerNo[k]) {
                        targetBet = leftNo[j] + "_" + centerNo[k];
                      } else {
                        targetBet = centerNo[k] + "_" + leftNo[j];
                      }
                      //既存かチェック
                      if (
                        !("," + checkBets.join() + ",").match(
                          "," + targetBet + ","
                        )
                      ) {
                        checkBets.push(targetBet);
                      }
                    }
                  }
                }
                ten = checkBets.length;
              } else if (
                targetData.TOB == TOB_TRIFECTA &&
                (targetData.method == METHOD_WHEEL_1 ||
                  targetData.method == METHOD_WHEEL_2 ||
                  targetData.method == METHOD_WHEEL_3)
              ) {
                ten = centerNo.length * (centerNo.length - 1);
              } else if (
                targetData.TOB == TOB_TRIFECTA_MULTI &&
                (targetData.method == METHOD_WHEEL_1 ||
                  targetData.method == METHOD_WHEEL_2 ||
                  targetData.method == METHOD_WHEEL_3)
              ) {
                ten = centerNo.length * (centerNo.length - 1) * 3;
              } else if (
                (targetData.TOB == TOB_EXACTA) &&
                targetData.method == METHOD_FORMATION
              ) {
                for (var j = 0; j < leftNo.length; j++) {
                  for (var k = 0; k < centerNo.length; k++) {
                    if (leftNo[j] != centerNo[k]) {
                      ten++;
                    }
                  }
                }
              } else if (
                (targetData.TOB == TOB_BRACKET_EXACTA) &&
                targetData.method == METHOD_FORMATION
              ) {
                for (var j = 0; j < leftNo.length; j++) {
                  for (var k = 0; k < centerNo.length; k++) {
                    if (leftNo[j] === centerNo[k] && !isCoupleBracketNum(raceEntryNum, leftNo[j])) {
                      continue;
                    }
                    ten++;
                  }
                }
              } else if (
                (targetData.TOB == TOB_EXACTA_MULTI) &&
                (targetData.method == METHOD_WHEEL_1 ||
                  targetData.method == METHOD_WHEEL_2)
              ) {
                ten = centerNo.length * 2;
              } else if (
                (targetData.TOB == TOB_BRACKET_EXACTA_MULTI) &&
                (targetData.method == METHOD_WHEEL_1 ||
                  targetData.method == METHOD_WHEEL_2)
              ) {
                const hasCouple = centerNo.includes(leftNo[0])
                ten = centerNo.length * 2 - (hasCouple ? 1 : 0);
              } else if (
                targetData.TOB == TOB_TRIO &&
                targetData.method == METHOD_WHEEL_1
              ) {
                ten = (centerNo.length * (centerNo.length - 1)) / 2;
              } else {
                for (var j = 0; j < leftNo.length; j++) {
                  ten += j;
                }
              }
              itemCount = ten;

              var firstTitle = "軸馬";
              if (targetData.method == METHOD_FORMATION) firstTitle = "1頭目";
              if (
                targetData.TOB == TOB_TRIO &&
                targetData.method == METHOD_WHEEL_1
              )
                firstTitle = "1頭軸";
              if (
                targetData.TOB == TOB_TRIO &&
                targetData.method == METHOD_WHEEL_2
              )
                firstTitle = "2頭軸";
              if (
                targetData.TOB == TOB_TRIFECTA &&
                targetData.method == METHOD_WHEEL_1
              )
                firstTitle = "1着軸";
              if (
                targetData.TOB == TOB_TRIFECTA &&
                targetData.method == METHOD_WHEEL_2
              )
                firstTitle = "2着軸";
              if (
                targetData.TOB == TOB_TRIFECTA &&
                targetData.method == METHOD_WHEEL_3
              )
                firstTitle = "3着軸";

              let secondTitle = "相手";
              if (targetData.method == METHOD_FORMATION) secondTitle = "2頭目";

              let amount = "1";
              if (targetData.amount != undefined) {
                if (parseInt(targetData.amount, 10) != NaN) {
                  amount = targetData.amount;
                }
              }
              targetData.FirstTitle = firstTitle;
              targetData.SecondTitle = secondTitle;
              targetData.LeftFormatTags = leftFormatTags;
              targetData.CenterFormatTags = centerFormatTags;
              targetData.Ten = ten;
              targetData.Amount = amount;
            }

            //■明細が３行となる３連単、３連複の1・2着軸流し、1・3着軸流し、2・3着軸流し、フォーメーションの場合
            if (
              (targetData.TOB == TOB_TRIO ||
                targetData.TOB == TOB_TRIFECTA ||
                targetData.TOB == TOB_TRIFECTA_MULTI) &&
              (targetData.method == METHOD_WHEEL_1_2 ||
                targetData.method == METHOD_WHEEL_1_3 ||
                targetData.method == METHOD_WHEEL_2_3 ||
                targetData.method == METHOD_FORMATION)
            ) {
              var ten = 0;
              if (
                targetData.TOB == TOB_TRIO &&
                targetData.method == METHOD_FORMATION
              ) {
                var betsList = new Array();
                for (var l = 0; l < leftNo.length; l++) {
                  for (var m = 0; m < centerNo.length; m++) {
                    if (leftNo[l] == centerNo[m]) {
                      continue;
                    }
                    for (var n = 0; n < rightNo.length; n++) {
                      if (
                        leftNo[l] == rightNo[n] ||
                        centerNo[m] == rightNo[n]
                      ) {
                        continue;
                      }
                      var targetBet = "";
                      if (leftNo[l] > centerNo[m] && leftNo[l] > rightNo[n]) {
                        if (centerNo[m] > rightNo[n]) {
                          targetBet =
                            rightNo[n] + "_" + centerNo[m] + "_" + leftNo[l];
                        } else if (centerNo[m] < rightNo[n]) {
                          targetBet =
                            centerNo[m] + "_" + rightNo[n] + "_" + leftNo[l];
                        }
                      } else if (
                        centerNo[m] > leftNo[l] &&
                        centerNo[m] > rightNo[n]
                      ) {
                        if (leftNo[l] > rightNo[n]) {
                          targetBet =
                            rightNo[n] + "_" + leftNo[l] + "_" + centerNo[m];
                        } else if (leftNo[l] < rightNo[n]) {
                          targetBet =
                            leftNo[l] + "_" + rightNo[n] + "_" + centerNo[m];
                        }
                      } else if (
                        rightNo[n] > leftNo[l] &&
                        rightNo[n] > centerNo[m]
                      ) {
                        if (leftNo[l] > centerNo[m]) {
                          targetBet =
                            centerNo[m] + "_" + leftNo[l] + "_" + rightNo[n];
                        } else if (leftNo[l] < centerNo[m]) {
                          targetBet =
                            leftNo[l] + "_" + centerNo[m] + "_" + rightNo[n];
                        }
                      }
                      //既存かチェック
                      if (
                        !("," + betsList.join() + ",").match(
                          "," + targetBet + ","
                        )
                      ) {
                        betsList.push(targetBet);
                      }
                    }
                  }
                }
                ten = betsList.length;
              } else if (
                targetData.TOB == TOB_TRIFECTA &&
                targetData.method == METHOD_WHEEL_1_2
              ) {
                ten = rightNo.length;
              } else if (
                targetData.TOB == TOB_TRIFECTA &&
                targetData.method == METHOD_WHEEL_1_3
              ) {
                ten = centerNo.length;
              } else if (
                targetData.TOB == TOB_TRIFECTA &&
                targetData.method == METHOD_WHEEL_2_3
              ) {
                ten = leftNo.length;
              } else if (
                targetData.TOB == TOB_TRIFECTA_MULTI &&
                targetData.method == METHOD_WHEEL_1_2
              ) {
                ten = rightNo.length * 6;
              } else if (
                targetData.TOB == TOB_TRIFECTA_MULTI &&
                targetData.method == METHOD_WHEEL_1_3
              ) {
                ten = centerNo.length * 6;
              } else if (
                targetData.TOB == TOB_TRIFECTA_MULTI &&
                targetData.method == METHOD_WHEEL_2_3
              ) {
                ten = leftNo.length * 6;
              } else if (
                targetData.TOB == TOB_TRIFECTA &&
                targetData.method == METHOD_FORMATION
              ) {
                var count = 0;
                for (var l = 0; l < leftNo.length; l++) {
                  for (var m = 0; m < centerNo.length; m++) {
                    if (leftNo[l] == centerNo[m]) {
                      continue;
                    }
                    for (var k = 0; k < rightNo.length; k++) {
                      if (
                        leftNo[l] == rightNo[k] ||
                        centerNo[m] == rightNo[k]
                      ) {
                        continue;
                      }
                      count++;
                    }
                  }
                }
                ten = count;
              } else {
                for (var j = 0; j < leftNo.length; j++) {
                  ten += j;
                }
              }

              itemCount = ten;
              var firstTitle = "1着軸";
              var secondTitle = "2着軸";
              var thirdTitle = "3着軸";
              if (targetData.method == METHOD_WHEEL_2_3) firstTitle = "相手";
              if (targetData.method == METHOD_WHEEL_1_3) secondTitle = "相手";
              if (targetData.method == METHOD_WHEEL_1_2) thirdTitle = "相手";

              if (targetData.method == METHOD_FORMATION) {
                firstTitle = "1頭目";
                secondTitle = "2頭目";
                thirdTitle = "3頭目";
              }

              var amount = "1";
              if (targetData.amount != undefined) {
                if (parseInt(targetData.amount, 10) != NaN) {
                  amount = targetData.amount;
                }
              }
              targetData.FirstTitle = firstTitle;
              targetData.SecondTitle = secondTitle;
              targetData.ThirdTitle = thirdTitle;
              targetData.LeftFormatTags = leftFormatTags;
              targetData.CenterFormatTags = centerFormatTags;
              targetData.RightFormatTags = rightFormatTags;
              targetData.Ten = ten;
              targetData.Amount = amount;
            }

            targetData.itemCount = String(itemCount);

            targetData.checkboxId = "post" + i;
            targetData.amountId = "amount" + i;
            targetData.oddsId = "odds" + i;
            targetData.payoutId = "payout" + i;

            //オッズ取得用
            if (leftNo != []) {
              targetData.oddsIndexString = "dummy" + i + leftNo[0];
            }
            if (centerNo != []) {
              targetData.oddsIndexString += "_" + centerNo[0];
            }
            if (rightNo != []) {
              targetData.oddsIndexString = "_" + rightNo[0];
            }

            targetData.Method = targetData.method;

            prevTargetData = targetData;
            let dataList = new Object();

            allList.push(targetData);
            amountList.push(targetData.Amount);
            purchaseCount.push(targetData.Ten);

            if (i != 0 && targetData.tableId == allList[i - 1].tableId) {
              dataList.detail = {
                TOB: targetData.TOB,
                HorseNum: targetData.HorseNum,
                Method: targetData.Method,
                Amount: targetData.Amount,
                LeftNo: targetData.LeftNo,
                Rowspan: targetData.Rowspan,
                wNol: targetData.wNol,
                wNoc: targetData.wNoc,
                wNor: targetData.wNor,
                Ten: targetData.Ten,
                BetCheck: targetData.BetCheck,
                Left: targetData.Left,
                Center: targetData.Center,
                Right: targetData.Right,
                oddsValue: targetData.oddsValue,
                oddsClass: targetData.oddsClass,
                odds1Class: targetData.odds1Class,
                odds2Class: targetData.odds2Class,
                odds1Value: targetData.odds1Value,
                odds2Value: targetData.odds2Value,
                uniqueNum: i,
                FirstTitle: targetData.FirstTitle,
                SecondTitle: targetData.SecondTitle,
                ThirdTitle: targetData.ThirdTitle,
                LeftFormatTags: targetData.LeftFormatTags,
                CenterFormatTags: targetData.CenterFormatTags,
                RightFormatTags: targetData.RightFormatTags,
                oddsString: targetData.oddsString,
              };

              // まとめる買い目を判断するキーデータ
              let k = addItemCount.value - 1;
              ContentListAll[k].detail.push(dataList.detail);
            } else {
              dataList.HeaderTitle = targetData.HeaderTitle;
              dataList.Method = targetData.Method;
              dataList.isBracketExacta = isBracketExacta;
              dataList.detail = [
                {
                  TOB: targetData.TOB,
                  HorseNum: targetData.HorseNum,
                  Method: targetData.Method,
                  Amount: targetData.Amount,
                  LeftNo: targetData.LeftNo,
                  Rowspan: targetData.Rowspan,
                  wNol: targetData.wNol,
                  wNoc: targetData.wNoc,
                  wNor: targetData.wNor,
                  Ten: targetData.Ten,
                  BetCheck: targetData.BetCheck,
                  Left: targetData.Left,
                  Center: targetData.Center,
                  Right: targetData.Right,
                  oddsValue: targetData.oddsValue,
                  oddsClass: targetData.oddsClass,
                  odds1Class: targetData.odds1Class,
                  odds2Class: targetData.odds2Class,
                  odds1Value: targetData.odds1Value,
                  odds2Value: targetData.odds2Value,
                  uniqueNum: i,
                  FirstTitle: targetData.FirstTitle,
                  SecondTitle: targetData.SecondTitle,
                  ThirdTitle: targetData.ThirdTitle,
                  LeftFormatTags: targetData.LeftFormatTags,
                  CenterFormatTags: targetData.CenterFormatTags,
                  RightFormatTags: targetData.RightFormatTags,
                  TableId: targetData.tableId,
                },
              ];
              //変数に保存
              ContentListAll.push(dataList);
              addItemCount.value += 1;
            }
            CheckList.push(false);
          }
        );
      }
    }
  } catch (e) {
    console.log("setData error", e);
  }
};

// オッズの取得
const setOdds = async (targetData) => {
  //買い方が基本の場合のみ
  if (targetData.method == METHOD_NORMAL) {
    let callJS = "Odds/winTicketOdds.js";
    if (targetData.TOB == TOB_PLACE) callJS = "Odds/placeTicketOdds.js"; //複勝
    if (targetData.TOB == TOB_BRACKET) callJS = "Odds/bracketQuinellaOdds.js"; //枠連
    if (targetData.TOB == TOB_BRACKET_EXACTA || targetData.TOB == TOB_BRACKET_EXACTA_MULTI)
      callJS = "Odds/bracketExactaOdds.js"; //枠単
    if (targetData.TOB == TOB_QUINELLA) callJS = "Odds/quinellaOdds.js"; //馬連
    if (targetData.TOB == TOB_WIDE) callJS = "Odds/wideOdds.js"; //ワイド
    if ((targetData.TOB || TOB_EXACTA_MULTI) == TOB_EXACTA)
      callJS = "Odds/exactaOdds.js"; //馬単
    if (targetData.TOB == TOB_TRIO) callJS = "Odds/trioOdds.js"; //３連複
    if (targetData.TOB == TOB_TRIFECTA || targetData.TOB == TOB_TRIFECTA_MULTI)
      callJS = "Odds/trifectaOdds.js"; //３連単

    let raceNum = targetData.raceNum;
    if (raceNum.length == 3) {
      raceNum = raceNum.slice(1, 3);
    }
    let req =
      HOST +
      PATH +
      callJS +
      BACKWORDPATH +
      "?RacetrackCd=" +
      targetData.racetrackCd +
      "&DOR=" +
      targetData.dor +
      "&RaceNum=" +
      raceNum +
      "&NarFlag=" +
      isNar();

    await getJSON(req, true, async function (status, json, data) {
      if (status == SUCCESS) {
        //データは常に１件が正しい。
        if (json != undefined && json.length > 0) {
          var displayData = json[0];
          //１列目値の特定
          var leftNo = [];
          for (var betCheck = 1; betCheck <= 18; betCheck++) {
            if (targetData.bet.substr(betCheck - 1, 1) == "1") {
              leftNo.push(betCheck);
            }
          }

          //２列目値の特定
          var centerNo = [];
          for (var betCheck = 19; betCheck <= 36; betCheck++) {
            if (targetData.bet.substr(betCheck - 1, 1) == "1") {
              centerNo.push(betCheck - 18);
            }
          }

          //３列目値の特定
          var rightNo = [];
          for (var betCheck = 37; betCheck <= 54; betCheck++) {
            if (targetData.bet.substr(betCheck - 1, 1) == "1") {
              rightNo.push(betCheck - 36);
            }
          }
          var oddsString = leftNo[0]; //単勝、複勝

          //馬単、枠単
          if (targetData.TOB == TOB_EXACTA ||
            targetData.TOB == TOB_BRACKET_EXACTA) {
            oddsString = leftNo[0] + "_" + centerNo[0];
          }
          //枠連、馬連、ワイド
          if (
            targetData.TOB == TOB_BRACKET ||
            targetData.TOB == TOB_QUINELLA ||
            targetData.TOB == TOB_WIDE
          ) {
            if (leftNo[0] < centerNo[0]) {
              oddsString = leftNo[0] + "_" + centerNo[0];
            } else {
              oddsString = centerNo[0] + "_" + leftNo[0];
            }
          }
          //３連単
          if (targetData.TOB == TOB_TRIFECTA) {
            oddsString = leftNo[0] + "_" + centerNo[0] + "_" + rightNo[0];
          }
          //３連複
          if (targetData.TOB == TOB_TRIO) {
            if (leftNo[0] > centerNo[0] && leftNo[0] > rightNo[0]) {
              if (centerNo[0] > rightNo[0]) {
                oddsString = rightNo[0] + "_" + centerNo[0] + "_" + leftNo[0];
              } else if (centerNo[0] < rightNo[0]) {
                oddsString = centerNo[0] + "_" + rightNo[0] + "_" + leftNo[0];
              }
            } else if (centerNo[0] > leftNo[0] && centerNo[0] > rightNo[0]) {
              if (leftNo[0] > rightNo[0]) {
                oddsString = rightNo[0] + "_" + leftNo[0] + "_" + centerNo[0];
              } else if (leftNo[0] < rightNo[0]) {
                oddsString = leftNo[0] + "_" + rightNo[0] + "_" + centerNo[0];
              }
            } else if (rightNo[0] > leftNo[0] && rightNo[0] > centerNo[0]) {
              if (leftNo[0] > centerNo[0]) {
                oddsString = centerNo[0] + "_" + leftNo[0] + "_" + rightNo[0];
              } else if (leftNo[0] < rightNo[0]) {
                oddsString = leftNo[0] + "_" + centerNo[0] + "_" + rightNo[0];
              }
            }
          }

          //オッズ設定
          if (targetData.TOB == TOB_PLACE || targetData.TOB == TOB_WIDE) {
            //複勝、オッズの場合はmin、max
            if (displayData["MinOdds" + oddsString] != undefined) {
              let odds1 = displayData["MinOdds" + oddsString];
              let odds1Color = "0";
              if (Number(odds1) < 10) {
                odds1Color = "1";
              } else if (Number(odds1) < 100) {
                odds1Color = "2";
              }
              let odds2 = displayData["MaxOdds" + oddsString];
              odds2 = odds2.substr(odds2.indexOf("-") + 1);
              let odds2Color = "0";
              if (Number(odds2) < 10) {
                odds2Color = "1";
              } else if (Number(odds2) < 100) {
                odds2Color = "2";
              }
              targetData.odds1Value = odds1;
              targetData.odds1Class = odds1Color;
              targetData.odds2Value = odds2;
              targetData.odds2Class = odds2Color;
            }
          } else {
            //その他
            if (displayData["Odds" + oddsString] != undefined) {
              targetData.oddsValue = displayData["Odds" + oddsString];
              targetData.oddsString = displayData["Odds" + oddsString];
            }

            // オッズの表示色のクラスを付与
            if (displayData["Odds" + oddsString].length == 3) {
              targetData.oddsClass = "txt-odds -no1";
            } else if (displayData["Odds" + oddsString].length == 4) {
              targetData.oddsClass = "txt-odds -no2";
            } else {
              targetData.oddsClass = "txt-odds -no3";
            }
          }
        }
      }
    });
  }
  return targetData;
};

// 払戻金の計算
const setRefound = (odds, amount) => {
  let payment = amount * 100;
  if (odds != undefined && odds != null && odds != "") {
    odds = odds.replace(".", "");
  }
  //数値にカンマをつける
  let refund = calRefund(odds, payment);
  if (refund.length > 3) {
    refund = replaceComma(refund);
  }
  return refund;
};

//ベット額更新のモーダル表示
const amountModal = (detail) => {
  refundValue = detail;
  modalAmountOpen.value = true;
};

// 金額一括入力のモーダルからの入力内容受け取り
const receiveAmountItem = (e) => {
  for (let i = 0; i < refundValue.length; i++) {
    let odds = refundValue[i].oddsString;
    let index = refundValue[i].uniqueNum;
    resetRefund(odds, index, e);
  }
};

//払戻金の再描画
const resetRefund = (odds, index, event) => {
  document.body.className = "";
  itemPrize.value = 0;
  amountList[index] = event.target.value;

  for (let i = 0; i < CheckList.length; i++) {
    if (CheckList[i] == true) {
      if (purchaseCount[i] == undefined) {
        itemPrize.value += amountList[i] * 100;
      } else {
        itemPrize.value += purchaseCount[i] * amountList[i] * 100;
      }
    }
  }

  // 買い目情報をアップデートする
  let itemNum = event.target.value;
  if (itemNum != NaN) {
    let betRaceNum = allList[index].raceNum;
    allList[index].Amount = event.target.value
    if (betRaceNum.length == 3) {
      betRaceNum = betRaceNum.slice(1, 3);
    }

    if (getCourse() == PAYINGSUGOTOKU || getCourse() == PAYINGSUGOTOKU_NONE) {
        var oldArray = getBet4Array();
        var newArray = new Array();
        for (var i in oldArray) {
            if (oldArray[i].puid != allList[index].puid) {
                newArray.push(oldArray[i]);
            } else {
                var obj = new Object();
                obj["dor"] = allList[index].dor;
                obj["racetrackCd"] = allList[index].racetrackCd;
                obj["raceNum"] = betRaceNum;
                obj["TOB"] = allList[index].TOB;
                obj["method"] = allList[index].method;
                obj["bet"] = allList[index].bet;
                obj["puid"] = allList[index].puid;
                obj["amount"] = allList[index].Amount
                newArray.push(obj);
            }
        }
        setBet4Array(newArray);
    } else {
      let updateAmount =
        HOST +
        PATH +
        "Odds/updateRaceBet.js" +
        BACKWORDPATH +
        "?puid=" +
        allList[index].puid +
        "&dor=" +
        allList[index].dor +
        "&racetrackCd=" +
        allList[index].racetrackCd +
        "&raceNum=" +
        betRaceNum +
        "&TOB=" +
        allList[index].TOB +
        "&method=" +
        allList[index].method +
        "&bet=" +
        allList[index].bet +
        "&amount=" +
        event.target.value;

      getJSON(updateAmount, false, function (status, json) {});
    }
  }
  document.body.className = "status-loaded";

  return refundValue[index];
};

// 全選択
const setAllSelect = (e) => {
  totalCount.value = 0;
  itemPrize.value = 0;
  if (e) {
    for (let i = 0; i < CheckList.length; i++) {
      if ([TOB_BRACKET_EXACTA, TOB_BRACKET_EXACTA_MULTI].includes(allList[i].TOB)) continue;
      if (i === 255) {
        break;
      }
      CheckList[i] = true;
      if (purchaseCount[i] == undefined) {
        totalCount.value += 1;
        itemPrize.value += amountList[i] * 100;
      } else {
        totalCount.value += 1 * purchaseCount[i];
        itemPrize.value += purchaseCount[i] * amountList[i] * 100;
      }
    }
  }
};

// レース毎の全選択
const setRaceAll = (detail, e) => {
  totalCount.value = 0;
  itemPrize.value = 0;

  if (e.target.checked) {
    for (let i = 0; i < detail.length; i++) {
      let unique = detail[i].uniqueNum;
      CheckList[unique] = true;
    }
  } else {
    for (let i = 0; i < detail.length; i++) {
      let unique = detail[i].uniqueNum;
      CheckList[unique] = false;
    }
  }

  for (let i = 0; i < CheckList.length; i++) {
    if (CheckList[i] == true) {
      if (purchaseCount[i] == undefined) {
        totalCount.value += 1;
        itemPrize.value += amountList[i] * 100;
      } else {
        totalCount.value += 1 * purchaseCount[i];
        itemPrize.value += purchaseCount[i] * amountList[i] * 100;
      }
    }
  }

  if (totalCount.value >= CheckList.length + 1) {
    totalCount.value -= 1;
  }
};

// 一つずつ選択
const setSelect = (uniqueNum, e) => {
  totalCount.value = 0;
  itemPrize.value = 0;
  if (e.target.checked) {
    CheckList[uniqueNum] = true;
  } else {
    let amount = allList[uniqueNum].Amount;
    CheckList[uniqueNum] = false;
  }

  for (let i = 0; i < CheckList.length; i++) {
    if (CheckList[i] == true) {
      if (purchaseCount[i] == undefined) {
        totalCount.value += 1;
        itemPrize.value += amountList[i] * 100;
      } else {
        totalCount.value += 1 * purchaseCount[i];
        itemPrize.value += purchaseCount[i] * amountList[i] * 100;
      }
    }
  }
};

// 削除用モーダルウィンドウ
const delModal = () => {
  if (CheckList.some((check) => check === true)) {
    modalDelOpen.value = true;
  } else {
    alert("項目が選択されていません。");
  }
};

// 削除機能
const delItem = () => {
  document.body.className = "";
  let deleteIndex = "_";
  // チェック済み項目の個数チェック
  for (let i = 0; i < CheckList.length; i++) {
    if (CheckList[i] === true) {
      deleteIndex += i;
      deleteIndex += "_";
    }
  }

  if (getCourse() == PAYINGSUGOTOKU || getCourse() == PAYINGSUGOTOKU_NONE) {
    var array = getBet4Array();
    var restArray = new Array();
    for (var i = 0; i < array.length; i++) {
        if (deleteIndex.indexOf("_" + i + "_") == -1) {
            restArray.push(array[i]);
        }
    }
    setBet4Array(restArray);
    doInit();
  } else {
    const request =
      HOST +
      PATH +
      "Odds/deleteBet.js" +
      BACKWORDPATH +
      "?deleteIndex=" +
      deleteIndex;
    getJSON(request, true, function (status, json) {
      if (status == SUCCESS) {
        doInit();
      }
    });
  }
};

//収支追加
const setBalance = (targetData) => {
  document.body.className = "";
  if (CheckList.some((check) => check === true)) {
    let listLength = CheckList.length;
    let amountError = false;
    for (let i = 0; i < listLength; i++) {
      let amount = amountList[i];
      if (CheckList[i] === true) {
        if (isNaN(amount) || amount < 1 || amount > 9999) {
          amountError = true;
        }
      }
    }
    if (amountError) {
      alert("購入金額を正しく入力してください。");
    } else {
      for (let i = 0; i < listLength; i++) {
        if (CheckList[i] === true) {
          let raceNum = targetData[i].raceNum;
          if (raceNum.length == 3) {
            raceNum = raceNum.slice(1, 3);
          }
          let amount = targetData[i].Amount; //ここに賭け金が入る
          let request = "";
          if (
            (getCourse() == PAYINGSUGOTOKU ||
            getCourse() == PAYINGSUGOTOKU_NONE) && !getUserAgent()
          ) {
            request =
              HOST +
              PATH +
              "Balance/setBalance4sg.js" +
              BACKWORDPATH +
              "?userId=" +
              encodeURIComponent(getUserId()) +
              "&dor=" +
              targetData[i].dor +
              "&racetrackCd=" +
              targetData[i].racetrackCd +
              "&raceNum=" +
              raceNum +
              "&tob=" +
              targetData[i].TOB +
              "&method=" +
              targetData[i].method +
              "&bet=" +
              targetData[i].bet +
              "&count=" +
              targetData[i].itemCount +
              "&spend=" +
              amount * 100 +
              "&narFlag=" +
              isNar();
          } else {
            request =
              HOST +
              PATH +
              "Balance/setBalance.js" +
              BACKWORDPATH +
              "?dor=" +
              targetData[i].dor +
              "&racetrackCd=" +
              targetData[i].racetrackCd +
              "&raceNum=" +
              raceNum +
              "&tob=" +
              targetData[i].TOB +
              "&method=" +
              targetData[i].method +
              "&bet=" +
              targetData[i].bet +
              "&count=" +
              targetData[i].itemCount +
              "&spend=" +
              amount * 100 +
              "&narFlag=" +
              isNar();
          }
          getJSON(request, true, function (status, json) {
            if (status == SUCCESS) {
            }
          });
        }
      }

      //モーダルウィンドウ表示
      modalBalanceOpen.value = true;
    }
  } else {
    alert("項目が選択されていません。");
  }
  document.body.className = "status-loaded";
};

//IPAT投票
const setIpat = async (targetData) => {
  document.body.className = "";

  const isCheckIpat = await checkIpatInfo()
  if (!isCheckIpat) return

  if (CheckList.some((check) => check === true)) {
    if (isNar() === 1) setDefaultPurchase('ipat');
    let listLength = CheckList.length;
    var ret = "";
    var addcount = 0;
    const userId = isAppNonmember() ? getNonMemberId() : getUserId();
    for (let i = 0; i < listLength; i++) {
      var link = "";
      if (CheckList[i] === true) {
        let raceNum = targetData[i].raceNum;
        if (raceNum.length == 3) {
          raceNum = raceNum.slice(1, 3);
        }
        const request =
          HOST +
          PATH +
          "Odds/entryIPAT.js" +
          BACKWORDPATH +
          "?dor=" +
          targetData[i].dor +
          "&racetrackCd=" +
          targetData[i].racetrackCd +
          "&raceNum=" +
          raceNum +
          "&TOB=" +
          targetData[i].TOB +
          "&method=" +
          targetData[i].method +
          "&userId=" +
          encodeURIComponent(userId) +
          "&narFlag=" +
          isNar() +
          "&isAppNonmember=" +
          (isAppNonmember() ? "1" : "0");
        await getJSON(request, true, function (status, json) {});

        //1byte目：1固定 (正常)
        link += "1";
        //2byte目：0固定？
        link += "0";
        //3-4:0オリジンで番号1-10,11,12-49
        var countNo = "0" + addcount;
        link += countNo.substr(0, 2);
        //5:開催場 1:札幌、2:函館、3:福島、4:新潟、5:東京、6:中山、7:中京、8:京都、9:阪神、A：小倉
        if (targetData[i].racetrackCd == "10") {
          link += "A"; //小倉はA
        } else {
          link += targetData[i].racetrackCd.substr(1, 1);
        }
        //6:レース番号
        if (targetData[i].raceNum == "10") {
          link += "A";
        } else if (targetData[i].raceNum == "11") {
          link += "B";
        } else if (targetData[i].raceNum == "12") {
          link += "C";
        } else {
          link += raceNum.substr(1, 1);
        }
        //7:曜日　1:日曜、2:月曜、・・・5:木曜、6:金曜、7:土曜
        var checkDate = new Date(
          targetData[i].dor.substr(0, 4),
          targetData[i].dor.substr(4, 2) - 1,
          targetData[i].dor.substr(6, 2)
        );
        var dateNo = checkDate.getDay() + 1;
        link += dateNo;
        //8:方式　0：通常、1:フォーメーション、2:ボックス、3:流し(１着軸)、4:２着軸流し
        if (
          targetData[i].TOB == TOB_TRIO &&
          targetData[i].method == METHOD_WHEEL_2
        ) {
          link += "6";
        } else if (
          targetData[i].TOB == TOB_EXACTA_MULTI &&
          targetData[i].method == METHOD_WHEEL_1
        ) {
          link += "3";
        } else if (
          (targetData[i].TOB == TOB_EXACTA ||
            targetData[i].TOB == TOB_EXACTA_MULTI) &&
          targetData[i].method == METHOD_WHEEL_2
        ) {
          link += "4";
        } else if (
          (targetData[i].TOB == TOB_TRIFECTA ||
            targetData[i].TOB == TOB_TRIFECTA_MULTI) &&
          targetData[i].method == METHOD_WHEEL_1
        ) {
          link += "3";
        } else if (
          (targetData[i].TOB == TOB_TRIFECTA ||
            targetData[i].TOB == TOB_TRIFECTA_MULTI) &&
          targetData[i].method == METHOD_WHEEL_2
        ) {
          link += "4";
        } else if (
          (targetData[i].TOB == TOB_TRIFECTA ||
            targetData[i].TOB == TOB_TRIFECTA_MULTI) &&
          targetData[i].method == METHOD_WHEEL_3
        ) {
          link += "5";
        } else if (
          (targetData[i].TOB == TOB_TRIFECTA ||
            targetData[i].TOB == TOB_TRIFECTA_MULTI) &&
          targetData[i].method == METHOD_WHEEL_1_2
        ) {
          link += "6";
        } else if (
          (targetData[i].TOB == TOB_TRIFECTA ||
            targetData[i].TOB == TOB_TRIFECTA_MULTI) &&
          targetData[i].method == METHOD_WHEEL_1_3
        ) {
          link += "7";
        } else if (
          (targetData[i].TOB == TOB_TRIFECTA ||
            targetData[i].TOB == TOB_TRIFECTA_MULTI) &&
          targetData[i].method == METHOD_WHEEL_2_3
        ) {
          link += "8";
        } else if (
          targetData[i].method == METHOD_WHEEL_1 ||
          targetData[i].method == METHOD_WHEEL_2 ||
          targetData[i].method == METHOD_WHEEL_3 ||
          targetData[i].method == METHOD_WHEEL_1_2 ||
          targetData[i].method == METHOD_WHEEL_1_3 ||
          targetData[i].method == METHOD_WHEEL_2_3
        ) {
          link += METHOD_WHEEL;
        } else {
          link += targetData[i].method;
        }

        //9:識別　1:単勝、2:複勝、3:枠連、4:馬連、5:ワイド、6:馬単、7:３連複、8:３連単
        if (targetData[i].TOB == TOB_EXACTA_MULTI) {
          link += TOB_EXACTA;
        } else if (targetData[i].TOB == TOB_TRIFECTA_MULTI) {
          link += TOB_TRIFECTA;
        } else {
          link += targetData[i].TOB;
        }

        //10-14:馬番１頭目、14-18、19-23 ３連単、３連複の３頭目　馬連などの２頭目、 ※8進
        //２進数文字列から10進数値に変換
        var con2 = "";
        if (
          targetData[i].TOB == TOB_WIN ||
          targetData[i].TOB == TOB_PLACE ||
          targetData[i].method == METHOD_BOX
        ) {
          //一枠だけの場合（単勝、複勝、各種ボックス）
          con2 =
            targetData[i].bet + "000000000000000000" + "000000000000000000";
        } else if (
          (targetData[i].method == METHOD_NORMAL ||
            targetData[i].method == METHOD_FORMATION) &&
          (targetData[i].TOB == TOB_BRACKET ||
            targetData[i].TOB == TOB_QUINELLA ||
            targetData[i].TOB == TOB_WIDE ||
            targetData[i].TOB == TOB_EXACTA)
        ) {
          //二枠で左、中を使う場合（枠連、馬単、馬連、ワイドの通常、フォーメーション）
          con2 = targetData[i].bet + "000000000000000000";
        } else if (
          ((targetData[i].TOB == TOB_QUINELLA ||
            targetData[i].TOB == TOB_WIDE) &&
            targetData[i].method == METHOD_WHEEL) ||
          ((targetData[i].TOB == TOB_EXACTA ||
            targetData[i].TOB == TOB_EXACTA_MULTI) &&
            (targetData[i].method == METHOD_WHEEL_1 ||
              targetData[i].method == METHOD_WHEEL_2)) ||
          (targetData[i].TOB == TOB_TRIO &&
            targetData[i].method == METHOD_WHEEL_1) ||
          ((targetData[i].TOB == TOB_TRIFECTA ||
            targetData[i].TOB == TOB_TRIFECTA_MULTI) &&
            (targetData[i].method == METHOD_WHEEL_1 ||
              targetData[i].method == METHOD_WHEEL_2 ||
              targetData[i].method == METHOD_WHEEL_3))
        ) {
          //二枠で左、右を使う場合（馬連、馬単、ワイドの流し、3連複 1頭軸流し・2頭軸流し、3連単 1着軸流し・2着軸流し・3着軸流し）
          con2 =
            targetData[i].bet.substr(0, 18) +
            "000000000000000000" +
            targetData[i].bet.substr(18, 18);
        } else if (
          targetData[i].TOB == TOB_TRIO &&
          targetData[i].method == METHOD_WHEEL_2
        ) {
          //三連複二着軸の場合、インプット値が二枠だが要求が三枠のため一枠分を二枠に整形
          var leftValue = targetData[i].bet.substr(0, 18);
          var targetidx = leftValue.indexOf("1");
          var zeroValue = "000000000000000000";
          var firstSet = leftValue.substr(0, targetidx + 1) + zeroValue; //先頭の1の位置より前の値＋後ろに0詰
          firstSet = firstSet.substr(0, 18); //18byteでカット
          var secondtSet =
            zeroValue.substr(0, targetidx + 1) +
            leftValue.substr(targetidx + 1, 18 - targetidx);
          con2 = firstSet + secondtSet + targetData[i].bet.substr(18, 18);
        } else if (
          (targetData[i].TOB == TOB_TRIFECTA ||
            targetData[i].TOB == TOB_TRIFECTA_MULTI) &&
          targetData[i].method == METHOD_WHEEL_1_2
        ) {
          //三連単二頭軸の場合 １，２着軸
          con2 = targetData[i].bet;
        } else if (
          (targetData[i].TOB == TOB_TRIFECTA ||
            targetData[i].TOB == TOB_TRIFECTA_MULTI) &&
          targetData[i].method == METHOD_WHEEL_1_3
        ) {
          //三連単二頭軸の場合 １，３着軸
          con2 =
            targetData[i].bet.substr(0, 18) +
            targetData[i].bet.substr(36, 18) +
            targetData[i].bet.substr(18, 18);
        } else if (
          (targetData[i].TOB == TOB_TRIFECTA ||
            targetData[i].TOB == TOB_TRIFECTA_MULTI) &&
          targetData[i].method == METHOD_WHEEL_2_3
        ) {
          //三連単二頭軸の場合 ２，３着軸
          con2 =
            targetData[i].bet.substr(18, 18) +
            targetData[i].bet.substr(36, 18) +
            targetData[i].bet.substr(0, 18);
        } else {
          con2 = targetData[i].bet;
        }

        // !NARの3連単, 3連複が1桁多い
        if ((targetData[i].TOB == TOB_TRIFECTA || targetData[i].TOB == TOB_TRIO) && isNar() === 1) {
          con2 = con2.padEnd(56, '0')
        } else {
          con2 += '00'
        }

        //con2の後ろから4つを切る
        var intbet1 = parseInt(con2.slice(0, con2.length - 4), 2);
        var intbet2 = parseInt(con2.slice(con2.length - 4, con2.length), 2);

        //16進数文字列に変換
        var bet16 =
          "00000000000000" + intbet1.toString(16) + intbet2.toString(16);

        //マルチフラグ
        if (
          targetData[i].TOB == TOB_EXACTA_MULTI ||
          targetData[i].TOB == TOB_TRIFECTA_MULTI
        ) {
          if (bet16.substr(bet16.length - 1, 1) == "8") {
            link += bet16.substr(bet16.length - 14, 13) + "9";
          } else if (bet16.substr(bet16.length - 1, 1) == "4") {
            link += bet16.substr(bet16.length - 14, 13) + "5";
          } else if (bet16.substr(bet16.length - 1, 1) == "2") {
            link += bet16.substr(bet16.length - 14, 13) + "3";
          } else if (bet16.substr(bet16.length - 1, 1) == "0") {
            link += bet16.substr(bet16.length - 14, 13) + "1";
          } else if (bet16.substr(bet16.length - 1, 1) == "c") {
            link += bet16.substr(bet16.length - 14, 13) + "d";
          }
        } else {
          link += bet16.substr(bet16.length - 14, 14);
        }

        //24-27：金額　16進表現　1->"0001"、9999->"270F"
        var amount = parseInt(targetData[i].Amount);
        var amount16 = "000" + amount.toString(16);
        link += amount16.substr(amount16.length - 4, 4);
        // 地方競馬即PAT競馬場コードマップ
        const NarIpatMap = {
          "30":"38",		// 門別
          "35":"49",		// 盛岡
          "36":"50",		// 水沢
          "42":"56",		// 浦和
          "43":"57",		// 船橋
          "44":"61",		// 大井
          "45":"62",		// 川崎
          "46":"67",		// 金沢
          "47":"66",		// 笠松
          "48":"26",		// 名古屋
          "50":"13",		// 園田
          "51":"14",		// 姫路
          "54":"78",		// 高知
          "55":"80",		// 佐賀
        }

        if (isNar()) {
          link += NarIpatMap[targetData[i].racetrackCd]
        }

        var setName = (addcount + 1) + "";
        if (addcount < 9) setName = "0" + setName;
        //$('input[name="' + setName + '"]').val(link);
        ret += link + "_";
        addcount++;
      }
    }
    //50項目まで不足分は0クリア（JRAの場合255項目）
    if (isNar() === 1) {
      for (var i = addcount; i < 50; i++) {
        ret += "00000000000000000000000000000" + "_"
      }
    } else {
      for (var i = addcount; i < 255; i++) {
        ret += "000000000000000000000000000" + "_";
      }
    }

    ipatBetString.value = ret;
    if (isWebview.value) {
      callDartForIpatSignUp(2, ipatBetString.value)
    } else {
      voteIPAT(
        loginInfo.inetid,
        loginInfo.userid,
        loginInfo.pass,
        loginInfo.pars,
        ipatBetString.value
      );
    }
  } else {
    alert("項目が選択されていません。");
  }
  document.body.className = "status-loaded";
};

const checkIpatInfo = async () => {
  const ipatLoginInfo = loadIPATLoginInfo()
  let isIpatFlag = true
  // IPAT情報があるか確認する(cookie,LocalStorageどちらかに4つとも値があればOK)
  Object.values(ipatLoginInfo).forEach(value => {
    if (value === '' || value === null || value === 'null') isIpatFlag = false
  });

  const goSugotokuConfirm = isSugotoku.value && !isSUGOTOKU_IPAT_CONFIRM()

  if (isWebview.value && !isIpatFlag) {
    const url = goSugotokuConfirm ? "/purchase/sugotokuConfirm" : "/purchase/staticIpat"
    await router.push(url)
    return false
  }

  //クッキーを確認して規約画面
  if (goSugotokuConfirm) {
    await router.push("/purchase/sugotokuConfirm")
    return false
  } else if (!isIPAT_CONFIRM()) {
    await router.push("/purchase/confirm")
    return false
  } else if (!isSaveINETID()) {
    //INET ID未入力の場合
    await router.push("/purchase/ipat/setting")
    return false
  }
  return true
}

const goIpat = () => {
  if (Object.keys(loginInfo).length) {
    voteIPAT(
        loginInfo.inetid,
        loginInfo.userid,
        loginInfo.pass,
        loginInfo.pars,
        null
    );
  } else {
    router.push('/purchase/ipat/setting')
  }
}

// IPAT連動
const voteIPAT = (inetId, userId, pass, pars, ipatBet) => {
  document.body.className = "";
  const request =
    HOST +
    PATH +
    "Odds/purchase.js" +
    BACKWORDPATH +
    "?inetId=" +
    inetId +
    "&uid=" +
    userId +
    "&pass=" +
    pass +
    "&pars=" +
    pars +
    "&narFlag=" +
    isNar() +
    '&userId=' +
    encodeURIComponent(getUserId());
  getJSON(request, true, function (status, json) {
    if (status == SUCCESS) {
      try {
        if (json.status == "NG") {
          alert(json.message);
          return false;
        }

        const voteUrl = isNar() === 1 ?
            'https://n.ipat.jra.go.jp/sp/pw_741_i.cgi' : 'https://www.ipat.jra.go.jp/sp/pw_741_i.cgi'
        const loginUrl = isNar() === 1 ?
            'https://n.ipat.jra.go.jp/sp/pw_732_i.cgi' : 'https://www.ipat.jra.go.jp/sp/pw_732_i.cgi'

        let form = document.createElement('form');
        form.action = ipatBet ? voteUrl : loginUrl;
        form.method = "POST";
        document.body.append(form);

        form.addEventListener("formdata", (e) => {
          var fd = e.formData;
          fd.set("g", "740");
          fd.set("mli", json.jra_mli);
          fd.set("uh", json.jra_uh);
          fd.set("inetid", json.jra_inetid);
          fd.set("u", json.jra_u);
          fd.set("nm", json.jra_nm);
          fd.set("zj", json.jra_zj);
          fd.set("uk", "0");

          //50項目まで不足分は0クリア（JRAの場合255項目）
          if (isNar() === 1) {
            for (let i = 0; i < 50; i++) {
              let setName = i + 1 + "";
              if (i < 9) setName = "0" + setName;
              let setValue = ipatBet.substr(i * 30, 29);
              if (setValue == "00000000000000000000000000000") setValue = "0";
              fd.set(setName, setValue);
            }
          } else {
            for (let i = 0; i < 255; i++) {
              let setName = i + 1 + "";
              setName = "000" + setName;
              setName = setName.slice(-3);

              if (ipatBet == undefined){
                setValue = "0";
              } else {
                var setValue = ipatBet.substr(i * 28, 27);
                if (setValue == "000000000000000000000000000") setValue = "0";
              }
              fd.set(setName, setValue);
            }
          }
        });

        form.submit();

      } catch (e) {
        console.log(e);
      }
    }
  });
  document.body.className = "status-loaded";
};

//配当均等用モーダル
const amountAvgModal = (detail) => {
  modalAmountAvgOpen.value = true;
  avgAmountIndex.value = detail[0].uniqueNum;
};

const setAmountAvgModal = ref();

// 配当均等用モーダルから入力内容の受け取り
const receiveAmountAvgItem = (input) => {
  let amount = input ?? 1;
  if (amount >= allList[avgAmountIndex.value].itemCount) {
    //購入点数より大きい場合は購入点数にする
    amountList[avgAmountIndex.value] = amount;
  }

  divideBet(avgAmountIndex.value);
};

//配当均等
const divideBet = (lastClickIndex) => {
  try {
    let targetData = allList[lastClickIndex];

    let addNoList = [];

    let ten = targetData.itemCount;
    let amount = amountList[lastClickIndex];
    ten = parseInt(targetData.itemCount, 10);
    amount = parseInt(amount, 10);

    if (isNaN(amount) || isNaN(ten)) {
      alert("購入金額に不正な値が入力されています。");
      document.body.className = "status-loaded";
      return;
    }
    if (amount < ten) {
      alert("購入金額が不足しています。");
      document.body.className = "status-loaded";
      return;
    }
    // 枠連
    if (targetData.TOB == TOB_BRACKET) {
      //枠連の場合、２層階層、順序無し、重複有り（※要出頭数チェック）
      for (var firstBet = 1; firstBet <= 7; firstBet++) {
        if (targetData.bet.substr(firstBet - 1, 1) == "1") {
          for (var secondBet = firstBet + 1; secondBet <= 8; secondBet++) {
            if (targetData.bet.substr(secondBet - 1, 1) == "1") {
              addNoList.push({ l: firstBet, r: secondBet });
            }
          }
        }
      }

      // 馬連、ワイド
    } else if (
      targetData.TOB == TOB_QUINELLA ||
      targetData.TOB == TOB_WIDE
    ) {
      if (targetData.method == METHOD_BOX) {
        //馬連、ワイドの場合２層階層、順序無し
        for (var firstBet = 1; firstBet <= 17; firstBet++) {
          if (targetData.bet.substr(firstBet - 1, 1) == "1") {
            for (
              var secondBet = firstBet + 1;
              secondBet <= 18;
              secondBet++
            ) {
              if (targetData.bet.substr(secondBet - 1, 1) == "1") {
                addNoList.push({ l: firstBet, r: secondBet });
              }
            }
          }
        }
      } else if (
        targetData.method == METHOD_WHEEL ||
        targetData.method == METHOD_FORMATION
      ) {
        var lchecks = new Array();
        var rchecks = new Array();

        //１列目値の特定
        for (var betCheck = 1; betCheck <= 18; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            lchecks.push(betCheck);
          }
        }
        //２列目値の特定
        for (var betCheck = 19; betCheck <= 36; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            rchecks.push(betCheck - 18);
          }
        }

        var betsList = new Array();

        for (var i = 0; i < lchecks.length; i++) {
          for (var j = 0; j < rchecks.length; j++) {
            if (lchecks[i] == rchecks[j]) {
              continue;
            }
            var targetBet = "";
            if (lchecks[i] < rchecks[j]) {
              targetBet = lchecks[i] + "_" + rchecks[j];
            } else if (lchecks[i] > rchecks[j]) {
              targetBet = rchecks[j] + "_" + lchecks[i];
            }
            //既存かチェック
            if (
              !("," + betsList.join() + ",").match("," + targetBet + ",")
            ) {
              betsList.push(targetBet);
              var num = targetBet.split("_");
              addNoList.push({ l: num[0], r: num[1] });
            }
          }
        }
      }

      //馬単
    } else if (targetData.TOB == TOB_EXACTA) {
      if (targetData.method == METHOD_BOX) {
        // ボックス
        //馬単の場合、２層階層順序有り
        for (var firstBet = 1; firstBet <= 18; firstBet++) {
          if (targetData.bet.substr(firstBet - 1, 1) == "1") {
            for (var secondBet = 1; secondBet <= 18; secondBet++) {
              //同一番号は除外
              if (firstBet != secondBet) {
                if (targetData.bet.substr(secondBet - 1, 1) == "1") {
                  addNoList.push({ l: firstBet, r: secondBet });
                }
              }
            }
          }
        }
      } else if (
        targetData.method == METHOD_FORMATION ||
        targetData.method == METHOD_WHEEL_1
      ) {
        // フォーメーション、1着軸流し
        var lchecks = new Array();
        var rchecks = new Array();

        //１列目値の特定
        for (var betCheck = 1; betCheck <= 18; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            lchecks.push(betCheck);
          }
        }
        //２列目値の特定
        for (var betCheck = 19; betCheck <= 36; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            rchecks.push(betCheck - 18);
          }
        }

        for (var i = 0; i < lchecks.length; i++) {
          for (var j = 0; j < rchecks.length; j++) {
            if (lchecks[i] == rchecks[j]) {
              continue;
            }
            addNoList.push({ l: lchecks[i], r: rchecks[j] });
          }
        }
      } else if (targetData.method == METHOD_WHEEL_2) {
        // 2着軸流し
        var lchecks = new Array();
        var rchecks = new Array();

        //2列目値の特定
        for (var betCheck = 1; betCheck <= 18; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            rchecks.push(betCheck);
          }
        }
        //1列目値の特定
        for (var betCheck = 19; betCheck <= 36; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            lchecks.push(betCheck - 18);
          }
        }

        for (var i = 0; i < lchecks.length; i++) {
          for (var j = 0; j < rchecks.length; j++) {
            if (lchecks[i] == rchecks[j]) {
              continue;
            }
            addNoList.push({ l: lchecks[i], r: rchecks[j] });
          }
        }
      }

      //馬単マルチ
    } else if (targetData.TOB == TOB_EXACTA_MULTI) {
      if (targetData.method == METHOD_WHEEL_1) {
        // 1着軸流しマルチ
        var lchecks = new Array();
        var rchecks = new Array();

        //１列目値の特定
        for (var betCheck = 1; betCheck <= 18; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            lchecks.push(betCheck);
          }
        }
        //２列目値の特定
        for (var betCheck = 19; betCheck <= 36; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            rchecks.push(betCheck - 18);
          }
        }

        var betsList = new Array();
        for (var i = 0; i < lchecks.length; i++) {
          for (var j = 0; j < rchecks.length; j++) {
            var bet = new Object();
            addNoList.push({ l: lchecks[i], r: rchecks[j] });
            addNoList.push({ l: rchecks[j], r: lchecks[i] });
          }
        }
      } else if (targetData.method == METHOD_WHEEL_2) {
        // 2着軸流しマルチ
        var lchecks = new Array();
        var rchecks = new Array();

        //2列目値の特定
        for (var betCheck = 1; betCheck <= 18; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            rchecks.push(betCheck);
          }
        }
        //1列目値の特定
        for (var betCheck = 19; betCheck <= 36; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            lchecks.push(betCheck - 18);
          }
        }

        for (var i = 0; i < lchecks.length; i++) {
          for (var j = 0; j < rchecks.length; j++) {
            var bet = new Object();
            addNoList.push({ l: lchecks[i], r: rchecks[j] });
            addNoList.push({ l: rchecks[j], r: lchecks[i] });
          }
        }
      }

      //３連複
    } else if (targetData.TOB == TOB_TRIO) {
      if (targetData.method == METHOD_BOX) {
        // ボックス
        //３連複の場合、３層階層順序無し
        for (var firstBet = 1; firstBet <= 16; firstBet++) {
          if (targetData.bet.substr(firstBet - 1, 1) == "1") {
            for (
              var secondBet = firstBet + 1;
              secondBet <= 17;
              secondBet++
            ) {
              if (targetData.bet.substr(secondBet - 1, 1) == "1") {
                for (
                  var thirdBet = secondBet + 1;
                  thirdBet <= 18;
                  thirdBet++
                ) {
                  if (targetData.bet.substr(thirdBet - 1, 1) == "1") {
                    addNoList.push({
                      l: firstBet,
                      c: secondBet,
                      r: thirdBet,
                    });
                  }
                }
              }
            }
          }
        }
      } else if (targetData.method == METHOD_FORMATION) {
        // フォーメーション
        var lchecks = new Array();
        var cchecks = new Array();
        var rchecks = new Array();

        //１列目値の特定
        for (var betCheck = 1; betCheck <= 18; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            lchecks.push(betCheck);
          }
        }
        //２列目値の特定
        for (var betCheck = 19; betCheck <= 36; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            cchecks.push(betCheck - 18);
          }
        }
        //３列目値の特定
        for (var betCheck = 37; betCheck <= 54; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            rchecks.push(betCheck - 36);
          }
        }

        var betsList = new Array();

        for (var i = 0; i < lchecks.length; i++) {
          for (var j = 0; j < cchecks.length; j++) {
            if (lchecks[i] == cchecks[j]) {
              continue;
            }

            for (var k = 0; k < rchecks.length; k++) {
              if (lchecks[i] == rchecks[k] || cchecks[j] == rchecks[k]) {
                continue;
              }
              var targetBet = "";
              if (lchecks[i] > cchecks[j] && lchecks[i] > rchecks[k]) {
                if (cchecks[j] > rchecks[k]) {
                  targetBet =
                    rchecks[k] + "_" + cchecks[j] + "_" + lchecks[i];
                } else if (cchecks[j] < rchecks[k]) {
                  targetBet =
                    cchecks[j] + "_" + rchecks[k] + "_" + lchecks[i];
                }
              } else if (
                cchecks[j] > lchecks[i] &&
                cchecks[j] > rchecks[k]
              ) {
                if (lchecks[i] > rchecks[k]) {
                  targetBet =
                    rchecks[k] + "_" + lchecks[i] + "_" + cchecks[j];
                } else if (lchecks[i] < rchecks[k]) {
                  targetBet =
                    lchecks[i] + "_" + rchecks[k] + "_" + cchecks[j];
                }
              } else if (
                rchecks[k] > lchecks[i] &&
                rchecks[k] > cchecks[j]
              ) {
                if (lchecks[i] > cchecks[j]) {
                  targetBet =
                    cchecks[j] + "_" + lchecks[i] + "_" + rchecks[k];
                } else if (lchecks[i] < cchecks[j]) {
                  targetBet =
                    lchecks[i] + "_" + cchecks[j] + "_" + rchecks[k];
                }
              }
              //既存かチェック
              if (
                !("," + betsList.join() + ",").match("," + targetBet + ",")
              ) {
                betsList.push(targetBet);
                var num = targetBet.split("_");
                addNoList.push({ l: num[0], c: num[1], r: num[2] });
              }
            }
          }
        }
      } else if (targetData.method == METHOD_WHEEL_1) {
        // 1頭軸流し
        var lchecks = new Array();
        var cchecks = new Array();

        //１列目値の特定
        for (var betCheck = 1; betCheck <= 18; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            lchecks.push(betCheck);
          }
        }
        //２・３列目値の特定
        for (var betCheck = 19; betCheck <= 36; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            cchecks.push(betCheck - 18);
          }
        }

        var betsList = new Array();

        for (var i = 0; i < lchecks.length; i++) {
          for (var j = 0; j < cchecks.length; j++) {
            if (lchecks[i] == cchecks[j]) {
              continue;
            }

            for (var k = 0; k < cchecks.length; k++) {
              if (lchecks[i] == cchecks[k] || cchecks[j] == cchecks[k]) {
                continue;
              }
              var targetBet = "";
              if (lchecks[i] > cchecks[j] && lchecks[i] > cchecks[k]) {
                if (cchecks[j] > cchecks[k]) {
                  targetBet =
                    cchecks[k] + "_" + cchecks[j] + "_" + lchecks[i];
                } else if (cchecks[j] < cchecks[k]) {
                  targetBet =
                    cchecks[j] + "_" + cchecks[k] + "_" + lchecks[i];
                }
              } else if (
                cchecks[j] > lchecks[i] &&
                cchecks[j] > cchecks[k]
              ) {
                if (lchecks[i] > cchecks[k]) {
                  targetBet =
                    cchecks[k] + "_" + lchecks[i] + "_" + cchecks[j];
                } else if (lchecks[i] < cchecks[k]) {
                  targetBet =
                    lchecks[i] + "_" + cchecks[k] + "_" + cchecks[j];
                }
              } else if (
                cchecks[k] > lchecks[i] &&
                cchecks[k] > cchecks[j]
              ) {
                if (lchecks[i] > cchecks[j]) {
                  targetBet =
                    cchecks[j] + "_" + lchecks[i] + "_" + cchecks[k];
                } else if (lchecks[i] < cchecks[j]) {
                  targetBet =
                    lchecks[i] + "_" + cchecks[j] + "_" + cchecks[k];
                }
              }
              //既存かチェック
              if (
                !("," + betsList.join() + ",").match("," + targetBet + ",")
              ) {
                betsList.push(targetBet);
                var num = targetBet.split("_");
                addNoList.push({ l: num[0], c: num[1], r: num[2] });
              }
            }
          }
        }
      } else if (targetData.method == METHOD_WHEEL_2) {
        var lchecks = new Array();
        var cchecks = new Array();

        //１列目値の特定
        for (var betCheck = 1; betCheck <= 18; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            lchecks.push(betCheck);
          }
        }
        //２列目値の特定
        for (var betCheck = 19; betCheck <= 36; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            cchecks.push(betCheck - 18);
          }
        }

        var betsList = new Array();

        for (var i = 0; i < lchecks.length; i++) {
          for (var j = i + 1; j < lchecks.length; j++) {
            if (lchecks[i] == lchecks[j]) {
              continue;
            }
            for (var k = 0; k < cchecks.length; k++) {
              if (lchecks[i] == cchecks[k] || lchecks[j] == cchecks[k]) {
                continue;
              }
              var targetBet = "";
              if (lchecks[i] > lchecks[j] && lchecks[i] > cchecks[k]) {
                if (lchecks[j] > cchecks[k]) {
                  targetBet =
                    cchecks[k] + "_" + lchecks[j] + "_" + lchecks[i];
                } else if (lchecks[j] < cchecks[k]) {
                  targetBet =
                    lchecks[j] + "_" + cchecks[k] + "_" + lchecks[i];
                }
              } else if (
                lchecks[j] > lchecks[i] &&
                lchecks[j] > cchecks[k]
              ) {
                if (lchecks[i] > cchecks[k]) {
                  targetBet =
                    cchecks[k] + "_" + lchecks[i] + "_" + lchecks[j];
                } else if (lchecks[i] < cchecks[k]) {
                  targetBet =
                    lchecks[i] + "_" + cchecks[k] + "_" + lchecks[j];
                }
              } else if (
                cchecks[k] > lchecks[i] &&
                cchecks[k] > lchecks[j]
              ) {
                if (lchecks[i] > lchecks[j]) {
                  targetBet =
                    lchecks[j] + "_" + lchecks[i] + "_" + cchecks[k];
                } else if (lchecks[i] < cchecks[j]) {
                  targetBet =
                    lchecks[i] + "_" + lchecks[j] + "_" + cchecks[k];
                }
              }

              //既存かチェック
              if (
                !("," + betsList.join() + ",").match("," + targetBet + ",")
              ) {
                betsList.push(targetBet);
                var num = targetBet.split("_");
                addNoList.push({ l: num[0], c: num[1], r: num[2] });
              }
            }
          }
        }
      }

      //三連単
    } else if (targetData.TOB == TOB_TRIFECTA) {
      if (targetData.method == METHOD_BOX) {
        // ボックス
        var checks = new Array();

        for (var betCheck = 1; betCheck <= 18; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            checks.push(betCheck);
          }
        }

        for (var i = 0; i < checks.length; i++) {
          for (var j = 0; j < checks.length; j++) {
            if (checks[i] == checks[j]) {
              continue;
            }
            for (var k = 0; k < checks.length; k++) {
              if (checks[i] == checks[k] || checks[j] == checks[k]) {
                continue;
              }
              addNoList.push({ l: checks[i], c: checks[j], r: checks[k] });
            }
          }
        }
      } else if (targetData.method == METHOD_WHEEL_1) {
        // 1着軸流し
        var lchecks = new Array();
        var crchecks = new Array();

        //１列目値の特定
        for (var betCheck = 1; betCheck <= 18; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            lchecks.push(betCheck);
          }
        }
        //２・３列目値の特定
        for (var betCheck = 19; betCheck <= 36; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            crchecks.push(betCheck - 18);
          }
        }

        for (var i = 0; i < lchecks.length; i++) {
          for (var j = 0; j < crchecks.length; j++) {
            if (lchecks[i] == crchecks[j]) {
              continue;
            }
            for (var k = 0; k < crchecks.length; k++) {
              if (lchecks[i] == crchecks[k] || crchecks[j] == crchecks[k]) {
                continue;
              }
              addNoList.push({
                l: lchecks[i],
                c: crchecks[j],
                r: crchecks[k],
              });
            }
          }
        }
      } else if (targetData.method == METHOD_WHEEL_2) {
        // 2着軸流し
        var cchecks = new Array();
        var lrchecks = new Array();

        //２列目値の特定
        for (var betCheck = 1; betCheck <= 18; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            cchecks.push(betCheck);
          }
        }
        //１・３列目値の特定
        for (var betCheck = 19; betCheck <= 36; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            lrchecks.push(betCheck - 18);
          }
        }

        for (var i = 0; i < lrchecks.length; i++) {
          for (var j = 0; j < cchecks.length; j++) {
            if (lrchecks[i] == cchecks[j]) {
              continue;
            }
            for (var k = 0; k < lrchecks.length; k++) {
              if (lrchecks[i] == lrchecks[k] || cchecks[j] == lrchecks[k]) {
                continue;
              }
              addNoList.push({
                l: lrchecks[i],
                c: cchecks[j],
                r: lrchecks[k],
              });
            }
          }
        }
      } else if (targetData.method == METHOD_WHEEL_3) {
        // 3着軸流し
        var rchecks = new Array();
        var lcchecks = new Array();

        //３列目値の特定
        for (var betCheck = 1; betCheck <= 18; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            rchecks.push(betCheck);
          }
        }
        //１・２列目値の特定
        for (var betCheck = 19; betCheck <= 36; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            lcchecks.push(betCheck - 18);
          }
        }

        for (var i = 0; i < lcchecks.length; i++) {
          for (var j = 0; j < lcchecks.length; j++) {
            if (lcchecks[i] == lcchecks[j]) {
              continue;
            }
            for (var k = 0; k < rchecks.length; k++) {
              if (lcchecks[i] == rchecks[k] || lcchecks[j] == rchecks[k]) {
                continue;
              }
              addNoList.push({
                l: lcchecks[i],
                c: lcchecks[j],
                r: rchecks[k],
              });
            }
          }
        }
      } else if (
        targetData.method == METHOD_FORMATION || // フォーメーション
        targetData.method == METHOD_WHEEL_1_2 || // 1着・2着軸流し
        targetData.method == METHOD_WHEEL_1_3 || // 1着・3着軸流し
        targetData.method == METHOD_WHEEL_2_3
      ) {
        // 2着・3着軸流し

        var lchecks = new Array();
        var cchecks = new Array();
        var rchecks = new Array();

        //１列目値の特定
        for (var betCheck = 1; betCheck <= 18; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            lchecks.push(betCheck);
          }
        }
        //２列目値の特定
        for (var betCheck = 19; betCheck <= 36; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            cchecks.push(betCheck - 18);
          }
        }
        //３列目値の特定
        for (var betCheck = 37; betCheck <= 54; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            rchecks.push(betCheck - 36);
          }
        }

        for (var i = 0; i < lchecks.length; i++) {
          for (var j = 0; j < cchecks.length; j++) {
            if (lchecks[i] == cchecks[j]) {
              continue;
            }
            for (var k = 0; k < rchecks.length; k++) {
              if (lchecks[i] == rchecks[k] || cchecks[j] == rchecks[k]) {
                continue;
              }
              addNoList.push({
                l: lchecks[i],
                c: cchecks[j],
                r: rchecks[k],
              });
            }
          }
        }
      }

      //三連単マルチ
    } else if (targetData.TOB == TOB_TRIFECTA_MULTI) {
      if (targetData.method == METHOD_WHEEL_1) {
        // 1着軸流し
        var lchecks = new Array();
        var crchecks = new Array();

        //１列目値の特定
        for (var betCheck = 1; betCheck <= 18; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            lchecks.push(betCheck);
          }
        }
        //２・３列目値の特定
        for (var betCheck = 19; betCheck <= 36; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            crchecks.push(betCheck - 18);
          }
        }

        for (var i = 0; i < lchecks.length; i++) {
          for (var j = 0; j < crchecks.length; j++) {
            if (lchecks[i] == crchecks[j]) {
              continue;
            }
            for (var k = 0; k < crchecks.length; k++) {
              if (lchecks[i] == crchecks[k] || crchecks[j] == crchecks[k]) {
                continue;
              }
              addNoList.push({
                l: lchecks[i],
                c: crchecks[j],
                r: crchecks[k],
              });
              addNoList.push({
                l: crchecks[j],
                c: lchecks[i],
                r: crchecks[k],
              });
              addNoList.push({
                l: crchecks[k],
                c: crchecks[j],
                r: lchecks[i],
              });
            }
          }
        }
      } else if (targetData.method == METHOD_WHEEL_2) {
        // 2着軸流し
        var cchecks = new Array();
        var lrchecks = new Array();

        //２列目値の特定
        for (var betCheck = 1; betCheck <= 18; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            cchecks.push(betCheck);
          }
        }
        //１・３列目値の特定
        for (var betCheck = 19; betCheck <= 36; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            lrchecks.push(betCheck - 18);
          }
        }

        for (var i = 0; i < lrchecks.length; i++) {
          for (var j = 0; j < cchecks.length; j++) {
            if (lrchecks[i] == cchecks[j]) {
              continue;
            }
            for (var k = 0; k < lrchecks.length; k++) {
              if (lrchecks[i] == lrchecks[k] || cchecks[j] == lrchecks[k]) {
                continue;
              }
              addNoList.push({
                l: lrchecks[i],
                c: cchecks[j],
                r: lrchecks[k],
              });
              addNoList.push({
                l: lrchecks[i],
                c: lrchecks[k],
                r: cchecks[j],
              });
              addNoList.push({
                l: cchecks[j],
                c: lrchecks[i],
                r: lrchecks[k],
              });
            }
          }
        }
      } else if (targetData.method == METHOD_WHEEL_3) {
        // 3着軸流し
        var rchecks = new Array();
        var lcchecks = new Array();

        //３列目値の特定
        for (var betCheck = 1; betCheck <= 18; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            rchecks.push(betCheck);
          }
        }
        //１・２列目値の特定
        for (var betCheck = 19; betCheck <= 36; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            lcchecks.push(betCheck - 18);
          }
        }

        for (var i = 0; i < lcchecks.length; i++) {
          for (var j = 0; j < lcchecks.length; j++) {
            if (lcchecks[i] == lcchecks[j]) {
              continue;
            }
            for (var k = 0; k < rchecks.length; k++) {
              if (lcchecks[i] == rchecks[k] || lcchecks[j] == rchecks[k]) {
                continue;
              }
              addNoList.push({
                l: lcchecks[i],
                c: lcchecks[j],
                r: rchecks[k],
              });
              addNoList.push({
                l: lcchecks[i],
                c: rchecks[k],
                r: lcchecks[j],
              });
              addNoList.push({
                l: rchecks[k],
                c: lcchecks[i],
                r: lcchecks[j],
              });
            }
          }
        }
      } else if (
        targetData.method == METHOD_WHEEL_1_2 || // 1着・2着軸流し
        targetData.method == METHOD_WHEEL_1_3 || // 1着・3着軸流し
        targetData.method == METHOD_WHEEL_2_3
      ) {
        // 2着・3着軸流し

        var lchecks = new Array();
        var cchecks = new Array();
        var rchecks = new Array();

        //１列目値の特定
        for (var betCheck = 1; betCheck <= 18; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            lchecks.push(betCheck);
          }
        }
        //２列目値の特定
        for (var betCheck = 19; betCheck <= 36; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            cchecks.push(betCheck - 18);
          }
        }
        //３列目値の特定
        for (var betCheck = 37; betCheck <= 54; betCheck++) {
          if (targetData.bet.substr(betCheck - 1, 1) == "1") {
            rchecks.push(betCheck - 36);
          }
        }

        for (var i = 0; i < lchecks.length; i++) {
          for (var j = 0; j < cchecks.length; j++) {
            if (lchecks[i] == cchecks[j]) {
              continue;
            }
            for (var k = 0; k < rchecks.length; k++) {
              if (lchecks[i] == rchecks[k] || cchecks[j] == rchecks[k]) {
                continue;
              }
              addNoList.push({
                l: lchecks[i],
                c: cchecks[j],
                r: rchecks[k],
              });
              addNoList.push({
                l: lchecks[i],
                c: rchecks[k],
                r: cchecks[j],
              });
              addNoList.push({
                l: cchecks[j],
                c: lchecks[i],
                r: rchecks[k],
              });
              addNoList.push({
                l: cchecks[j],
                c: rchecks[k],
                r: lchecks[i],
              });
              addNoList.push({
                l: rchecks[k],
                c: lchecks[i],
                r: cchecks[j],
              });
              addNoList.push({
                l: rchecks[k],
                c: cchecks[j],
                r: lchecks[i],
              });
            }
          }
        }
      }
    }

    getOddsForDivide(lastClickIndex, addNoList, amount);
  } catch (e) {
    console.log(e);
    document.body.className = "status-loaded";
  }
};

const getOddsForDivide = (index, addNoList, amount) => {
  try {
    var targetData = allList[index];

    var callJS = "Odds/winTicketOdds.js";
    if (targetData.TOB == TOB_PLACE) callJS = "Odds/placeTicketOdds.js"; //複勝
    if (targetData.TOB == TOB_BRACKET) callJS = "Odds/bracketQuinellaOdds.js"; //枠連
    if (targetData.TOB == TOB_BRACKET_EXACTA || targetData.TOB == TOB_BRACKET_EXACTA_MULTI)
      callJS = "Odds/bracketExactaOdds.js"; //枠単
    if (targetData.TOB == TOB_QUINELLA) callJS = "Odds/quinellaOdds.js"; //馬連
    if (targetData.TOB == TOB_WIDE) callJS = "Odds/wideOdds.js"; //ワイド
    if (targetData.TOB == TOB_EXACTA || targetData.TOB == TOB_EXACTA_MULTI)
      callJS = "Odds/exactaOdds.js"; //馬単・マルチ
    if (targetData.TOB == TOB_TRIO) callJS = "Odds/trioOdds.js"; //３連複
    if (targetData.TOB == TOB_TRIFECTA || targetData.TOB == TOB_TRIFECTA_MULTI)
      callJS = "Odds/trifectaOdds.js"; //３連単・マルチ

    var raceNum = targetData.raceNum;
    if (raceNum.length == 3) {
      raceNum = raceNum.slice(1, 3);
    }
    var req =
        HOST +
        PATH +
        callJS +
        BACKWORDPATH +
        "?RacetrackCd=" +
        targetData.racetrackCd +
        "&DOR=" +
        targetData.dor +
        "&RaceNum=" +
        raceNum +
        "&NarFlag=" +
        isNar();

    getJSON(req, true, function (status, json, data) {
      if (status === SUCCESS) {
        //データは常に１件が正しい。
        if (json != undefined && json.length > 0) {
          var Odds = json[0];
          var betArray = new Array();
          var doFlgArray = new Array();
          var payment = amount;

          if (
              targetData.TOB == TOB_TRIO ||
              targetData.TOB == TOB_TRIFECTA ||
              targetData.TOB == TOB_TRIFECTA_MULTI
          ) {
            // オッズを挿入初期金額は1（100円）
            for (var i in addNoList) {
              var oddId =
                  "Odds" +
                  addNoList[i].l +
                  "_" +
                  addNoList[i].c +
                  "_" +
                  addNoList[i].r;
              var odds = Odds[oddId];
              const canBuy = odds !== '---.-' && odds !== '0.0'
              if (odds.indexOf(".") > -1 && canBuy) {
                odds = parseInt(odds.replace(".", ""), 10);

                addNoList[i].odds = odds;
                addNoList[i].oddsStr = Odds[oddId];
                addNoList[i].prize = 1;
                addNoList[i].oddId = oddId;
                addNoList[i].refund = parseInt(calRefund(Odds[oddId], 1 * 100), 10);
              }
            }
            addNoList = addNoList.filter(val => !isNaN(val.prize))

            payment = payment - addNoList.length;
            for (var j = payment; j > 0; j = j - 1) {
              // refundでソート
              addNoList.sort(function (a, b) {
                if (a.refund < b.refund) return -1;
                if (a.refund > b.refund) return 1;
                // イコールの場合、若い馬番優先
                if (a.l < b.l) return -1;
                if (a.l > b.l) return 1;
                if (a.c < b.c) return -1;
                if (a.c > b.c) return 1;
                if (a.r < b.r) return -1;
                if (a.r > b.r) return 1;
              });
              addNoList[0].prize = addNoList[0].prize + 1;
              addNoList[0].refund = parseInt(
                  calRefund(addNoList[0].oddsStr, addNoList[0].prize * 100),
                  10
              );
            }

            addNoList.sort(sortTrioNum("l", "c", "r"));
          } else {
            for (var i in addNoList) {
              if (targetData.TOB == TOB_WIDE) {
                var oddId = "MinOdds" + addNoList[i].l + "_" + addNoList[i].r;
              } else {
                var oddId = "Odds" + addNoList[i].l + "_" + addNoList[i].r;
              }
              var odds = Odds[oddId];
              const canBuy = odds !== '---.-' && odds !== '0.0'
              if (odds.indexOf(".") > -1 && canBuy) {
                odds = parseInt(odds.replace(".", ""), 10);

                addNoList[i].odds = odds;
                addNoList[i].oddsStr = Odds[oddId];
                addNoList[i].prize = 1;
                addNoList[i].oddId = oddId;
                addNoList[i].refund = parseInt(calRefund(Odds[oddId], 1 * 100), 10);
              }
            }
            addNoList = addNoList.filter(val => !isNaN(val.prize))

            payment = payment - addNoList.length;
            for (var j = payment; j > 0; j = j - 1) {
              // refundでソート
              addNoList.sort(function (a, b) {
                if (a.refund < b.refund) return -1;
                if (a.refund > b.refund) return 1;
                // イコールの場合、若い馬番優先
                if (a.l < b.l) return -1;
                if (a.l > b.l) return 1;
                if (a.r < b.r) return -1;
                if (a.r > b.r) return 1;
              });
              addNoList[0].prize = addNoList[0].prize + 1;
              addNoList[0].refund = parseInt(
                  calRefund(addNoList[0].oddsStr, addNoList[0].prize * 100),
                  10
              );
            }

            addNoList.sort(sortDoubleNum("l", "r"));
          }

          // パラメータ作成
          var last = allList.length - 1;

          // 買い目作成
          for (var k in addNoList) {
            var target = addNoList[k];
            var lstr = "";
            var cstr = "";
            var rstr = "";

            for (var j = 1; j <= 18; j++) {
              if (target.l != undefined) {
                if (target.l == String(j)) {
                  lstr += "1";
                } else {
                  lstr += "0";
                }
              }
              if (target.c != undefined) {
                if (target.c == String(j)) {
                  cstr += "1";
                } else {
                  cstr += "0";
                }
              }
              if (target.r != undefined) {
                if (target.r == String(j)) {
                  rstr += "1";
                } else {
                  rstr += "0";
                }
              }
            }

            betArray.push(lstr + cstr + rstr);
            doFlgArray.push(true);
          }

          if (betArray.length > 0) {
            var setRaceBetParams = new Array();

            var raceNum = targetData.raceNum;
            if (raceNum.length == 3) {
              raceNum = raceNum.slice(1, 3);
            }
            setRaceBetParams["DOR"] = targetData.dor;
            setRaceBetParams["RacetrackCd"] = targetData.racetrackCd;
            setRaceBetParams["RaceNum"] = raceNum;

            var tob = targetData.TOB;
            if (targetData.TOB == TOB_BRACKET_EXACTA_MULTI) {
              tob = TOB_BRACKET_EXACTA;
            }
            if (targetData.TOB == TOB_EXACTA_MULTI) {
              tob = TOB_EXACTA;
            }
            if (targetData.TOB == TOB_TRIFECTA_MULTI) {
              tob = TOB_TRIFECTA;
            }

            var amountArray = new Array();
            for (var o = 0; o < addNoList.length; o++) {
              amountArray.push(addNoList[o].prize);
            }

            // 買い目登録
            setRaceBetByArrayWithAnoubt(
                setRaceBetParams,
                tob,
                METHOD_NORMAL,
                betArray,
                doFlgArray,
                amountArray,
                function () {
                  // 買い目削除
                  let index = "_" + avgAmountIndex.value + "_";
                  deletePurchase(index, function () {
                    modalAmountAvgOpen.value = false
                    setAmountAvgModal.value.disable = false
                    doInit()
                  });
                }
            );
          }
        }
      }
    });
  } catch (e) {
    console.log(e);
    document.body.className = "status-loaded";
  }
};

const setRaceBetByArrayWithAnoubt = (
  params,
  TOB,
  Method,
  betArray,
  doFlgArray,
  amountArray,
  callback
) => {
  if (getCourse() == PAYINGSUGOTOKU || getCourse() == PAYINGSUGOTOKU_NONE) {
    setRaceBet4sg(params, TOB, Method, betArray, doFlgArray, amountArray);
    callback();
  } else {
    var nowindex = 0;
    //順番に登録させるため再帰的に登録
    function setRaceBetloop(nowindex) {
      if (amountArray[nowindex]) {
        setRaceBetWithAmount(
            params["DOR"],
            params["RacetrackCd"],
            params["RaceYear"],
            params["RaceNum"],
            params["RaceMeeting"],
            params["RaceDay"],
            TOB,
            Method,
            betArray[nowindex],
            doFlgArray[nowindex],
            amountArray[nowindex],
            function () {
              var nextindex = nowindex + 1;
              if (nextindex < betArray.length) {
                setRaceBetloop(nextindex);
              } else {
                callback();
              }
            }
        );
      } else {
        var nextindex = nowindex + 1;
        if (nextindex < betArray.length) {
          setRaceBetloop(nextindex);
        } else {
          callback();
        }
      }
    }
    setRaceBetloop(nowindex);
  }
};

//配当均等用買い目登録
const setRaceBetWithAmount = (
  DOR,
  RacetrackCd,
  RaceYear,
  RaceNum,
  RaceMeeting,
  RaceDay,
  TOB,
  Method,
  Bet,
  doflg,
  amount,
  callback
) => {
  if (doflg) {
    if (RaceNum < 10) {
      RaceNum = "0" + String(parseInt(RaceNum, 10));
    }
    //パラメータからAPIコールを生成
    const req =
      HOST +
      PATH +
      "Odds/setRaceBet.js" +
      BACKWORDPATH +
      "?dor=" +
      DOR +
      "&racetrackCd=" +
      RacetrackCd +
      "&raceYear=" +
      RaceYear +
      "&raceNum=" +
      RaceNum +
      "&raceMeeting=" +
      RaceMeeting +
      "&raceDay=" +
      RaceDay +
      "&TOB=" +
      TOB +
      "&method=" +
      Method +
      "&bet=" +
      Bet +
      "&amount=" +
      amount +
      "&narFlag=" +
      isNar();
    getJSON(req, true, function (status, json) {
      if (status == SUCCESS) {
        callback();
      }
    });
  } else {
    //コールバック
    callback();
  }
};

// 配当均等用の削除
const deletePurchase = (deleteIndex, callback) => {
  document.body.className = "";
  if (getCourse() == PAYINGSUGOTOKU || getCourse() == PAYINGSUGOTOKU_NONE) {
    var array = getBet4Array();
    var restArray = new Array();
    for (var i = 0; i < array.length; i++) {
      if (deleteIndex.indexOf("_" + i + "_") == -1) {
        restArray.push(array[i]);
      }
    }
    setBet4Array(restArray);
    callback();
  } else {
    var request =
      HOST +
      PATH +
      "Odds/deleteBet.js" +
      BACKWORDPATH +
      "?deleteIndex=" +
      deleteIndex;
    getJSON(request, true, function (status, json) {
      if (status == SUCCESS) {
        callback();
      }
    });
  }
  document.body.className = "status-loaded";
};

const callDartForIpatSignUp = (type, info = '') => {
  let host = isNar() ? NAR_HOST : JRA_HOST
  window.flutter_inappwebview.callHandler("save_ipat_info", {
    "inet_id": loginInfo.inetId,
    "user_id": loginInfo.userId,
    "pars": loginInfo.pars
  })


  window.flutter_inappwebview.callHandler("launch_ipat", {
    "launch_type": type,
    "launch_url": host + 'purchase/appStaticIpat?info=' + info + '&',
  })

}
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">購入予定一覧<template v-if="narFlag">(IPAT)</template></h1>
      <div class="nav" v-if="narFlag">
        <router-link to="/purchase/spat4" class="btn-spat4 -arrow">
          <img src="/assets/images/purchase/spat4_logo.png" class="spat4-logo" />
          で購入する
        </router-link>
      </div>
    </section>
    <div class="container-box">
      <div class="inner">
        <section class="layout-section">
          <div v-for="(item, index) in ContentListAll" :key="index">
            <h2 v-if="item.HeaderTitle" class="heading-circle">
              {{ item.HeaderTitle }}
            </h2>
            <table
              v-if="ContentListAll"
              class="table-grid -purchaseplan -center -middle"
              :class="item.Method === METHOD_NORMAL && !item.isBracketExacta ? '' : '-nototal'"
            >
              <thead>
                <tr v-if="item.Method == METHOD_NORMAL">
                  <th class="-kaimoku">買い目</th>
                  <th class="-odds">オッズ</th>
                  <th class="-amount">
                    <span v-if="!item.isBracketExacta"
                          @click="amountModal(item.detail)"
                          class="btn-basic -noarrow">
                      金額一括入力
                    </span>
                  </th>
                  <th>
                    <span class="form-checkbox -check -zen" v-if="!item.isBracketExacta">
                      <label>
                        <input
                          type="checkbox"
                          @change="setRaceAll(item.detail, $event)"
                          value=""
                          name="" />
                        <i></i>
                      </label>
                    </span>
                  </th>
                </tr>
                <tr v-else-if="item.Method == METHOD_BOX">
                  <th class="-kaimoku">買い目</th>
                  <th class="-score">購入点数</th>
                  <th class="-amount">
                    <span v-if="!item.isBracketExacta"
                          @click="amountAvgModal(item.detail)"
                          class="btn-basic -noarrow">
                      配当均等
                    </span>
                  </th>
                  <th>
                    <span class="form-checkbox -check -zen" v-if="!item.isBracketExacta">
                      <label>
                        <input type="checkbox"
                               @change="setRaceAll(item.detail, $event)"
                               value=""
                               name="" />
                        <i></i>
                      </label>
                    </span>
                  </th>
                </tr>
                <tr v-else>
                  <th class="-kaimoku" colspan="2">買い目</th>
                  <th class="-score">購入点数</th>
                  <th class="-amount">
                    <span v-if="!item.isBracketExacta"
                          @click="amountAvgModal(item.detail)"
                          class="btn-basic -noarrow">
                      配当均等
                    </span>
                  </th>
                  <th>
                    <span class="form-checkbox -check -zen" v-if="!item.isBracketExacta">
                      <label>
                        <input type="checkbox"
                               @change="setRaceAll(item.detail, $event)"
                               value=""
                               name="" />
                        <i></i>
                      </label>
                    </span>
                  </th>
                </tr>
              </thead>

              <tbody v-for="(detail, key) in item.detail" :key="key">
                <!-- 明細が1行になる通常、ボックスの場合 -->
                <template
                  v-if="
                    detail.Method == METHOD_NORMAL ||
                    detail.Method == METHOD_BOX
                  "
                >
                  <tr>
                    <!-- 単勝、複勝の場合 -->
                    <template
                      v-if="detail.TOB == TOB_WIN || detail.TOB == TOB_PLACE"
                    >
                      <td class="-kaimoku">
                        <i :class="detail.HorseNum">{{ detail.LeftNo }}</i>
                      </td>
                      <td
                        v-if="detail.odds1Class && detail.odds2Class"
                        class="-odds"
                      >
                        <span :class="`txt-odds -no${detail.odds1Class}`">
                          {{ detail.odds1Value }}
                        </span>
                        <span class="txt-odds -no0">-</span>
                        <span :class="`txt-odds -no${detail.odds2Class}`">
                          {{ detail.odds2Value }}
                        </span>
                      </td>
                      <td v-else class="-odds" :class="detail.oddsClass">
                        {{ detail.oddsValue }}
                      </td>
                    </template>
                    <!-- 枠連基本、馬連基本、馬単基本、ワイド基本の場合 -->
                    <template
                      v-else-if="
                        (detail.TOB == TOB_BRACKET ||
                          detail.TOB == TOB_BRACKET_EXACTA ||
                          detail.TOB == TOB_QUINELLA ||
                          detail.TOB == TOB_EXACTA ||
                          detail.TOB == TOB_WIDE) &&
                        detail.Method == METHOD_NORMAL
                      "
                    >
                      <td class="-kaimoku">
                        <ul class="list-kaimoku">
                          <li>
                            <i :class="detail.wNol">{{ detail.Left }}</i>
                          </li>
                          <li>
                            <i :class="detail.wNoc">{{ detail.Center }}</i>
                          </li>
                        </ul>
                      </td>
                      <td
                        v-if="detail.odds1Class && detail.odds2Class"
                        class="-odds"
                      >
                        <span :class="`txt-odds -no${detail.odds1Class}`">
                          {{ detail.odds1Value }}
                        </span>
                        <span class="txt-odds -no0">-</span>
                        <span :class="`txt-odds -no${detail.odds2Class}`">
                          {{ detail.odds2Value }}
                        </span>
                      </td>
                      <td v-else class="-odds" :class="detail.oddsClass">
                        {{ detail.oddsValue }}
                      </td>
                    </template>
                    <!-- ボックスの場合 -->
                    <template v-else-if="detail.Method == METHOD_BOX">
                      <!-- 枠連で同枠がある場合、行追加 -->
                      <template
                        v-if="
                          detail.TOB == TOB_BRACKET &&
                          detail.SameBracket &&
                          detail.SameBracket != ''
                        "
                      >
                        <td
                          class="-kaimoku -left"
                          v-html="detail.LeftFormatTags"
                        ></td>
                        <td class="-score">{{ detail.Ten }}点</td>
                      </template>
                      <template v-else>
                        <td
                          class="-kaimoku -left"
                          v-html="detail.LeftFormatTags"
                        ></td>
                        <td class="-score">{{ detail.Ten }}点</td>
                      </template>
                    </template>
                    <!-- 3連複、3連単の基本 -->
                    <template
                      v-else-if="
                        (detail.TOB == TOB_TRIO ||
                          detail.TOB == TOB_TRIFECTA) &&
                        detail.Method == METHOD_NORMAL
                      "
                    >
                      <td class="-kaimoku">
                        <ul class="list-kaimoku">
                          <li>
                            <i :class="detail.wNol">{{ detail.Left }}</i>
                          </li>
                          <li>
                            <i :class="detail.wNoc">{{ detail.Center }}</i>
                          </li>
                          <li>
                            <i :class="detail.wNor">{{ detail.Right }}</i>
                          </li>
                        </ul>
                      </td>
                      <td class="-odds">
                        <span :class="detail.oddsClass">{{
                          detail.oddsValue
                        }}</span>
                      </td>
                    </template>
                    <td class="-amount">
                      <span v-if="item.isBracketExacta" class="_bold">購入不可</span>
                      <template v-else>
                        <span class="form-number -digit2">
                        <label class="input-number">
                          <input
                            type="number"
                            min="1"
                            max="9999"
                            @change="
                              resetRefund(
                                detail.oddsValue,
                                detail.uniqueNum,
                                $event
                              )
                            "
                            v-model.number="amountList[detail.uniqueNum]"
                            name=""
                          />
                        </label>
                      </span>
                        00円
                      </template>
                    </td>
                    <td rowspan="2" class="-middle -check">
                      <span class="form-checkbox" v-if="!item.isBracketExacta">
                        <label>
                          <input
                            @change="setSelect(detail.uniqueNum, $event)"
                            v-model="CheckList[detail.uniqueNum]"
                            type="checkbox"
                            value=""
                            name=""
                          /><i></i>
                        </label>
                      </span>
                    </td>
                  </tr>
                  <tr v-if="detail.Method == METHOD_NORMAL && !item.isBracketExacta">
                    <td colspan="3" class="-total">
                      <span>
                        払戻予定額：{{
                          setRefound(
                            (detail.oddsValue || detail.odds1Value),
                            amountList[detail.uniqueNum]
                          )
                        }}円
                      </span>
                    </td>
                  </tr>
                </template>

                <!-- 明細が２行となる馬連、馬単、ワイドの流し、フォーメーション -->
                <!-- 3連複 1頭軸流し・2頭軸流し、3連単 1着軸流し・2着軸流し・3着軸流しの場合 -->
                <template
                  v-else-if="
                    ((detail.TOB == TOB_EXACTA ||
                      detail.TOB == TOB_BRACKET_EXACTA ||
                      detail.TOB == TOB_BRACKET_EXACTA_MULTI ||
                      detail.TOB == TOB_QUINELLA ||
                      detail.TOB == TOB_WIDE ||
                      detail.TOB == TOB_EXACTA_MULTI) &&
                      (detail.Method == METHOD_FORMATION ||
                        detail.Method == METHOD_WHEEL ||
                        detail.Method == METHOD_WHEEL_1 ||
                        detail.Method == METHOD_WHEEL_2)) ||
                    ((detail.TOB == TOB_TRIO ||
                      detail.TOB == TOB_TRIFECTA ||
                      detail.TOB == TOB_TRIFECTA_MULTI) &&
                      (detail.Method == METHOD_WHEEL_1 ||
                        detail.Method == METHOD_WHEEL_2 ||
                        detail.Method == METHOD_WHEEL_3))
                  "
                >
                  <tr>
                    <td class="-kaimoku01">{{ detail.FirstTitle }}</td>
                    <td
                      class="-kaimoku02 -left"
                      v-html="detail.LeftFormatTags"
                    ></td>
                    <td class="-score" rowspan="2">{{ detail.Ten }}点</td>
                    <td class="-amount" rowspan="2">
                      <span v-if="item.isBracketExacta" class="_bold">購入不可</span>
                      <template v-else>
                        <span class="form-number -digit2">
                        <label class="input-number">
                          <input
                            type="number"
                            min="1"
                            max="9999"
                            @change="
                              resetRefund(detail.Ten, detail.uniqueNum, $event)
                            "
                            v-model.number="amountList[detail.uniqueNum]"
                            name=""
                          />
                        </label>
                        </span>
                        00円
                      </template>
                    </td>
                    <td rowspan="2" class="-middle -check">
                      <span class="form-checkbox" v-if="!item.isBracketExacta">
                        <label>
                          <input
                            @change="setSelect(detail.uniqueNum, $event)"
                            v-model="CheckList[detail.uniqueNum]"
                            type="checkbox"
                            value=""
                            name=""/>
                          <i></i>
                        </label>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="-kaimoku01">{{ detail.SecondTitle }}</td>
                    <td
                      class="-kaimoku02 -left"
                      v-html="detail.CenterFormatTags"
                    ></td>
                  </tr>
                </template>

                <!-- 明細が３行となる３連単、３連複の1・2着軸流し、1・3着軸流し、2・3着軸流し、フォーメーションの場合 -->
                <template
                  v-else-if="
                    (detail.TOB == TOB_TRIO ||
                      detail.TOB == TOB_TRIFECTA ||
                      detail.TOB == TOB_TRIFECTA_MULTI) &&
                    (detail.Method == METHOD_WHEEL_1_2 ||
                      detail.Method == METHOD_WHEEL_1_3 ||
                      detail.Method == METHOD_WHEEL_2_3 ||
                      detail.Method == METHOD_FORMATION)
                  "
                >
                  <tr>
                    <td class="-kaimoku01">{{ detail.FirstTitle }}</td>
                    <td
                      class="-kaimoku02 -left"
                      v-html="detail.LeftFormatTags"
                    ></td>
                    <td class="-score" rowspan="3">{{ detail.Ten }}点</td>
                    <td class="-amount" rowspan="3">
                      <span class="form-number -digit2">
                        <label class="input-number">
                          <input
                            type="number"
                            min="1"
                            max="9999"
                            @change="
                              resetRefund(detail.Ten, detail.uniqueNum, $event)
                            "
                            v-model.number="amountList[detail.uniqueNum]"
                            name=""
                          />
                        </label> </span
                      >00円
                    </td>
                    <td rowspan="3" class="-middle -check">
                      <span class="form-checkbox">
                        <label>
                          <input
                            @change="setSelect(detail.uniqueNum, $event)"
                            v-model="CheckList[detail.uniqueNum]"
                            type="checkbox"
                            value=""
                            name=""
                          /><i></i>
                        </label>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="-kaimoku01">{{ detail.SecondTitle }}</td>
                    <td
                      class="-kaimoku02 -left"
                      v-html="detail.CenterFormatTags"
                    ></td>
                  </tr>
                  <tr>
                    <td class="-kaimoku01">{{ detail.ThirdTitle }}</td>
                    <td
                      class="-kaimoku02 -left"
                      v-html="detail.RightFormatTags"
                    ></td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>

          <div class="container-total">
            <dl class="amount">
              <dt>点数合計</dt>
              <dd>
                <span class="num">{{ totalCount }}</span
                >点
              </dd>
            </dl>
            <dl class="amount">
              <dt>購入金額合計</dt>
              <dd>
                <span class="num" v-bind="itemPrize.value">{{ itemPrize }}</span
                >円
              </dd>
            </dl>
          </div>
        </section>

        <section class="layout-section -select255">
          <div class="inner _center">
            <p>※一度に購入できる買い目は255点までです。</p>
            <button class="btn-basic -noarrow" @click="setAllSelect($event)">
              買い目を上から255点選択する
            </button>
          </div>
        </section>

        <section class="layout-section -ipat">
          <div class="inner _center">
            <span @click="setIpat(allList)" class="btn-basic"
              >選択した項目をIPAT投票する</span
            >
            <span
              v-if="!isWebviewAndPayingNone"
              @click="setBalance(allList)"
              class="btn-basic -white -addbalance"
              >選択した項目を収支に登録する</span
            >
          </div>
        </section>

        <section class="layout-section -addremove">
          <div class="inner _center">
            <span @click="delModal" class="btn-basic -gray -removeselect"
              >選択項目を削除</span
            >
            <router-link class="btn-basic" :to="narFlag ? '/race' : '/race/list'">買い目を追加</router-link>
          </div>
        </section>
      </div>

      <div v-if="!isWebviewAndPayingNone" class="inner">
        <h2 class="heading-circle">収支管理</h2>

        <table class="table-grid -collection">
          <tbody>
            <tr>
              <td>
                <router-link :to="'/balance'" class="link-cell">
                  <div class="title">収支管理へ</div>
                  過去に登録した収支を確認できます。
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="inner">
        <h2 class="heading-circle">IPAT設定</h2>
        <table class="table-grid -collection">
          <tbody>
            <tr>
              <td>
                <router-link :to="'/purchase/staticIpat'" class="link-cell">
                  <div class="title">登録内容の設定</div>
                  IPATのログイン情報を保存できます。
                </router-link>
              </td>
            </tr>
            <tr>
              <td>
                <a
                  @click="isWebview ? callDartForIpatSignUp(1) : goIpat()"
                  class="link-cell -window">
                  <div class="title">IPATメニュー</div>
                  IPATに移動します。<span class="_caption"
                    >(別ウィンドウで表示)</span
                  >
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <router-link :to="'/purchase/staticConfirm'" class="link-cell">
                  <div class="title">IPAT連動利用規約</div>
                  IPAT連動機能を利用する上での利用規約です。
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
  <div class="loading-anim">
    <div class="inner">
      <img src="/assets/images/common/loading.svg" alt="" class="loading" />
    </div>
  </div>
  <SetBalanceModal
    v-on:closeDialog="modalBalanceOpen = false"
    :modalBalanceOpen="modalBalanceOpen"
    @delItem="delItem"
  >
  </SetBalanceModal>
  <SetAmountModal
    v-on:closeDialog2="modalAmountOpen = false"
    :modalAmountOpen="modalAmountOpen"
    @receiveAmountItem="receiveAmountItem"
  >
  </SetAmountModal>
  <SetDelModal
    v-on:closeDialog3="modalDelOpen = false"
    :modalDelOpen="modalDelOpen"
    @delItem="delItem"
  >
  </SetDelModal>
  <SetAmountAvgModal
    ref="setAmountAvgModal"
    v-on:closeDialog4="modalAmountAvgOpen = false"
    :modalAmountAvgOpen="modalAmountAvgOpen"
    @receiveAmountAvgItem="receiveAmountAvgItem"
  >
  </SetAmountAvgModal>
</template>

<style lang="scss" src="../../assets/css/purchase/style.scss" scoped></style>