import {BACKWORDPATH, DATAPATH, HOST, PATH} from "../../assets/js/define";
import {getJSON, prizeToString, SUCCESS,} from "../../assets/js/common";
import {storeToRefs} from "pinia";
import {useStoreCommon} from "../../stores/common-states"
import {useStoreStudData} from "../../stores/data/stud-states";
import {getBlood} from '../../assets/js/data-common'

let storeStudData = null;
let narFlag = 0;

export async function fetch(breedingNum) {
  const storeCommon = useStoreCommon()
  const {host, isNarSite} = storeToRefs(storeCommon);
  storeStudData = useStoreStudData();
  narFlag = isNarSite.value ? 1 : 0;

  await dataBreeding(breedingNum, host.value, isNarSite.value);
  await dataBrother(breedingNum, host.value);
}

const dataBreeding = async (breedingNum, host, isNar) => {
  const {
    breedingNumValue,
    studData,
    careerStatData,
    bloodData,
    crossAppend,
  } = storeToRefs(storeStudData);

  let request =
    host +
    PATH +
    "Master/breedingMasterAPI.js" +
    BACKWORDPATH +
    "?BreedingNum=" +
    breedingNum +
    "&NarFlag=" +
    narFlag;
  request = encodeURI(request);

  await getJSON(request, true, function (status, json) {
    if (status !== SUCCESS || !json?.length) return;
    let useData = json[0];
    breedingNumValue.value = useData.BreedingNum;

    //競走馬データへのリンク生成
    let urlLink;
    if (useData.PedigreeNum !== "0000000000") {
      urlLink =
        host +
        DATAPATH +
        "horse?PedigreeNum=" +
        useData.PedigreeNum;
    }
    useData.UrlLink = urlLink;

    //賞金額を文字列（*億*万円）に整形する
    let latestAnnualEarnings = '-';
    if (useData.LatestAnnualEarnings && useData.LatestAnnualEarnings > 0) {
      latestAnnualEarnings = Number(useData.LatestAnnualEarnings);
      latestAnnualEarnings = prizeToString(latestAnnualEarnings);
    }
    useData.LatestAnnualEarnings = latestAnnualEarnings;

    // ランキングのURL生成
    let leadUrl = HOST + "leading/stud?rank=" + useData.LatestWinsRanking;
    useData.LatestWinsRanking = useData.LatestWinsRanking && useData.LatestWinsRanking > 0 ?
      "(" +
      "<a href='" +
      leadUrl +
      "'>" +
      useData.LatestWinsRanking +
      "位</a>" +
      ")" : '(-位)';

    let str;
    if (useData.LeadingUpdated && 8 <= useData.LeadingUpdated.length) {
      let updatedL = useData.LeadingUpdated;
      str =
        updatedL.slice(0, 4) +
        "年" +
        updatedL.slice(4, 6) +
        "月" +
        updatedL.slice(6, 8) +
        "日";
      if (updatedL.length > 8 && isNar) {
        str += ' ' + updatedL.slice(8, 10) + ':' + updatedL.slice(10, 12);
      }
    }
    useData.LeadingUpdated = "成績集計 " + str + "現在";

    studData.value = useData;
    careerStatData.value = useData.CareerStat;

    crossAppend.value = useData.CrossDegree;

    const horseHairColor = {
      HairColorFather: useData.HairColorCd_Father,
      HairColorMother: useData.HairColorCd_Mother,
    }

    const pedigreeInfo = useData.PedigreeInfo
    if (pedigreeInfo && Object.keys(pedigreeInfo).length) {
      bloodData.value = getBlood(pedigreeInfo, horseHairColor, host);
    }
  });
}

//主な産駒データ取得
const dataBrother = async (breedingNum, host) => {
  const {
    offspringData
  } = storeToRefs(storeStudData);

  const mode = "sire";
  let request =
    host +
    PATH +
    "Master/offspringMasterAPI.js" +
    BACKWORDPATH +
    "?num=" +
    breedingNum +
    "&mode=" +
    mode +
    "&narFlag=" +
    narFlag;
  request = encodeURI(request);
  await getJSON(request, true, function (status, json) {
    if (status === SUCCESS) {
      offspringData.value = json[0]?.Offspring ?? [];
    }
  });
}