<script setup>
import {onMounted, ref, watch, onServerPrefetch} from "vue";

import HorseRecord from "../../components/data/horse/HorseRecord.vue";
import Achievement from "../../components/data/horse/Achievement.vue";
import NewInfo from "../../components/data/horse/NewInfo.vue";
import Blood from "../../components/data/horse/Blood.vue";
import Time from "../../components/data/horse/Time.vue";
import ShowImage from "../../components/utils/ShowImage.vue";
import Slider from '../../components/data/horse/ThumbnailSlider.vue'

import { useStoreHorseData } from "../../stores/horse-data-states";
import {useRoute} from "vue-router";

import {
  ADD,
  changeFavoriteFromCookie,
  changeNextTargetFromCookie,
  FRACEHORSE,
  getJSON,
  getTopBanner,
  getUrlVars,
  getUserAgent,
  getUserId,
  isFavorite,
  isNar,
  isNextTarget as funcIsNextTarget,
  isPogUser,
  isSugotoku,
  PAYINGAPPPASS,
  PAYINGECONOMY,
  PAYINGNONE,
  PAYINGPACK03,
  PAYINGPACK06,
  PAYINGPACK12,
  PAYINGPREMIUM,
  PAYINGSMARTPASS,
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
  PAYINGYAHOO,
  prizeToString,
  REMOVE,
  retrieveCourse,
  SUCCESS,
  TRACEHORSE,
} from "../../assets/js/common";
import {
  BACKWORDPATH,
  HOST,
  MEDIAPATH,
  PATH,
  POGPATH,
  RACEPATH,
} from "../../assets/js/define";
import {storeToRefs} from "pinia";
import {fetchPredictedSalesBanner} from '../../../spa/assets/js/wordpress'

import {fetch as fetchHorse} from "../../functions/data/horse";

// store
const storeHorse = useStoreHorseData();
const route = useRoute();
onServerPrefetch(async () => {
  await fetchHorse(route.query["PedigreeNum"]);
});

//パラメータ(固定)
const NARFLAG = ref(null)
const PAYINGDATA = {
  PAYINGPREMIUM: PAYINGPREMIUM,
  PAYINGSUGOTOKU: PAYINGSUGOTOKU,
  PAYINGPACK03: PAYINGPACK03,
  PAYINGPACK06: PAYINGPACK06,
  PAYINGPACK12: PAYINGPACK12,
  PAYINGSMARTPASS: PAYINGSMARTPASS,
  PAYINGYAHOO: PAYINGYAHOO,
  PAYINGECONOMY: PAYINGECONOMY,
  PAYINGSUGOTOKU_NONE: PAYINGSUGOTOKU_NONE,
  PAYINGAPPPASS: PAYINGAPPPASS,
  PAYINGNONE: PAYINGNONE,
};

//データ
const registerInfo = ref(null)
const videoUrlArray = ref([])
const isNotPremium = ref(false)
const pogUrl = ref(null)
const isPog = ref(false)

//課金コース
const userCourse = ref(null)
const courseId = ref(PAYINGNONE)
const userId = ref("")

const favClass = ref("")
const nextTargetClass = ref("")
const mCourse = ref(PAYINGNONE)
const isFav = ref(false)
const isWebView = ref(false)
const openImage = ref(false)
const imageURL = ref("")
const isNextTarget = ref(false)
const tab = ref("horseRecord");
const isCurrentTab = ref("horseRecord");
const isMounted = ref(false);
const banner = ref("")
const predictedSalesBanner = ref('');

const {
  news,
  horseData,
  auctionInfo,
  raceResult,
  specialEntryData,
  horseResultData,
  narHorseResultData,
  jraOutHorseResultData,
  bestRecordData,
  narBestRecordData,
  pedigreeStatData,
  crossDegreeData,
  horseHairColor,
  horsePedigreeNum,
  loadingInfo,
  horseSymbol1,
  horseSymbol2,
  nextRacesData,
  breedHorseURL,
  breedingTitle,
  offspringData,
  isPogHorse,
} = storeToRefs(storeHorse);

const tabChange = (data) => {
  tab.value = data;
  isCurrentTab.value = data;
};

watch(route, async (from, to) => {
  storeHorse.reset();
  await fetchHorse(to.query["PedigreeNum"]);
})

onMounted(async () => {
  document.body.className = "";
  isWebView.value = getUserAgent();
  NARFLAG.value = isNar();

  if(horsePedigreeNum.value !== route.query["PedigreeNum"]){
    storeHorse.reset();
    await fetchHorse(route.query["PedigreeNum"]);
  }

  if (!horseData.value?.RaceHorseData?.HorseName) {
    alert('競走馬データが存在しません。')
    document.body.className = "status-loaded";
    return;
  }

  if (!isWebView.value && !isSugotoku()) {
    banner.value = await getTopBanner()
  }
  fetchPredictedSalesBanner(predictedSalesBanner, 'horse');

  // パラメータ取得
  const params = getUrlVars();

  callRetrieveCourse(function (argCurseId, showMarks, argUserId) {
    //単勝オッズ
    //課金コースのチェック、条件実績タブの非有効化
    // 会員コースを確認
    mCourse.value = argCurseId;
    courseId.value = argCurseId;
    userId.value = argUserId || getUserId();
    if (courseId.value == PAYINGNONE) {
      // 無課金ユーザ会員登録ページ
      userCourse.value = HOST + "member";
    } else if (courseId.value == PAYINGYAHOO) {
      // Y!プレミアムブリッジページ
      userCourse.value =
          "/member/error/y_premium_info_close";
    } else if (courseId.value == PAYINGSUGOTOKU_NONE) {
      // スゴ特無課金ページ
      userCourse.value = HOST + "joinSugotoku.js";
    }

    // POG表示
    if (courseId.value !== PAYINGNONE && isPogHorse.value) {
      if (isPogUser() === 0) {
        pogUrl.value = HOST + POGPATH + "join";
      } else {
        pogUrl.value =
            HOST +
            POGPATH +
            "horse_detail?PedigreeNum=" +
            params.PedigreeNum;
      }
      // pog表示
      isPog.value =
          courseId.value !== PAYINGSUGOTOKU &&
          courseId.value !== PAYINGSUGOTOKU_NONE &&
          courseId.value !== PAYINGAPPPASS;
    }

    //お気に入り,次走狙い馬の表示
    if (
        courseId.value !== PAYINGNONE &&
        courseId.value !== PAYINGSUGOTOKU_NONE
    ) {
      isFav.value = true;
      if (isFavorite(FRACEHORSE, horsePedigreeNum.value) === 1) {
        favClass.value = "-registed";
      } else {
        favClass.value = "";
      }
      isNextTarget.value = true;
      if (funcIsNextTarget(TRACEHORSE, horsePedigreeNum.value) === 1) {
        //自動解除済みの場合か判定
        isAutoDeletedNextTarget(userId.value, horsePedigreeNum.value);
      } else {
        nextTargetClass.value = "";
      }
    }

    if (isNar() === 0) {
      setVideoLink(raceResult.value, courseId.value);
    }
    isMounted.value = true;
    document.body.className = "status-loaded";
  });

  function callRetrieveCourse(callback) {
    try {
      retrieveCourse(callback);
    } catch (e) {
      callback("none", false, "");
    }
  }
  // 動画リンクを生成
  function setVideoLink(raceResult, courseId) {
    const COMMON_URL = HOST + MEDIAPATH + "videoOnDemand.js" + BACKWORDPATH;

    let dor = "";
    let racetrackCd = "";
    let raceNum = "";
    let reqLst = [];
    for (let i = 0; i < raceResult.length; i++) {
      const useData = raceResult[i];
      dor = useData.DOR;
      racetrackCd = useData.RacetrackCd;
      raceNum = useData.RaceNum;
      reqLst.push(dor + racetrackCd + raceNum);
    }
    if (reqLst.length == 0) {
      return;
    }

    const request =
        HOST +
        MEDIAPATH +
        "getVideoUrl.js" +
        BACKWORDPATH +
        "?Lst=" +
        reqLst.join(",") +
        "&NarFlag=" +
        isNar();
    getJSON(request, true, function (status, results) {
      if (status !== SUCCESS) {
        return;
      }
      videoUrlArray.value.splice(0, videoUrlArray.value.length);
      for (let i = 0; i < results.length; i++) {
        const key = results[i].Key;

        let user = courseId;
        let url;
        if (
            PAYINGPREMIUM === user ||
            PAYINGPACK03 === user ||
            PAYINGPACK06 === user ||
            PAYINGPACK12 === user ||
            PAYINGSUGOTOKU === user
        ) {
          //premium
          url =
              COMMON_URL +
              "?DOR=" +
              key.substr(0, 8) +
              "&RacetrackCd=" +
              key.substr(8, 2) +
              "&RaceNum=" +
              key.substr(10, 2) +
              "&Expir=" +
              results[i].Expir +
              "&x=.mp4";
          isNotPremium.value = false;
        } else if (PAYINGSUGOTOKU_NONE === user) {
          url = HOST + "joinSugotoku.js";
          isNotPremium.value = true;
        } else {
          url = HOST + (user === PAYINGECONOMY ? "member/join_economy" : "member")
          isNotPremium.value = true;
        }
        videoUrlArray.value.push({
          url: url,
          key: key,
          exist: results[i].Exist
        });
      }
    });
  };


  //兄弟馬データ取得

  //種牡・牝馬データ取得

  // 次走情報

  //次走狙い馬が自動削除されていた場合はクッキーから削除
  async function isAutoDeletedNextTarget(userId, pedigreeNum) {
    let request =
        HOST +
        PATH +
        "MyNextTarget/getMyNextTargetRacehorse.js" +
        BACKWORDPATH +
        "?userID=" + encodeURIComponent(userId)  +
        "&pedigreeNum=" + pedigreeNum
    await getJSON(request, true, function (status, json) {
      if (status === SUCCESS) {
        if(json.includes(pedigreeNum)){
          nextTargetClass.value = "-registed";
        } else {
          // 自動解除済みの場合cookieから次走狙い馬を削除
          changeNextTargetFromCookie(TRACEHORSE, pedigreeNum, REMOVE);
          nextTargetClass.value = "";
        }
      }
    });
  }
});
const clickFav = async () => {
  try {
    document.body.className = "";
    if (isFavorite(FRACEHORSE, horsePedigreeNum.value) == 1) {
      // お気に入り登済みなので削除する
      const request =
          HOST +
          PATH +
          "MyFavorite/deleteMyFavoriteRacehorse.js" +
          BACKWORDPATH +
          "?" +
          "userID=" +
          encodeURIComponent(userId.value) +
          "&pedigreeNum=" +
          horsePedigreeNum.value +
          "&NarFlag=" +
          isNar();
      const order = REMOVE;
      await getJSON(request, false, function (status, json) {
        if (status == SUCCESS) {
        } else {
          alert("お気に入りの削除に失敗しました。");
        }
      });
      const favJudge = await changeFavoriteFromCookie(
          FRACEHORSE,
          horsePedigreeNum.value,
          order
      );
      if (favJudge) {
        //アイコンクラス変更
        favClass.value = "";
      } else {
        favClass.value = "";
      }
    } else {
      // お気に入り未登録なので登録する
      const request =
          HOST +
          PATH +
          "MyFavorite/setMyFavoriteRacehorse.js" +
          BACKWORDPATH +
          "?" +
          "userID=" +
          encodeURIComponent(userId.value) +
          "&pedigreeNum=" +
          horsePedigreeNum.value +
          "&isPush=1" +
          "&NarFlag=" +
          isNar();
      const order = ADD;
      await getJSON(request, false, function (status, json) {
        if (status == SUCCESS) {
          favStatus.value = SUCCESS;
        } else {
          alert("お気に入りの登録に失敗しました。");
        }
      });
      const favJudge = await changeFavoriteFromCookie(
          FRACEHORSE,
          horsePedigreeNum.value,
          order
      );
      if (favJudge) {
        favClass.value = "-registed";
      } else {
        favClass.value = "";
      }
    }
    document.body.className = "status-loaded";
  } catch (e) {
    console.log("error" + e);
  }
}
    //次走狙い馬登録用
const clickNextTarget = async () => {
  try {
    document.body.className = "";
    if(courseId.value === PAYINGECONOMY){
      location.href = HOST + 'member/join_economy';
    } else {
      if (funcIsNextTarget(TRACEHORSE, horsePedigreeNum.value) == 1) {
        // 次走狙い馬登録済みなので削除する
        const request =
            HOST +
            PATH +
            "MyNextTarget/deleteMyNextTargetRacehorse.js" +
            BACKWORDPATH +
            "?" +
            "userID=" +
            encodeURIComponent(userId.value) +
            "&pedigreeNum=" +
            horsePedigreeNum.value +
            "&narFlag=" +
            isNar();
        const order = REMOVE;
        await getJSON(request, false, function (status, json) {
          if (status == SUCCESS) {
          } else {
            alert("次走狙い馬の削除に失敗しました。");
          }
        });
        await changeNextTargetFromCookie(
            TRACEHORSE,
            horsePedigreeNum.value,
            order
        );
        nextTargetClass.value = "";
      } else {
        // 次走狙い馬未登録なので登録する
        const request =
            HOST +
            PATH +
            "MyNextTarget/setMyNextTargetRacehorse.js" +
            BACKWORDPATH +
            "?" +
            "userID=" +
            encodeURIComponent(userId.value) +
            "&pedigreeNum=" +
            horsePedigreeNum.value +
            "&isPush=1" +
            "&narFlag=" +
            isNar();
        const order = ADD;
        await getJSON(request, false, function (status, json) {
          if (status == SUCCESS) {
            //nextTargetStatus.value = SUCCESS;
          } else {
            alert("次走狙い馬の登録に失敗しました。");
          }
        });
        const nextTargetJudge = await changeNextTargetFromCookie(
            TRACEHORSE,
            horsePedigreeNum.value,
            order
        );
        if (nextTargetJudge) {
          nextTargetClass.value = "-registed";
        }
      }
    }
    document.body.className = "status-loaded";
  } catch (e) {
    console.log("error" + e);
  }
}

const showImage = ($event) => {
  imageURL.value = $event;
  openImage.value = true;
}

const createDateStr = (date) => {
  const w = ["日", "月", "火", "水", "木", "金", "土"];
  let result = "";
  if (date != undefined && date != null && date.length === 8) {
    const day = new Date(
        date.slice(0, 4) + "/" + date.slice(4, 6) + "/" + date.slice(6, 8)
    );
    let dayStr = "";
    dayStr = w[day.getDay()];
    result =
        String(parseInt(date.slice(4, 6), 10)) +
        "月" +
        String(parseInt(date.slice(6, 8), 10)) +
        "日（" +
        dayStr +
        ")";
  }

  return result;
}

const checkDisplayStatusIcon = (retireDiv, snkDataFlg) => {
  return retireDiv === '引退' || snkDataFlg === 1 || NARFLAG.value === 1 ||
      horseSymbol1.value === "-kakuchi" || horseSymbol2.value === "-kakuchi"
}

</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -horse">
      <h1 class="heading-page">{{ horseData?.RaceHorseData?.HorseName }}の競走馬データ</h1>
      <a v-if="breedHorseURL != null" class="btn-basic" :href="breedHorseURL">{{
        breedingTitle
      }}</a>
    </section>
    <section v-if="loadingInfo && horseData?.RaceHorseData?.HorseName" class="layout-section">
      <div class="summary-title">
        <div class="summary">
          <div class="inner">
            <h2 class="title">
              <i
                class="icon-horse"
                v-if="horseSymbol1"
                :class="horseSymbol1"
              ></i>
              <i
                class="icon-horse"
                v-if="horseSymbol2"
                :class="horseSymbol2"
              ></i
              >{{ horseData.RaceHorseData.HorseName }}
              <span v-if="checkDisplayStatusIcon(horseData.RaceHorseData.RetireDiv, horseData.RaceHorseData.SnkDataFlg)">
              </span>
              <span v-else-if="horseData.RaceHorseData.StablingFlg === 1"
                    class="icon-status -stables">
              </span>
              <span v-else class="icon-status -grazing"></span>
            </h2>
            <div class="caption">
              {{ horseData.RaceHorseData.SexCd
              }}{{ horseData.RaceHorseData.age }}({{
                horseData.RaceHorseData.DOB
              }}生){{ horseData.RaceHorseData.HairColorCd }}
            </div>
            <div class="caption">
              <span v-if="NARFLAG === 0 && !horseData.RaceHorseData.IsNarData">
                {{ horseData.RaceHorseData.Class }}
              </span>
              <span v-if="horseData.RaceHorseData.IsActive === 1"
                    class="icon-status -erase">
              </span>
              <span v-else-if="horseData.RaceHorseData.SnkDataFlg === 1 && NARFLAG === 0 && !horseData.RaceHorseData.IsNarData"
                    class="icon-status -unregistered">
              </span>
              <span v-else-if="horseData.RaceHorseData.IsActive === 0"
                    class="icon-status -active">
              </span>
            </div>
          </div>
          <ul class="list-icon -bottomleft">
              <li><a v-if="NARFLAG === 0 && isPog" :href="pogUrl" class="icon-pog">POG</a></li>
              <li v-if="courseId === PAYINGDATA.PAYINGECONOMY">
                <div v-if="isNextTarget" class="icon-nextrun -premium" :class="nextTargetClass" @click="clickNextTarget">
                    <span class="form-checkboxgroup">
                      <label>
                        <input type="checkbox" name="cat">
                        <span>次走狙い</span>
                      </label>
                    </span>
                  </div>
              </li>
              <li v-else>
                <div v-if="isNextTarget" class="icon-nextrun" :class="nextTargetClass" @click="clickNextTarget">
                    <span class="form-checkboxgroup">
                      <label>
                        <input type="checkbox" name="cat">
                        <span>次走狙い</span>
                      </label>
                    </span>
                  </div>
              </li>
              <li>
                <span v-if="isFav" class="icon-fav" :class="favClass" @click="clickFav"></span>
              </li>
          </ul>
        </div>
        <div class="photo">
          <slider :thumbnail="horseData.RaceHorseData.Thumbnail"
                  :host="HOST"
                  @imageURL="showImage($event)">
          </slider>
        </div>
      </div>
      <div class="inner">
        <table class="table-grid -data">
          <tr>
            <th>父</th>
            <td>
              <a :href="horseData.RaceHorseDataDetails.FatherURL">{{
                horseData.RaceHorseDataDetails.FatherName
              }}</a>
            </td>
          </tr>
          <tr>
            <th>母</th>
            <td>
              <a :href="horseData.RaceHorseDataDetails.MotherURL">{{
                horseData.RaceHorseDataDetails.MotherName
              }}</a>
            </td>
          </tr>
          <tr>
            <th>母の父</th>
            <td>
              <a :href="horseData.RaceHorseDataDetails.HorseURL_6">{{
                horseData.RaceHorseDataDetails.HorseName_6
              }}</a>
            </td>
          </tr>
          <tr>
            <th>戦績</th>
            <td>{{ horseData.RaceHorseDataDetails.foughtres }}</td>
          </tr>
          <tr v-if="NARFLAG === 0">
            <th>収得賞金</th>
            <td>
              平地:
              {{
                prizeToString(
                  horseData.RaceHorseData.FlatRaceAcquisitionEarnings
                )
              }}
              <br />
              障害:
              {{
                prizeToString(
                  horseData.RaceHorseData.SteeplechaseRaceAcquisitionEarnings
                )
              }}
            </td>
          </tr>
          <tr>
            <th>総賞金</th>
            <td>
              中央：{{ horseData.RaceHorseDataDetails.MainEarnings }}<br />
              地方：{{ horseData.RaceHorseDataDetails.NarMainEarnings }}
            </td>
          </tr>
          <tr>
            <th>調教師</th>
            <td>
              <span v-if="horseData.RaceHorseDataDetails.TrainerName">
                <a v-if="horseData.RaceHorseDataDetails.TrainerURL"
                   :href="horseData.RaceHorseDataDetails.TrainerURL">
                  {{ horseData.RaceHorseDataDetails.TrainerName
                  }}{{ horseData.RaceHorseDataDetails.TrainerGroup }}
                </a>
                <span v-else>
                  {{ horseData.RaceHorseDataDetails.TrainerName
                  }}{{ horseData.RaceHorseDataDetails.TrainerGroup }}
                </span>
              </span>
              <span v-else>――</span>
            </td>
          </tr>
          <tr>
            <th class="horseOwnerInfo">馬主</th>
            <td class="ownerInfo">
              <div class="inner" v-if="horseData.RaceHorseDataDetails.HorseOwnerName">
                <a :href="horseData.RaceHorseDataDetails.HorseOwnerURL">
                  {{ horseData.RaceHorseDataDetails.HorseOwnerName }}
                </a>
                <div class="uniformIcon" v-if="!NARFLAG">
                  <img src="../../assets/images/common/blank_square.png" alt
                       :style="horseData.RaceHorseData.Style">
                </div>
              </div>
              <div class="inner" v-else>
                <span>――</span>
              </div>
            </td>
          </tr>
          <tr>
            <th>生産者</th>
            <td>
              <a v-if="horseData.RaceHorseDataDetails.BreederName"
                 :href="horseData.RaceHorseDataDetails.BreederURL">{{
                horseData.RaceHorseDataDetails.BreederName
              }}</a>
              <span v-else>――</span>
            </td>
          </tr>
          <tr>
            <th>産地</th>
            <td>{{ horseData.RaceHorseDataDetails.BreedingCenter }}</td>
          </tr>
        </table>
      </div>

      <div v-if="banner" v-html="banner"></div>

      <section class="layout-section">
        <div class="inner" v-if="predictedSalesBanner" v-html="predictedSalesBanner"></div>
      </section>

      <div data-tab='["data-trigger", "data-target"]'>
        <div class="nav-tab -fit5">
          <!-- tabの条件分岐 -->
          <ul data-trigger>
            <li
              @click="tabChange('horseRecord')"
              :class="{ '-current': isCurrentTab === 'horseRecord' }"
            >
              <span class="label">競走成績</span>
            </li>
            <li
              @click="tabChange('newInfo')"
              :class="{ '-current': isCurrentTab === 'newInfo' }"
            >
              <span class="label">最新情報</span>
            </li>
            <li v-if="userCourse">
              <router-link :to="userCourse">
                <span class="label">条件実績</span>
              </router-link>
            </li>
            <li
              v-else
              @click="tabChange('achievement')"
              :class="{ '-current': isCurrentTab === 'achievement' }"
            >
              <span class="label">条件実績</span>
            </li>
            <li
              @click="tabChange('time')"
              :class="{ '-current': isCurrentTab === 'time' }"
            >
              <span class="label">持ちタイム</span>
            </li>
            <li
              @click="tabChange('blood')"
              :class="{ '-current': isCurrentTab === 'blood' }"
            >
              <span class="label">血統・他</span>
            </li>
          </ul>
        </div>
          <HorseRecord
              v-if="tab === 'horseRecord' && isMounted"
              :raceResult="raceResult"
              :videoUrlArray="videoUrlArray"
              :PAYINGDATA="PAYINGDATA"
              :courseId="courseId"
              :isNotPremium="isNotPremium"
          />
          <NewInfo
              v-if="tab === 'newInfo' && isMounted"
              :HOST="HOST"
              :PATH="PATH"
              :RACEPATH="RACEPATH"
              :PAYINGDATA="PAYINGDATA"
              :courseId="courseId"
              :news="news"
              :registerInfo="registerInfo"
              :specialEntryData="specialEntryData"
              :pedigreeNum="horsePedigreeNum"
              :nextRacesData="nextRacesData"
              :horseData="horseData?.RaceHorseData"
          />
          <Achievement
              v-if="tab === 'achievement' && isMounted"
              :horseResultData="horseResultData"
              :narHorseResultData="narHorseResultData"
          />
          <Time
              v-if="tab === 'time' && isMounted"
              :bestRecordData="bestRecordData"
              :narBestRecordData="narBestRecordData"
          />
          <Blood
              v-if="tab === 'blood' && isMounted"
              :HOST="HOST"
              :pedigreeStatData="pedigreeStatData"
              :crossDegreeData="crossDegreeData"
              :horseHairColor="horseHairColor"
              :offspringData="offspringData"
              :auctionInfo="auctionInfo"
              :narFlag="NARFLAG"
          />
      </div>
    </section>
    <template v-if="loadingInfo">
      <p v-if="NARFLAG !== 1" class="p-caption -padding">
        ※総賞金・通算成績・条件実績・重賞勝鞍・G1実績・各馬一覧等のデータは開催終了の翌日の更新となります。<br>
      </p>
      <p class="p-caption -padding">
        ※出馬表・結果・成績・オッズなどのデータについては、必ず主催者発表のものと照合し確認してください。<br>
        　<template v-if="NARFLAG === 1">成績集計期間は2020年04月01日以降になります。</template>
      </p>
    </template>
    <ShowImage
      @closeModal="openImage = false"
      :openImage="openImage"
      :imageURL="imageURL">
    </ShowImage>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../assets/css/data/style.scss" scoped></style>
