<script setup>
import {
  ADD,
  changeFavoriteFromCookie,
  FRACEHORSE,
  getJSON,
  getUserId,
  isFavorite,
  REMOVE,
  SUCCESS,
} from "../../../assets/js/common";
import {BACKWORDPATH, HOST, PATH,} from "../../../assets/js/define";
import {computed, ref} from 'vue'

const props = defineProps({
  managementHorses: Array,
  title: String,
  isDisplayFav: Boolean,
});

const count = ref(30);

const listItems = computed(() => {
  const list = props.managementHorses;
  return list.slice(0, count.value);
})

const isMore = () => {
  count.value += 30;
}

const clickFav = async (index) => {
  try {
    const pedigreeNum = props.managementHorses[index].PedigreeNum;
    if (isFavorite(FRACEHORSE, pedigreeNum) === 1) {
      // お気に入り登録済みなので削除する
      const request =
        HOST +
        PATH +
        "MyFavorite/deleteMyFavoriteRacehorse.js" +
        BACKWORDPATH +
        "?" +
        "userID=" +
        encodeURIComponent(getUserId()) +
        "&pedigreeNum=" +
        pedigreeNum;
      const order = REMOVE;
      await getJSON(request, false, function (status, json) {
        if (status !== SUCCESS) {
          alert("お気に入りの削除に失敗しました。");
        }
      });
      const favJudge = await changeFavoriteFromCookie(
        FRACEHORSE,
        pedigreeNum,
        order
      );
      props.managementHorses[index].favClass = favJudge ? "" : "-registed";
    } else {
      // お気に入り未登録なので登録する
      const request =
        HOST +
        PATH +
        "MyFavorite/setMyFavoriteRacehorse.js" +
        BACKWORDPATH +
        "?" +
        "userID=" +
        encodeURIComponent(getUserId()) +
        "&pedigreeNum=" +
        pedigreeNum +
        "&isPush=1";
      const order = ADD;
      await getJSON(request, false, function (status, json) {
        if (status !== SUCCESS) {
          alert("お気に入りの登録に失敗しました。");
        }
      });
      const favJudge = await changeFavoriteFromCookie(
        FRACEHORSE,
        pedigreeNum,
        order
      );
      props.managementHorses[index].favClass = favJudge ? "-registed" : "";
    }
  } catch (e) {
    console.log(e)
  }
}
</script>

<template>
  <div class="item">
    <div class="inner">
      <h2 class="heading-circle">
        {{ title }}
      </h2>
      <div id="graph-course"></div>
      <div v-if="listItems.length">
        <table class="table-grid -padding">
          <tbody v-for="(horse, index) in listItems" :key="index">
          <tr>
            <th colspan="4" :class="horse.SexClass">
              <div class="head">
                <div class="-left">
                  <a :href="horse.HorseNameLink">
                    {{ horse.HorseName }}
                  </a>
                  <i v-if="horse.StablingDiv" :class="horse.StablingDiv"></i>
                </div>
                <div class="-right" v-if="isDisplayFav">
                  <span v-if="horse.PedigreeNum !== '0000000000'"
                        class="icon-fav"
                        :class="horse.favClass"
                        @click="clickFav(index)">
                  </span>
                </div>
              </div>
            </th>
          </tr>
          <tr>
            <td>{{ horse.BirthYear }}年生<br/>{{ horse.HairColor }}</td>
            <td class="-center">{{ horse.Sex }}{{ horse.Age }}</td>
            <td>父:{{ horse.FatherName }}<br/>母:{{ horse.MotherName }}</td>
            <td>
              <div>中央：{{ horse.JraMainEarnings }}</div>
              <div>地方：{{ horse.NarMainEarnings }}</div>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="nav-group">
        <span v-if="(listItems.length - count) >= 0" class="btn-basic" @click="isMore">
          もっと見る
        </span>
        </div>
      </div>
      <div v-else>管理馬情報はありません。</div>
    </div>
  </div>
</template>

<style lang="scss" src="../../../assets/css/data/style.scss" scoped></style>