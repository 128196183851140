<template>
  <div class="container-tabtarget" data-target>
    <div class="item">
      <div
        v-if="!isNar && courseId !== PAYINGDATA.PAYINGSUGOTOKU"
        class="inner"
      >
        <div class="layout-title -marginbottom">
          <h4 class="heading-circle">
            次走候補
            <template v-if="horseData?.RaceTypeCd">
              <router-link :to="`/program?RaceTypeCd=${horseData?.RaceTypeCd}&RaceRegulationCd=${horseData?.RaceRegulationCd}`"
                           class="btn-basic">
                番組表へ
              </router-link>
            </template>
          </h4>
        </div>

        <template v-if="nextRacesData.length > 0">
          <next-race-info :nextRacesData="nextRacesData"
                          :PAYINGDATA="PAYINGDATA"
                          :courseId="courseId">
          </next-race-info>
        </template>

        <template v-else>
          <ul>
            <li>次走情報はありません。</li>
          </ul>
        </template>
      </div>
      <div class="inner">
        <h2 class="heading-circle">
          <span v-if="specialEntryData.SpecialEntryDiv * 1 === 1">
            出走確定情報
          </span>
          <span v-else>
            特別レース登録情報
          </span>
        </h2>
        <div
          v-if="
            specialEntryFullData && Object.keys(specialEntryFullData).length > 0
          "
        >
          <a
            v-for="specialRow in specialEntryFullData"
            :key="specialRow.DOR"
            :href="specialRow.specialEntryURL"
            class="btn-basic -wide -race"
          >
            <div class="title">
              <div class="text">
                {{ specialRow.Racetrack }}
                {{ specialRow.RaceNum > 9 ? specialRow.RaceNum : specialRow.RaceNum.slice(1) }}R　
                {{specialRow.RaceMainName }}
              </div>
              <div class="grade">
                <span :class="specialRow.gradeIcon"></span>
              </div>
            </div>
            <span class="caption">{{ specialRow.DOR }}</span>
          </a>
        </div>
        <div v-else>
          <span v-if="isNar">出走情報はまだありません。</span>
          <span v-else>今週の出走情報はまだありません。</span>
        </div>
      </div>

      <div class="inner">
        <h2 class="heading-circle">関連ニュース</h2>
        <div v-if="news.length">
          <ul class="list-news">
            <li v-for="(item, index) in news" :key="index" class="item">
              <div class="summary">
                <a :href="item.url" class="title">{{ item.Headline }}</a>
                <time class="caption">{{ item.DeliveryDate }} 更新</time>
              </div>
              <div class="thumb">
                <img
                    v-if="item.ImageFileURL"
                    src="../../../assets/images/common/blank_square.png"
                    alt=""
                    :style="{ 'background-image': 'url(' + item.ImageFileURL + ')' }"
                />
              </div>
            </li>
          </ul>
        </div>
        <div v-else>
          <ul>
            <li>関連ニュースはありません。</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getGradeIconClass, getGradeIconClassNar, isNar} from "../../../assets/js/common";
import nextRaceInfo from "./NextRaceInfo.vue";

export default {
  data() {
    return {
      specialEntryTitle: "特別レース登録情報",
      specialEntryFullData: [],
      isWebView: false,
      isNar: null,
    };
  },
  components: { nextRaceInfo },
  props: {
    HOST: String,
    PATH: String,
    RACEPATH: String,
    PAYINGDATA: Object,
    courseId: String,
    news: Object,
    registerInfo: Object,
    specialEntryData: Object,
    pedigreeNum: String,
    nextRacesData: Array,
    horseData: Array,
  },
  created() {
    const self = this;
    self.isNar = isNar() === 1

    setSpecialEntry(
        self.specialEntryData.SpecialEntry,
        self.specialEntryData.SpecialEntryDiv
    );

    //日付フォーマット取得関数（例：yyyy.mm.dd (曜日) ）
    function getDisplayDateStr_DOT(yyyymmdd) {
      const DAY_LBL = ["日", "月", "火", "水", "木", "金", "土"];
      const month = parseInt(yyyymmdd.slice(4, 6), 10);
      const date = parseInt(yyyymmdd.slice(6, 8), 10);
      const mDate = new Date(
        parseInt(yyyymmdd.slice(0, 4), 10),
        month - 1,
        date
      );

      return (
        yyyymmdd.slice(0, 4) +
        "." +
        month +
        "." +
        date +
        "(" +
        DAY_LBL[mDate.getDay()] +
        ")"
      );
    }

    function setSpecialEntry(json, flag) {
      if (flag * 1 === 1) {
        //出走確定済みの場合は見出しの変更
        self.specialEntryTitle = "出走確定情報";
      }

      const commonUrl = self.HOST + self.RACEPATH + "card"; //出馬表用URL

      let specialData = [];
      for (let n = 0; n < self.specialEntryData.SpecialEntry.length; n++) {
        const se = self.specialEntryData.SpecialEntry[n];

        let gradeIcon = "";
        if (self.isNar) {
          gradeIcon = getGradeIconClassNar(se.GradeCd, se.RaceMainName)
        } else {
          gradeIcon = getGradeIconClass(se.GradeCd, se.TrackTypeCd, se.RaceRegulationCd)
        }

        //URL生成
        const url =
          commonUrl +
          "?DOR=" +
          se.DOR +
          "&RacetrackCd=" +
          se.RacetrackCd +
          "&RaceNum=" +
          se.RaceNum;

        specialData.push({
          specialEntryURL: url,
          Racetrack: se.Racetrack,
          RaceNum: se.RaceNum,
          RaceMainName: se.RaceMainName,
          DOR: getDisplayDateStr_DOT(se.DOR),
          gradeIcon: gradeIcon,
        });
      }
      self.specialEntryFullData = specialData;
    }
  },
};
</script>

<style lang="scss" src="../../../assets/css/data/style.scss" scoped></style>
