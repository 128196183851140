import {
  getMenmerCourse,
  PAYINGNONE,
  PAYINGSUGOTOKU_NONE,
  SUCCESS,
  getJSON,
  getUserAgent,
  getNonMemberId,
  isAppNonmember,
} from '../../assets/js/common';
import { HOST, PATH, BACKWORDPATH } from "../../assets/js/define";
export default function () {
  // //出馬表など更新可能ページ用
  let param;
  const isSkipUser = () => {
    return getMenmerCourse() == PAYINGSUGOTOKU_NONE || (getMenmerCourse() == PAYINGNONE && !getUserAgent());
  }
  const loadMyMarkForDrop2 = async (userid, dor, racetrackcd, racenum) => {
    if (isSkipUser()) return param;
    const userId = isAppNonmember() ? getNonMemberId() : userid;

    var req =
      HOST +
      PATH +
      "RaceInfo/getMyMarks.js" +
      BACKWORDPATH +
      "?userid=" +
      encodeURIComponent(userId) +
      "&dor=" +
      dor +
      "&racetrackcd=" +
      racetrackcd +
      "&racenum=" +
      racenum;
    await getJSON(req, true, function (status, json) {
      if (status == SUCCESS) {
        param = json;
      }
    });
    return param;
  };

  const setMyMark = (userid, dor, racetrackcd, racenum, id, marknum) => {
    if (!isSkipUser()) {
      const userId = isAppNonmember() ? getNonMemberId() : userid;
      var req =
        HOST +
        PATH +
        "RaceInfo/setMyMarks.js" +
        BACKWORDPATH +
        "?userid=" +
        encodeURIComponent(userId) +
        "&dor=" +
        dor +
        "&racetrackcd=" +
        racetrackcd +
        "&racenum=" +
        racenum +
        "&myno" +
        id +
        "=" +
        String(marknum);
      document.body.className = "";
      getJSON(req, true, function (status, json) {
        document.body.className = "status-loaded";
      });
    } else {
      document.body.className = "status-loaded";
    }
  };

  const setMyMarkForSpecial = (userid, dor, racetrackcd, racenum, pnum , marknum) => {
    if (!isSkipUser()) {
      const userId = isAppNonmember() ? getNonMemberId() : userid;
      const req =
          HOST +
          PATH +
          "RaceInfo/setMyMarksForSpecial.js" +
          BACKWORDPATH +
          "?userid=" + encodeURIComponent(userId) +
          "&dor=" + dor +
          "&racetrackcd=" + racetrackcd +
          "&racenum=" + racenum +
          "&pedigreenum=" + pnum +
          "&myno=" + String(marknum);
      document.body.className = "";
      getJSON(req, true, function (status, json) {
        document.body.className = "status-loaded";
      });
    } else {
      document.body.className = "status-loaded";
    }
  };

  return {
    loadMyMarkForDrop2,
    setMyMark,
    setMyMarkForSpecial,
  };
}
