import axios from "axios";
import {
  getJSON,
  SUCCESS,
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
  PAYINGNONE,
  PAYINGPREMIUM,
  PAYINGAPPPASS,
  PAYINGPACK03,
  PAYINGPACK06,
  PAYINGPACK12,
  getMenmerCourse,
  calcBracketNum,
  getCookie,
  USERTYPE,
  DATETYPE,
  DEFAULT_RACETRACKCD,
  replaceComma,
  isNar,
} from "../../assets/js/common";
import {
  JRA_WP_HOST,
  HOST,
  PATH,
  BACKWORDPATH,
  DATAPATH,
  NEWSPATH,
  RACEPATH,
  NEWSIMAGEPATH,
  MEDIAPATH,
  DEBUG,
} from "../../assets/js/define";

let KAIJO = {
  "01": "札幌",
  "02": "函館",
  "03": "福島",
  "04": "新潟",
  "05": "東京",
  "06": "中山",
  "07": "中京",
  "08": "京都",
  "09": "阪神",
  10: "小倉",
  30: "門別",
  31: "北見",
  32: "岩見",
  33: "帯広",
  34: "旭川",
  35: "盛岡",
  36: "水沢",
  37: "上山",
  38: "三条",
  39: "足利",
  40: "宇都",
  41: "高崎",
  42: "浦和",
  43: "船橋",
  44: "大井",
  45: "川崎",
  46: "金沢",
  47: "笠松",
  48: "名古屋",
  49: "紀三",
  50: "園田",
  51: "姫路",
  52: "益田",
  53: "福山",
  54: "高知",
  55: "佐賀",
  56: "荒尾",
  57: "中津",
  58: "札幌",
  59: "函館",
  60: "新潟",
  61: "中京",
  A0: "他外",
  A2: "日本",
  A4: "アメ",
  A6: "イギ",
  A8: "フラ",
  B0: "イン",
  B2: "アイ",
  B4: "ニュ",
  B6: "オー",
  B8: "カナ",
  C0: "イタ",
  C2: "ドイ",
  C5: "オマ",
  C6: "イラ",
  C7: "(ア)",
  C8: "シリ",
  D0: "スウ",
  D2: "ハン",
  D4: "ポル",
  D6: "ロシ",
  D8: "ウル",
  E0: "ペル",
  E2: "アル",
  E4: "ブラ",
  E6: "ベル",
  E8: "トル",
  F0: "韓国",
  F1: "中国",
  F2: "チリ",
  F8: "パナ",
  G0: "香港",
  G2: "スペ",
  H0: "西独",
  H2: "南ア",
  H4: "スイ",
  H6: "モナ",
  H8: "フィ",
  I0: "プエ",
  I2: "コロ",
  I4: "チェ",
  I6: "チェ",
  I8: "スロ",
  J0: "エク",
  J2: "ギリ",
  J4: "マレ",
  J6: "メキ",
  J8: "モロ",
  K0: "パキ",
  K2: "ポー",
  K4: "パラ",
  K6: "サウ",
  K8: "キプ",
  L0: "タイ",
  L2: "ウク",
  L4: "ベネ",
  L6: "ユー",
  L8: "デン",
  M0: "シン",
  M2: "澳門",
  M4: "墺",
  M6: "ヨル",
};
const COMMON_JOCKEY_URL = HOST + "data/jockey"; //分析用URL

//TOPレース情報描画関数（レース開催中用）
export function loadTodayRaceInfo(dor, json, json2, json3, jsonChg, sMarks) {
  let data = {};
  if (json == null) {
    return;
  }

  let COMMON_RACE_LIST_URL = HOST + "race"; //その他レース一覧用URL
  let COMMON_PAYOUT_URL = HOST + "race/payout"; //払戻金一覧用URL
  let COMMON_ANALYSIS_URL = HOST + "race/analysis"; //分析用URL

  let user = getCookie(USERTYPE);

  try {
    let premiumMark = "";
    if (
      PAYINGPREMIUM === user ||
      PAYINGSUGOTOKU === user ||
      PAYINGPACK03 === user ||
      PAYINGPACK06 === user ||
      PAYINGPACK12 === user
    ) {
      //premium
      //プレミアム会員の場合のプレミアムマークのセット
      premiumMark = "";
    } else {
      premiumMark = " pay-s";
    }
    let mDateArray = [];
    let defaultRaceUrlArray = []

    json["TargetRaces"].forEach((race) => {
      mDateArray.push({ DOR: race.DOR });
    });

    // //日付タブの描画
    // //日付タブ以外に、変更情報一覧ボタン（とリンク）、その他レース一覧ボタン（とリンク）のセットも行う
    //念のため日付順でソート
    mDateArray.sort(function (a, b) {
      a = a["DOR"];
      b = b["DOR"];
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });
    //開催年月日タブと表示番号の設定
    let dayTab = {};
    for (let i = 0; i < mDateArray.length; i++) {
      dayTab[mDateArray[i].DOR] = i + 1;
      mDateArray[i].date = getDisplayDateStrForMain(mDateArray[i].DOR);
    }

    data.mDateArray = mDateArray;
    data.raceList = {};
    data.raceTrend = {};
    data.oddsObj = {};
    data.placeTab = {};

    for (let i = 0; i < mDateArray.length; i++) {
      //描画
      //開催年月日タブ表示番号の取得
      let mIndex = dayTab[mDateArray[i].DOR];
      data.raceList[mDateArray[i].DOR] = {};
      data.raceTrend[mDateArray[i].DOR] = {};
      data.oddsObj[mDateArray[i].DOR] = {};
      data.placeTab[mDateArray[i].DOR] = [];

      //タブの取得と表示番号の設定
      let raceArray = json["TargetRaces"].find((races) => {
        return races.DOR === mDateArray[i].DOR;
      });
      let mRacetrackArray = raceArray.Races;
      let placeTab = {};
      for (let i = 0; i < mRacetrackArray.length; i++) {
        placeTab[mRacetrackArray[i].RacetrackCd] = i + 1;
      }

      if (isNar() === 1) {
        const sortArray = [44, 45, 43, 42, 30, 31, 32, 34, 35, 36, 37, 37, 38, 38, 40, 41, 46, 47, 48, 49, 50,
          51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 33];

        mRacetrackArray.sort((x, y) => {
          return sortArray.indexOf(Number(x.RacetrackCd)) - sortArray.indexOf(Number(y.RacetrackCd));
        })
      } else {
        mRacetrackArray.sort(function (a, b) {
          a = a["RacetrackCd"];
          b = b["RacetrackCd"];

          let aconv = a;
          let bconv = b;
          if (mRacetrackArray.some(val => val.RacetrackCd === "05" ||  val.RacetrackCd === "06")) {
            //札幌：01→21、函館：02→22、福島：03→13、新潟：04→14、中京：07→17、小倉：10→20
            if (a == "01") aconv = "21";
            if (a == "02") aconv = "22";
            if (a == "03") aconv = "13";
            if (a == "04") aconv = "14";
            if (a == "07") aconv = "17";
            if (a == "10") aconv = "20";

            if (b == "01") bconv = "21";
            if (b == "02") bconv = "22";
            if (b == "03") bconv = "13";
            if (b == "04") bconv = "14";
            if (b == "07") bconv = "17";
            if (b == "10") bconv = "20";
          } else {
            //札幌：01→21、函館：02→22、中京：07→17、小倉：10→20
            if (a == "01") aconv = "21";
            if (a == "02") aconv = "22";
            if (a == "07") aconv = "17";
            if (a == "10") aconv = "20";

            if (b == "01") bconv = "21";
            if (b == "02") bconv = "22";
            if (b == "07") bconv = "17";
            if (b == "10") bconv = "20";
          }
          if (aconv < bconv) return -1;
          if (aconv > bconv) return 1;
          return 0;
        });
        defaultRaceUrlArray.push({
          dor: mDateArray[i].DOR,
          raceTrackCd: getDefaultRace(mDateArray[i].DOR, mRacetrackArray, json2, json3)
        })
      }
      for (let j = 0; j < mRacetrackArray.length; j++) {
        //描画
        let keys = Object.keys(json3);
        let oddsObj = null;
        let compare = mDateArray[i].DOR + mRacetrackArray[j].RacetrackCd;
        let oddUpdInfo = "";
        for (let k = 0; k < keys.length; k++) {
          if (compare === keys[k].slice(1, 11)) {
            oddsObj = json3["o" + keys[k].slice(1)];
            break;
          }
        }
        if (oddsObj != null && oddsObj.length > 0) {
          //オッズ更新時刻の表示
          oddUpdInfo = oddsObj[0].OddsUpdInfo;
        }

        //競馬場タブの登録
        let thisConditionData = loadWeatherAndTrackCondition(
          mRacetrackArray[j].RacetrackCd,
          json2["w" + mDateArray[i].DOR]
        );
        data.placeTab[mDateArray[i].DOR].push({
          RacetrackCd: mRacetrackArray[j].RacetrackCd,
          Racetrack: mRacetrackArray[j].Racetrack,
          TrackConditionCdDirt: thisConditionData
            ? thisConditionData.TrackConditionCdDirt
            : "",
          TrackConditionCdTurf: thisConditionData
            ? thisConditionData.TrackConditionCdTurf
            : "",
          Weather: thisConditionData ? thisConditionData.Weather : "",
          isNighter: mRacetrackArray[j].isNighter,
        });

        data.oddsObj[mDateArray[i].DOR][mRacetrackArray[j].RacetrackCd] =
          oddUpdInfo;
        //競馬場タブ表示番号の取得
        let mTrackIndex = placeTab[mRacetrackArray[j].RacetrackCd];
        let raceListUrl =
          COMMON_RACE_LIST_URL +
          "?DOR=" +
          mDateArray[i].DOR +
          "&RacetrackCd=" +
          mRacetrackArray[j].RacetrackCd;
        let analysisUrl =
          COMMON_ANALYSIS_URL +
          "?DOR=" +
          mDateArray[i].DOR +
          "&RacetrackCd=" +
          mRacetrackArray[j].RacetrackCd;
        let payoutUrl =
          COMMON_PAYOUT_URL +
          "?DOR=" +
          mDateArray[i].DOR +
          "&RacetrackCd=" +
          mRacetrackArray[j].RacetrackCd;
        data.mainrace = {
          payoutUrl: payoutUrl,
          raceListUrl: raceListUrl,
          analysisUrl: analysisUrl,
          mIndex: mIndex,
          mTrackIndex: mTrackIndex,
          raceStudy:
            user === PAYINGNONE || user === PAYINGSUGOTOKU_NONE ? true : false,
          premiumMark: premiumMark,
          mark: user === PAYINGAPPPASS ? true : false,
          sugotoku: user === PAYINGSUGOTOKU_NONE ? true : false,
        };
        //レース情報描画
        const raceList = loadRaceList(
          {
            DOR: mDateArray[i].DOR,
            RacetrackCd: mRacetrackArray[j].RacetrackCd,
          },
          mIndex,
          mTrackIndex,
          json2["r" + mDateArray[i].DOR + mRacetrackArray[j].RacetrackCd],
          json3,
          sMarks
        );
        data.raceList[mDateArray[i].DOR][mRacetrackArray[j].RacetrackCd] =
          raceList;

        //本日のレース傾向描画
        const raceTrend = loadRaceTrend(
          {
            DOR: mDateArray[i].DOR,
            RacetrackCd: mRacetrackArray[j].RacetrackCd,
          },
          mIndex,
          mTrackIndex,
          json2["r" + mDateArray[i].DOR + mRacetrackArray[j].RacetrackCd],
          json3
        );
        data.raceTrend[mDateArray[i].DOR][mRacetrackArray[j].RacetrackCd] =
          raceTrend;
        }
    }
    data.defaultRaceUrlArray = defaultRaceUrlArray;
  } catch (e) {
    if (DEBUG) alert("Error|loadTodayRaceInfo : " + e);
    console.log(e)
  }

  return data;
}

//TOPレース情報描画関数（レース非開催日用）
export function loadTodayRaceInfoVer2(dor, json, json2, json3, jsonChg) {
  let data = {};
  if (json === null) {
    return;
  }

  const COMMON_CHANGE_INFO_LIST_URL = HOST + "info"; //変更情報一覧用URL
  const COMMON_RACE_LIST_URL = HOST + "race"; //レース一覧用URL

  try {
    let mDateArray = [];

    json["TargetRaces"].forEach((race) => {
      mDateArray.push({ DOR: race.DOR });
    });

    // //日付タブの描画
    // //日付タブ以外に、変更情報一覧ボタン（とリンク）、その他レース一覧ボタン（とリンク）のセットも行う
    //念のため日付順でソート
    mDateArray.sort(function (a, b) {
      a = a["DOR"];
      b = b["DOR"];
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });

    //開催年月日タブと表示番号の設定
    let dayTab = {};
    for (let i = 0; i < mDateArray.length; i++) {
      dayTab[mDateArray[i].DOR] = i + 1;
      mDateArray[i].date = getDisplayDateStrForMain(mDateArray[i].DOR);
    }

    let mRacetrackArray = json["TargetRacetrackCd"];

    //競馬場タブと表示番号の設定
    let placeTab = {};
    for (let i = 0; i < mRacetrackArray.length; i++) {
      placeTab[mRacetrackArray[i].RacetrackCd] = i + 1;
    }

    data.mDateArray = mDateArray;
    data.raceList = [];

    for (let i = 0; i < mDateArray.length; i++) {
      let defaultRaceUrl = getCookie(DEFAULT_RACETRACKCD);
      let raceListAllUrl =
        COMMON_RACE_LIST_URL +
        "?DOR=" +
        mDateArray[i].DOR +
        "&RacetrackCd=" +
        defaultRaceUrl;
      let changeFlg = 0; //0: 月~水 1:木金,変更なし 2:木金,変更あり
      let changeMsg = "";
      let changeURL = "";
      let changeTime = "";

      //開催年月日タブ表示番号の取得
      let mIndex = dayTab[mDateArray[i].DOR];

      if (/^2/.test(json.DateType)) {
        //木・金の場合のみ設定
        //変更情報一覧ボタンの生成
        if (Array.isArray(jsonChg) && jsonChg.filter(c => c.Contents).length > 0) {
          changeFlg = 2;
          let change = loadChangeInfo(mDateArray[i].DOR, mIndex, jsonChg);
          if (change.length > 0) {
            changeMsg = change[0].Contents;
          }
          changeURL = COMMON_CHANGE_INFO_LIST_URL + "?dor=" + mDateArray[i].DOR;
          changeTime = new Date();
        } else {
          changeFlg = 1;
          changeURL = COMMON_CHANGE_INFO_LIST_URL + "?dor=" + mDateArray[i].DOR;
          changeTime = new Date();
        }
      }

      let raceArray = new Array();
      for (let j = 0; j < mRacetrackArray.length; j++) {
        //描画
        //競馬場タブ表示番号の取得
        let mTrackIndex = placeTab[mRacetrackArray[j].RacetrackCd];

        //競馬場タブの登録
        let thisConditionData = loadWeatherAndTrackCondition(
          mRacetrackArray[j].RacetrackCd,
          json2["w" + mDateArray[i].DOR]
        );
        let thisRaceData = loadRaceList(
          {
            DOR: mDateArray[i].DOR,
            RacetrackCd: mRacetrackArray[j].RacetrackCd,
          },
          mIndex,
          mTrackIndex,
          json2["r" + mDateArray[i].DOR + mRacetrackArray[j].RacetrackCd],
          json3
        );
        if (!thisRaceData) continue;
        thisRaceData.condition = thisConditionData;
        thisRaceData.symbol = innerRaceSymbol(
          thisRaceData.mainRace.RaceSymbolCd
        );

        //レース情報の取得～描画
        raceArray.push(thisRaceData);
      }

      data.raceList.push({
        DOR: mDateArray[i].DOR,
        date: mDateArray[i].date,
        raceURL: raceListAllUrl,
        changeData: {
          changeFlg: changeFlg,
          changeMsg: changeMsg,
          changeURL: changeURL,
          changeTime: changeTime,
        },
        races: raceArray,
      });
    }
  } catch (e) {
    if (DEBUG) alert("Error|loadTodayRaceInfoVer2 : " + e);
  }
  return data;
}

//競馬場別天候馬場状態描画関数
const loadWeatherAndTrackCondition = function (racetrackCd, json) {
  if (
    json == null ||
    !("WeatherAndTrackCondition" in json) ||
    json.WeatherAndTrackCondition == null
  ) {
    return;
  }
  let data = [];
  let weatherArray = json.WeatherAndTrackCondition;
  for (let i = 0; i < weatherArray.length; i++) {
    //描画
    if (weatherArray[i].RacetrackCd === racetrackCd) {
      return weatherArray[i];
    }
  }

  return data;
};

//出馬表取得関数
const loadCandidacyTable = function (dayIndex, trackIndex, json, json2) {
  //e o
  if (
    json == null ||
    !("EntryHorses" in json) ||
    json.EntryHorses == null ||
    json.EntryHorses.length == 0
  ) {
    return [];
  } //データがない場合は終了
  if (
    json2 == null ||
    json2.length == 0 ||
    !("EntryNum" in json2[0]) ||
    json2[0].EntryNum == null
  ) {
    return [];
  } //データがない場合は終了

  let horseArray = json.EntryHorses;
  let odds = json2[0];

  let COMMON_HORSE_DATA_URL = HOST + DATAPATH + "horse"; //競走馬データURL
  let PLACE_LBL = ["icon-1st", "icon-2nd", "icon-3rd"];
  let horses = [];
  for (let i = 0; i < odds.EntryNum; i++) {
    //描画
    if ("---.-" === odds["Odds" + (i + 1)] || "0.0" === odds["Odds" + (i + 1)]) {
      continue;
    }
    //horses.push({ key: i, value: 0 });
    horses.push({ key: i, value: Number(odds["Odds" + (i + 1)]) });
  }
  //オッズ昇順に並べ替え
  horses.sort(function (a, b) {
    a = a["value"];
    b = b["value"];
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });

  let data = [];

  for (let i = 0; i < 3; i++) {
    try {
      //描画
      if (horses.length <= 0) {
        continue;
      }
      let iconColor;
      if (horses[i].value * 1 < 10) {
        //10倍未満のときは赤文字
        iconColor = '<span class="txt-odds -no1">';
      } else if (horses[i].value * 1 < 100) {
        //100倍未満のときは青文字
        iconColor = '<span class="txt-odds -no2">';
      } else {
        //それ以外は黒文字
        iconColor = '<span class="txt-odds -other">';
      }

      let dataUrl =
        COMMON_HORSE_DATA_URL +
        "?PedigreeNum=" +
        horseArray[horses[i].key].PedigreeNum;

      data.push({
        dataUrl: dataUrl,
        label: PLACE_LBL[i],
        HorseNum: horseArray[horses[i].key].HorseNum,
        BracketNum: horseArray[horses[i].key].BracketNum,
        key: horses[i].key + 1,
        horseName: horseArray[horses[i].key].HorseName,
        iconColor: iconColor + String(horses[i].value.toFixed(1)) + "倍</span>",
        value: horses[i].value,
      });
    } catch (err) {
      data.push({
        dataUrl: '',
        label: '',
        HorseNum:'',
        BracketNum:'',
        key:'',
        horseName:'',
        iconColor:'',
        value:''
      });
      continue
    }
  }

  return data;
};

//レース一覧取得＆描画関数
const loadRaceList = function (
  race,
  dayIndex,
  trackIndex,
  json,
  json3,
  sMarks
) {
  let data = {};
  if (json == null || json.length == 0) {
    //データがない場合は終了
    return null;
  }

  let COMMON_ENTRY_LIST_URL = HOST + RACEPATH + "card"; //出馬表用URL
  let COMMON_RACE_RESULT = HOST + RACEPATH + "results"; //レース結果用URL

  //もっともグレードの高いレースを取得
  //初期値として11Rをセットします
  let mainRace = 0;
  let mKey = "";
  let compare = race.DOR + race.RacetrackCd;

  let keys = Object.keys(json3);
  for (let i = 0; i < keys.length; i++) {
    if (compare === keys[i].slice(1, 11)) {
      const raceNum = keys[i].slice(-2);
      mainRace = json.findIndex(race => race.RaceNum === raceNum);
      mKey = keys[i].slice(1);
      break;
    }
  }

  json.length === 1 ? mainRace = 0: ''

  data.race = [];

  //スライド画像リンクの設定
  for (let i = 0; i < json.length; i++) {
    //#4315 同じ会場で重賞レースが1日に2回以上開催された場合に対応
    if (
      json[i].GradeCd == "A" ||
      json[i].GradeCd == "B" ||
      json[i].GradeCd == "C" ||
      json[i].GradeCd == "D"
    ) {
      let bannerHref = json[mainRace].ResultFlg
        ? COMMON_RACE_RESULT
        : COMMON_ENTRY_LIST_URL;
      bannerHref +=
        "?DOR=" +
        race.DOR +
        "&RacetrackCd=" +
        race.RacetrackCd +
        "&RaceNum=" +
        json[i].RaceNum;

      data.race.push({
        DOR: race.DOR,
        RacetrackCd: race.RacetrackCd,
        RaceNum: json[i].RaceNum,
        bannerHref: bannerHref,
        place: KAIJO[race.RacetrackCd],
      });
    }
  }

  //レース開始時刻
  json[mainRace].StartTimeShow =
    json[mainRace].StartTime === "0000"
      ? "--:--"
      : json[mainRace].StartTime.slice(0, 2) * 1 +
        ":" +
        json[mainRace].StartTime.slice(2, 4);

  json[mainRace].DistanceShow = replaceComma(json[mainRace].Distance);
  data.mainRace = json[mainRace];

  //最後に出馬表からオッズを表示
  let raceData = {
    DOR: race.DOR,
    RacetrackCd: race.RacetrackCd,
    RaceNum: json[mainRace].RaceNum,
  };
  const candidacyTable = loadCandidacyTable(
    dayIndex,
    trackIndex,
    json3["e" + mKey],
    json3["o" + mKey]
  );
  data.candidacyTable = candidacyTable;

  //オッズ、近走成績リンク設定
  const mainRaceLinks = setMainRaceLinks(raceData, dayIndex, trackIndex);
  data.mainRaceLinks = mainRaceLinks;

  return data;
};

//レース傾向描画関数
function loadRaceTrend(race, dayIndex, trackIndex, json, json3) {
  let mLst = [],
    mKey = "";
  let keys = Object.keys(json3);
  for (let i = 0; i < keys.length; i++) {
    if (/^t$/i.test(keys[i].slice(0, 1))) {
      if (keys[i].slice(1, 11) == race.DOR + race.RacetrackCd) {
        mLst = json3[keys[i]];
        mKey = keys[i].slice(1);
        break;
      }
    }
  }

  if (mLst.length == 0) {
    return;
  }

  //前回の傾向ボタンのリンク設定
  let dor = mKey.slice(0, 8);
  let racetrackCd = mKey.slice(8, 10);
  let raceMeeting = mKey.slice(10, 12);
  let raceDay = mKey.slice(12, 14);
  let mHref =
    HOST +
    "info/trends?DOR=" +
    dor +
    "&RacetrackCd=" +
    racetrackCd +
    "&RaceMeeting=" +
    raceMeeting +
    "&RaceDay=" +
    raceDay +
    "&isPre=1";

  let data = {};
  data.mHref = mHref;
  data.trend = []
  data.jockeyArray = mLst.JockeyList
  data.studArray = mLst.StudList

  if (mLst.TrendList && mLst.TrendList.length > 0) {
    const raceInfos = mLst.TrendList;

    //レース昇順にソート
    raceInfos.sort(function (a, b) {
      a = a["RaceNum"];
      b = b["RaceNum"];
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });
    let trendMax = 3;
    if (!(getMenmerCourse() == PAYINGNONE || getMenmerCourse() == PAYINGSUGOTOKU_NONE)) {
      trendMax = raceInfos.length;
    } else {
      if (raceInfos.length < 3) {
        trendMax = raceInfos.length;
      }
    }
    if (trendMax >= 12) {
      trendMax = raceInfos.length;
    }
    let raceInfoArray = [];
    for (let i = 0; i < trendMax; i++) {
      let isCancel = false;
      if (raceInfos[i].Status == 2) {
        //レース中止の場合
        isCancel = true;
      }
      //レース番号
      const raceNum = parseInt(raceInfos[i].RaceNum, 10);

      //天気
      const weatherCd = raceInfos[i].WeatherCd || "";

      // 人気
      const popular = raceInfos[i].Popular;

      //距離
      let distance = "";
      let trackType = "";
      if (raceInfos[i].TrackType) {
        distance += raceInfos[i].TrackType.slice(0, 1);
        //レース番号のクラス設定
        if (raceInfos[i].TrackType === "芝") {
          trackType = "-turf";
        } else if (raceInfos[i].TrackType === "ダート") {
          trackType = "-dart";
        } else if (raceInfos[i].TrackType === "障害") {
          trackType = "-hurdle";
        }
      }

      if (raceInfos[i].Distance) {
        distance += raceInfos[i].Distance + "m";
      }

      //馬場状態
      let condition;
      if (raceInfos[i].TrackConditionCdTurf) {
        condition = raceInfos[i].TrackConditionCdTurf;
      }
      if (raceInfos[i].TrackConditionCdDirt) {
        condition = raceInfos[i].TrackConditionCdDirt;
      }

      //馬番
      let horseNum;
      let horseNumColor;
      if (raceInfos[i].HorseNum) {
        horseNum = raceInfos[i].HorseNum;
        horseNumColor =
            "icon-umaban -waku" +
            0 +
            calcBracketNum(
                raceInfos[i].EntryNum,
                raceInfos[i].HorseNum
            );
      }
      //コーナー通過順
      let corneringOrder = raceInfos[i].CorneringOrder || "";

      // JRA
      //ペース/決め手
      let paceStr = "";
      let paceColor;
      if (isNar() === 0 && raceInfos[i].Pace) {
        if (raceInfos[i].Pace === "H") {
          paceStr += raceInfos[i].Pace;
          paceColor = "text-pace -high";
        } else if (raceInfos[i].Pace === "M") {
          paceStr += raceInfos[i].Pace;
          paceColor = "text-pace -middle";
        } else if (raceInfos[i].Pace === "S") {
          paceStr += raceInfos[i].Pace;
          paceColor = "text-pace -slow";
        }
      }

      //決め手
      const clincher = raceInfos[i].RunningStyle;

      //勝利騎手
      const jockeyName = raceInfos[i].JockeyName;
      //騎手名太字
      const nameBold = mLst.JockeyList.includes(raceInfos[i].JockeyCd);
      //勝利騎手のリンク先
      let jockeyNameUrl = raceInfos[i].JockeyCd ?
        "/data/jockey?JockeyCd=" + raceInfos[i].JockeyCd : "";

      //騎手傾向
      const jockeyData = raceInfos[i].JockeyData

      //種牡馬傾向
      const studData = raceInfos[i].StudData

      const raceInfo = {
        raceNum,
        weatherCd,
        distance,
        popular,
        trackType,
        condition,
        paceStr,
        clincher,
        corneringOrder,
        horseNum,
        paceColor,
        horseNumColor,
        isCancel,
        jockeyName,
        jockeyNameUrl,
        nameBold,
        jockeyData,
        studData,
      };

      const exist = raceInfoArray.filter(race => {
        return raceInfo.raceNum === race.raceNum
      })

      if (exist.length) continue
      raceInfoArray.push(raceInfo);
    }
    data.trend = raceInfoArray;
  }

  return data;
}

//オッズ、近走成績リンクセット関数：ph2
const setMainRaceLinks = function (raceData, dayIndex, trackIndex) {
  let COMMON_ODDS_URL = HOST + RACEPATH + "odds/win"; //オッズページ用URL
  let COMMON_LATEST_RESULT_URL = HOST + RACEPATH + "card"; //近走成績用URL
  let mQuery =
    "?DOR=" +
    raceData.DOR +
    "&RacetrackCd=" +
    raceData.RacetrackCd +
    "&RaceNum=" +
    raceData.RaceNum;

  const data = {
    COMMON_ODDS_URL: COMMON_ODDS_URL,
    COMMON_LATEST_RESULT_URL: COMMON_LATEST_RESULT_URL,
    mQuery: mQuery,
  };

  return data;
};
//変更情報取得＆描画関数
export const loadChangeInfo = function (date, dayIndex, json) {
  if (json === null) {
    return;
  }
  let data = new Array();
  for (let i = 0; i < json.length; i++) {
    if (date != json[i].DOR) {
      continue;
    }
    json[i].Contents = json[i].Contents.replaceAll("<br/>", "、")
    data.push(json[i]);
  }
  return data;
};

//ニュースヘッドライン取得し、HTML設定する関数
export function loadNewsHeadline(json) {
  if (json == null || !("SearchResult" in json) || json.SearchResult == null) {
    return;
  }

  let data = [];
  let mArray = json.SearchResult;

  const mMax = 5;
  let commonUrl = HOST + NEWSPATH + "article";

  for (let i = 0; i < mArray.length; i++) {
    //描画
    //最大表示件数を上回ったら終了
    if (i >= mMax) {
      break;
    }

    let mHref =
      commonUrl +
      "?FileCreateDate=" +
      mArray[i].FileCreateDate +
      "&SeqNo=" +
      mArray[i].SeqNo;
    let mUpdateTime = mArray[i].AnnouncementTime.replace(/\//g, ".");

    data.push({
      Headline: mArray[i].Headline,
      mUpdateTime: mUpdateTime,
      mHref: mHref,
      ImageFileURL: mArray[i].ImageFileURL ? HOST + NEWSIMAGEPATH + mArray[i].ImageFileURL : '',
    });
  }

  return data;
}

//発走間近情報：直近3件のみ取得
export function loadUpcomingRaceInfo(json) {
  if (json == null || !("RaceLst" in json) || json.RaceLst == null) {
    return;
  }
  let data = {};
  let mArray = json.RaceLst;

  let mMax = 4;
  let mDate = new Date();
  let currentTime = mDate.getHours() * 60 + mDate.getMinutes();

  let counter = 0;
  let hiddenFlag = 0;
  let startFlg = false;
  data.race = [];

  for (let i = 0; i < mArray.length; i++) {
    let commonUrl = HOST + RACEPATH;
    //描画
    let raceTime =
      Math.floor(mArray[i].StartTime / 100) * 60 +
      (mArray[i].StartTime.slice(2, 4) % 100);

    //最初のレース開始30分前になるまでは表示しない
    if (hiddenFlag == 0 && raceTime - currentTime > 30) {
      // デバッグ時はコメントアウト
      break;
    }
    hiddenFlag += 1;
    //過去のレースはループ
    if (raceTime <= currentTime && mArray[i].UnResultFlg !== 1) {
      //デバッグ時はコメントアウト
      continue;
    }
    //最大表示件数を上回ったら終了
    if (counter >= mMax) {
      break;
    }
    counter += 1;

    if (mArray[i].UnResultFlg === 1) {
      commonUrl += "odds/inquire";
    } else {
      commonUrl += "card";
    }

    let mHref =
      commonUrl +
      "?DOR=" +
      mArray[i].DOR +
      "&RacetrackCd=" +
      mArray[i].RacetrackCd +
      "&RaceNum=" +
      mArray[i].RaceNum;
    let mTime =
      Math.floor(mArray[i].StartTime / 100) +
      ":" +
      mArray[i].StartTime.slice(2, 4);
    data.race.push({
      fiveMin:
        raceTime - currentTime <= 10
          ? raceTime - currentTime >= 0
            ? true
            : false
          : false,
      mArray: mArray[i],
      RaceNum: parseInt(mArray[i].RaceNum, 10),
      mTime: mTime,
      mHref: mHref,
      UnResultFlg: mArray[i].UnResultFlg,
      raceFieldName: KAIJO[String(mArray[i].RacetrackCd)],
    });
    startFlg = true;
  }

  return data;
}

//厳選1頭推奨馬情報読み込み
export function loadRecommend(json) {
  let data = {};
  if(Object.keys(json).length){
    let analysisHref =
      HOST + RACEPATH + "analysis?DOR=" + json.DOR + "&RacetrackCd=" + json.RacetrackCd + "&RaceNum=" + json.RaceNum;
    let entryHref = HOST + RACEPATH + "card?DOR=" + json.DOR + "&RacetrackCd=" + json.RacetrackCd + "&RaceNum=" + json.RaceNum;

    data.analysisHref = analysisHref;
    data.entryHref = entryHref;
    data.RacetrackCd = json.RacetrackCd;
    data.RacetrackName = json.RacetrackName;
    data.RaceNum = json.RaceNum;
    data.TrackTypeCd = json.TrackTypeCd;
    data.Distance = json.Distance;
    data.RaceName = json.RaceName;
    let startTime = String(json.StartTime);
    let hour = startTime.slice(0, 2);
    let minute = startTime.slice(2);
    data.StartTime = hour + ":" + minute;
    data.HorseName = json.HorseName;
  }

  return data;
}

//単勝1倍台情報読み込み
export function loadPays1x(dor, json) {
  let data = {};
  if (!(dor in json) || json[dor] == null || json[dor].length == 0) {
    return data;
  }

  let mDate = new Date();
  // let currentTime = ('0'+mDate.getHours()).slice(-2)+('0'+mDate.getMinutes()).slice(-2);

  let currentTime =
    ("0" + mDate.getHours()).slice(-2) + ("0" + mDate.getMinutes()).slice(-2);

  let myIdx = 0;
  for (let i = 0; i < json[dor].length; i++) {
    if (currentTime >= json[dor][i].StartTime) {
      if (i != 0) {
        myIdx = i - 1;
      }
      break;
    }
    myIdx = i;
  }

  let race = json[dor][myIdx];
  if (race) {
    let mHref =
      HOST +
      RACEPATH +
      "card?DOR=" +
      dor +
      "&RacetrackCd=" +
      race.RacetrackCd +
      "&RaceNum=" +
      race.RaceNum;

    data.mHref = mHref;
    data.race = race;
    data.raceNum = race.RaceNum * 1;
    data.TrackTypeCd = race.TrackTypeCd.slice(0, 1);
  }

  return data;
}

//高額配当情報読み込み
export function loadHighDividend(dor, json) {
  if (!json || !(dor in json) || json[dor] == null || json[dor].length == 0) {
    return;
  }
  let data = {};
  let race = json[dor][0];
  let refund = race.Contents[0];
  data.race = race;
  data.refund = refund;
  data.RaceNum = race.RaceNum * 1;
  data.Refund = replaceComma(refund.Refund);
  data.mHref =
    HOST +
    RACEPATH +
    "results?DOR=" +
    dor +
    "&RacetrackCd=" +
    race.RacetrackCd +
    "&RaceNum=" +
    race.RaceNum;

  return data;
}

//固め打ち騎手情報読み込み
export function loadMultiWinners(dor, json) {
  let data = {};
  if (!json || !(dor in json) || json[dor] == null || json[dor].length == 0) {
    data.class = "nodata";
    return;
  }

  let jockey = json[dor][0];
  let mHref = HOST + DATAPATH + "jockey?JockeyCd=" + jockey.JockeyCd;

  data.jockey = jockey;
  data.JockeyName = jockey.JockeyName.replace(/\s/g, "");
  data.RaceNum = jockey.Contents[0].RaceNum * 1;
  data.mHref = mHref;

  return data;
}

//ライブラリー
//先週の開催結果で使用する日付フォーマット取得関数（例：○月△日（□））
function getDisplayDateStr(yyyymmdd) {
  let DAY_LBL = ["日", "月", "火", "水", "木", "金", "土"];
  let month = parseInt(yyyymmdd.slice(4, 6), 10);
  let date = parseInt(yyyymmdd.slice(6, 8), 10);
  let mDate = new Date(parseInt(yyyymmdd.slice(0, 4), 10), month - 1, date);
  let day = DAY_LBL[mDate.getDay()];
  if (day == "土") {
    day = '<span class="_sat">' + day + "</span>";
  } else if (day == "日") {
    day = '<span class="_sun">' + day + "</span>";
  }
  let str = month + "月" + date + "日(" + day + ")";

  return str;
}
//レース表示部で使用する日付フォーマット取得関数（例：○/△（□））
function getDisplayDateStrForMain(yyyymmdd) {
  let DAY_LBL = ["日", "月", "火", "水", "木", "金", "土"];
  let month = parseInt(yyyymmdd.slice(4, 6), 10);
  let date = parseInt(yyyymmdd.slice(6, 8), 10);
  let mDate = new Date(parseInt(yyyymmdd.slice(0, 4), 10), month - 1, date);
  let day = DAY_LBL[mDate.getDay()];
  if (day == "土") {
    day = '<span class="_sat">' + day + "</span>";
  } else if (day == "日") {
    day = '<span class="_sun">' + day + "</span>";
  }
  let str = month + "/" + date + "(" + day + ")";

  return str;
}
//金額数値を3桁区切りにする関数
export function addFigure(str) {
  let num = new String(str).replace(/,/g, "");
  while (num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
  return num;
}
//yyyymmdd日のx日前後の日付をyyyymmdd型で取得する共通関数
export function getYesterday(yyyymmdd, x) {
  let standard = getTimeStamp(yyyymmdd);

  let mDate = getYyyymmdd(getBeforeAfterDate(standard, x, "日"));
  return mDate;
}
//yyyymmdd型で指定された日付からタイムスタンプを取得
function getTimeStamp(yyyymmdd) {
  let timestamp = new Date(
    parseInt(yyyymmdd.slice(0, 4), 10),
    parseInt(yyyymmdd.slice(4, 6), 10) - 1,
    parseInt(yyyymmdd.slice(6, 8), 10)
  );

  return timestamp;
}
//x（日、分）前、後のDateを取得する関数
//dateは基準となる時刻
//xは数値：プラス＝･･･後；マイナス＝･･･前
//modeは、'日'or'分'
function getBeforeAfterDate(date, x, mode) {
  if (typeof date === "undefined" || !(date instanceof Date)) {
    return false;
  }
  let differTime = 0;
  if (mode === "日") {
    //x日前
    differTime = x * 24 * 60 * 60 * 1000;
  } else if (mode === "分") {
    //x分前
    differTime = x * 60 * 1000;
  }

  let timestamp = date.getTime(); //現在のシステム時間を取得
  let xTimestamp = timestamp + differTime; //4分前を取得
  let xDate = new Date(xTimestamp);

  return xDate;
}
//yyyymmdd形式の日付を取得する関数
function getYyyymmdd(date) {
  if (typeof date === "undefined" || !(date instanceof Date)) {
    return false;
  }

  return (
    date.getFullYear() +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    ("0" + date.getDate()).slice(-2)
  );
}

// 最終確定表示
export async function showLastFixedResultRaceInfo() {
  let req =
    HOST +
    PATH +
    "RaceInfo/getFixedResultRaceInfo.js" +
    BACKWORDPATH +
    "?NarFlag=" +
    isNar();
  let data = {};
  await getJSON(req, true, function (status, rslt) {
    if (status == SUCCESS) {
      if (1 === rslt.InShowTime) {
        let href =
          HOST +
          "race/results?DOR=" +
          rslt.DOR +
          "&RacetrackCd=" +
          rslt.RacetrackCd +
          "&RaceNum=" +
          rslt.RaceNum;
        let raceNum = rslt.RaceNum.replace(/^0/, "");
        data.href = href;
        data.RaceName = rslt.RaceName;
        data.Racetrack = rslt.Racetrack;
        data.RaceNum = raceNum;
      }
    }
  });
  return data;
}

// シンボルを返す
export function innerRaceSymbol(symbolCd) {
  const innerRaceSymbolCd = {
    "000": "",
    "001": "(指)",
    "002": "若手",
    "003": "[指]",
    "004": "(特指)",
    "020": "牝",
    "021": "牝 (指)",
    "023": "牝 [指]",
    "024": "牝 (特指)",
    "030": "牡・ｾﾝ",
    "031": "牡・ｾﾝ (指)",
    "033": "牡・ｾﾝ [指]",
    "034": "牡・ｾﾝ (特指)",
    "040": "牡・牝",
    "041": "牡・牝 (指)",
    "043": "牡・牝 [指]",
    "044": "牡・牝 (特指)",
    A00: "(混)",
    A01: "(混)(指)",
    A02: "(混) 若手",
    A03: "(混)[指]",
    A04: "(混)(特指)",
    A10: "(混) 牡",
    A11: "(混) 牡 (指)",
    A13: "(混) 牡 [指]",
    A14: "(混) 牡 (特)",
    A20: "(混) 牝",
    A21: "(混) 牝 (指)",
    A23: "(混) 牝 [指]",
    A24: "(混) 牝 (特指)",
    A30: "(混) 牡・ｾﾝ",
    A31: "(混) 牡・ｾﾝ (指)",
    A33: "(混) 牡・ｾﾝ [指]",
    A34: "(混) 牡・ｾﾝ (特指)",
    A40: "(混) 牡・牝",
    A41: "(混) 牡・牝 (指)",
    B00: "(父)",
    B01: "(父)(指)",
    B03: "(父)[指]",
    B04: "(父)(特指)",
    C00: "(市)",
    C01: "(市)(指)",
    C03: "(市)[指]",
    C04: "(市)(特指)",
    D00: "(抽)",
    D01: "(抽)(指)",
    D03: "(抽)[指]",
    E00: "[抽]",
    E01: "[抽](指)",
    E03: "[抽][指]",
    F00: "(市)(抽)",
    F01: "(市)(抽)(指)",
    F03: "(市)(抽)[指]",
    F04: "(市)(抽)(特指)",
    G00: "(抽) ",
    G01: "(抽)(指)",
    G03: "(抽)[指]",
    H00: "(抽)",
    H01: "(抽)(指)",
    I00: "[抽]",
    I01: "[抽](指)",
    I03: "[抽][指]",
    J00: "[抽]",
    J01: "[抽](指)",
    K00: "(市)(抽)",
    K01: "(市)(抽)(指)",
    K03: "(市)(抽)[指]",
    L00: "(市)(抽)",
    L01: "(市)(抽)(指)",
    L03: "(市)(抽)[指]",
    M00: "九州",
    M01: "九州 (指)",
    M03: "九州 [指]",
    M04: "九州 (特指)",
    N00: "(国際)",
    N01: "(国際)(指)",
    N03: "(国際)[指]",
    N04: "(国際)(特指)",
    N20: "(国際) 牝",
    N21: "(国際) 牝 (指)",
    N23: "(国際) 牝 [指]",
    N24: "(国際) 牝 (特指)",
    N30: "(国際) 牡・ｾﾝ",
    N31: "(国際) 牡・ｾﾝ (指)",
    N40: "(国際) 牡・牝",
    N41: "(国際) 牡・牝 (指)",
    N44: "(国際) 牡・牝 (特指)",
  };

  return innerRaceSymbolCd[symbolCd];
}

//レース情報内のデフォルト競走場取得
function getDefaultRace(DOR, mRacetrackArray, raceInfo, entryList)
{
  var maxGradeCd = "";
  var defaultTab = mRacetrackArray[0].RacetrackCd;
  var raceRegulationArray = [];
  for (let i = 0; i < mRacetrackArray.length; i++) {
    //WEBで未設定の場合
    //最大のグレードコードを判定
    var mainRace = 0;
    var mKey = '';
    var compare = DOR + mRacetrackArray[i].RacetrackCd;
    const race = raceInfo['r' + DOR + mRacetrackArray[i].RacetrackCd];
    if (race.length == 0) {
      continue;
    }
    const keys = Object.keys(entryList);
    for (let j = 0; j < keys.length; j++) {
      if (compare === keys[j].slice(1, 11)) {
        mainRace = keys[j].slice(-2) * 1 - 1;
        mKey = keys[j].slice(1);
        break;
      }
    }

    const checkGCd = race[mainRace].GradeCd;
    raceRegulationArray.push(race[mainRace]);
    if(['A', 'B', 'C'].includes(checkGCd) && (maxGradeCd === '' || checkGCd < maxGradeCd)) {
      maxGradeCd = checkGCd;
      defaultTab = race[mainRace].RacetrackCd;
    }
  }

  if(maxGradeCd == "") {
    let maxRaceRegulationCd = "";
    //重賞レースが存在しない場合、競走条件でチェック
    for (let i = 0; i < raceRegulationArray.length; i++) {
      const checkRaceRegulationCd = raceRegulationArray[i].RaceRegulationCd;
      if ((checkRaceRegulationCd == "オープン" && maxRaceRegulationCd != "オープン") ||
          (checkRaceRegulationCd == "1600万円以下" && maxRaceRegulationCd != "オープン" && maxRaceRegulationCd != "1600万円以下") ||
          (checkRaceRegulationCd == "1000万円以下" && maxRaceRegulationCd != "オープン" && maxRaceRegulationCd != "1600万円以下" && maxRaceRegulationCd != "1000万円以下")) {
        maxRaceRegulationCd = checkRaceRegulationCd;
        defaultTab = raceRegulationArray[i].RacetrackCd;
      }
    }
  }

  return defaultTab;
}

// 当日レースデータから関連レース動画を取得
export async function getRelatedMovies(mainRaces) {
  try {
    const relatedMovies = await fetchRelatedMovies(Object.keys(mainRaces));

    if(!relatedMovies || !Array.isArray(Object.keys(relatedMovies))) {
      return [];
    }
    
    let relatedRaces = [];
    const specials = Object.keys(relatedMovies)
    if(Array.isArray(Object.keys(specials))) {
      relatedRaces = specials.map(num => {
        return Array.isArray(relatedMovies[num])
          ? relatedMovies[num].map(r => `${r.DOR}${r.RacetrackCd}${r.RaceNum}`)
          : undefined
      }).filter(r => r).flat() ?? [];
    }
    
    if(!relatedRaces || !Array.isArray(Object.keys(relatedRaces))) {
      return [];
    }
    
    const [movieUrls, relatedRaceResults] = await Promise.all([
      getMovieUrls(relatedRaces),
      getRelatedRaceResult(relatedRaces)
    ]);
  
    if(!movieUrls || !Array.isArray(Object.keys(movieUrls))) {
      return [];
    }
  
    if(!relatedRaceResults || !Array.isArray(Object.keys(relatedRaceResults))) {
      return [];
    }
  
    let ret = {};
    Object.keys(mainRaces).forEach(k => {
      let code = {};
      if(!relatedMovies[k]) {
        return;
      }
      relatedMovies[k].forEach(m => {
        let c = `${m.DOR}${m.RacetrackCd}${m.RaceNum}`;
        if(!relatedRaceResults[c]) {
          return;
        }
        try {
          code[c] = {
            ThumbUrl: m.thumb_url,
            MovieUrl: movieUrls[c] ?? '',
            Date: `${m.DOR.slice(0, 4)}.${m.DOR.slice(4, 6)}.${m.DOR.slice(6, 8)}`,
            DOR: m.DOR,
            RaceNum: relatedRaceResults[c].RaceNum,
            RaceMainName: relatedRaceResults[c].RaceMainName,
            RaceShortName6: relatedRaceResults[c].RaceShortName6,
            GradeCd: relatedRaceResults[c].GradeCd,
            Distance: relatedRaceResults[c].Distance,
            TrackTypeCd: relatedRaceResults[c].TrackTypeCd,
            RacetrackCd: relatedRaceResults[c].RacetrackCd,
            Racetrack: relatedRaceResults[c].Racetrack,
            RaceRegulationCd: relatedRaceResults[c].RaceRegulationCd,
            CommonRaceName: relatedRaceResults[c].CommonRaceName,
            Entries: relatedRaceResults[c].RaceRanking
              .filter(r => mainRaces[k].includes(r.PedigreeNum))
              .map(r => {
                return {
                  RaceRank: r.RaceRank,
                  BracketNum: r.BracketNum,
                  HorseNum: r.HorseNum,
                  HorseName: r.HorseName,
                  AccidentCd: r.AccidentCd,
                }
              })
              .sort((a,b) => {
                const aAccident = Number(a.AccidentCd) <= 5 ? Number(a.AccidentCd)*100 : 0;
                const bAccident = Number(b.AccidentCd) <= 5 ? Number(b.AccidentCd)*100 : 0;
                return (Number(a.RaceRank) + aAccident) - (Number(b.RaceRank) + bAccident);
              })
          };
        } catch (e) {
          console.log(e);
        }
      });
      if(code) {
        ret[k] = code;
      }
    });
    return ret;
  } catch (e) {
    console.log(e);
    return [];
  }
}

// WPから関連レース動画を取得
async function fetchRelatedMovies(races) {
  try {
    if(!Array.isArray(races)) {
      return false;
    }
    const query = races
      .filter(r => r !== "0000")
      .map((r,i) => `SpecialRaceNum${encodeURIComponent("["+i+"]")}=${r}`)
      .join("&");
  
    const wpUrl = `${JRA_WP_HOST}wp-json/acf/v3/racemovie?${query}`;
    const moviesInfo = await axios.get(wpUrl)
      .then(res => {
        return res.data
      })
      .catch(err => {
        console.error(err)
        return [];
      });
    
    let returnObj = {};
    moviesInfo.forEach(a => {
      if(!a.acf.SpecialRaceNum || !Array.isArray(a.acf.movies)) {
        return false;
      }
      returnObj[a.acf.SpecialRaceNum] = a.acf.movies.map(m => {
        return {
          thumb_url: m.thumb.url ?? '',
          DOR: m.DOR ?? '',
          RacetrackCd: m.RacetrackCd ?? '',
          RaceNum: m.RaceNum ?? ''
        }
      })
    });
    return returnObj;
  } catch (e) {
    console.log(e);
    return false;
  }

}

// 動画URLを取得
async function getMovieUrls(raceCodes) {
  try {
    const commonUrl = HOST + MEDIAPATH + "videoOnDemand.js" + BACKWORDPATH;
    if(!Array.isArray(raceCodes) || raceCodes.length < 1) {
      return false;
    }
    const query = raceCodes.join(",");
    const url = `${HOST}${MEDIAPATH}getVideoUrl.js${BACKWORDPATH}?Lst=${query}`;
    // OPTIMIZE: getVideoUrl.jsが遅い場合、新しいwebapiを作る
    const movieUrls = await axios.get(url)
      .then(res => res.data)
      .catch(err => console.error(err));

    let returnObj = {};
    movieUrls.filter(m => m.Exist === 1)
      .forEach(m => {
        returnObj[m.Key] = `${commonUrl}?DOR=${m.Key.slice(0, 8)}` +
          `&RacetrackCd=${m.Key.slice(8, 10)}` +
          `&RaceNum=${m.Key.slice(10, 12)}` +
          `&Expir=${m.Expir}` +
          `&x=.mp4`
    })
    return returnObj;
  } catch (e) {
    console.log(e);
    return false;
  }
}

// 関連レースのレース結果を取得
async function getRelatedRaceResult(races) {
  if(!Array.isArray(races)) {
    return false;
  }
  const query = races.join(",");
  const url = `${HOST}${MEDIAPATH}getRelatedRaceResults.js${BACKWORDPATH}?RaceCodes=${query}`;
  return await axios.get(url)
    .then(res => res.data)
    .catch(err => {
      console.error(err)
      return [];
    });
}

/**
 * Myお気に入り/次走狙いボタンを表示するか
 */
export function shouldShowFavoriteButton(shouldShowFavButtonRef, mUserId) {
  const narFlag = isNar();

  // お気に入り今週出走
  const favoriteHorseWeeklyRacingRequest =
    HOST +
    PATH +
    'Master/weeklyRacingUpdateAPI.js' +
    BACKWORDPATH +
    '?ViewKbn=4&UserId=' +
    encodeURIComponent(mUserId) +
    '&NarFlag=' + narFlag;
  getJSON(favoriteHorseWeeklyRacingRequest, true, function (status, json) {
    if (status === SUCCESS && json?.length) {
      shouldShowFavButtonRef.value = true;
    }
  });

  // お気に入り特別登録
  const favoriteHorseSpecialEntryRequest =
    HOST +
    PATH +
    'Master/getSpecialEntryInfoAPI.js' +
    BACKWORDPATH +
    '?UserId=' +
    encodeURIComponent(mUserId) +
    '&NarFlag=' + narFlag;
  getJSON(favoriteHorseSpecialEntryRequest, true, function (status, json) {
    if (status === SUCCESS && json?.length) {
      shouldShowFavButtonRef.value = true;
    }
  });

  // 次走狙い今週出走
  const nextTargetWeeklyRacingRequest =
    HOST +
    PATH +
    'Master/weeklyRacingUpdateAPI.js' +
    BACKWORDPATH +
    '?ViewKbn=5&UserId=' +
    encodeURIComponent(mUserId) +
    '&NarFlag=' + narFlag;
  getJSON(nextTargetWeeklyRacingRequest, true, function (status, json) {
    if (status === SUCCESS && json?.length) {
      shouldShowFavButtonRef.value = true;
    }
  });

  // 次走狙い特別登録
  const nextTargetSpecialEntryRequest =
    HOST +
    PATH +
    'Master/getSpecialEntryInfoAPI.js' +
    BACKWORDPATH +
    '?ViewKbn=5&UserId=' +
    encodeURIComponent(mUserId) +
    '&NarFlag=' + narFlag;
  getJSON(nextTargetSpecialEntryRequest, true, function (status, json) {
    if (status === SUCCESS && json?.length) {
      shouldShowFavButtonRef.value = true;
    }
  });
}